<template>
  <main>
    <nav class="step-step-nav" aria-label="Progress">
      <ol
        role="list"
        class="rounded-md divide-y divide-gray-300 md:flex md:divide-y-0"
      >
        <li
          v-for="(step, stepIdx) in this.$store.state.endlessOfficeSteps"
          :key="step.name"
          class="relative md:flex-1 md:flex"
        >
          <router-link
            tag="button"
            v-if="step.status === 'complete'"
            :to="step.to"
            class="group flex items-center w-full"
          >
            <span class="px-6 py-4 flex items-center text-sm font-medium">
              <span
                class="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800"
              >
                <CheckIcon
                  disabled="true"
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <span class="ml-4 text-sm font-medium text-gray-900">{{
                step.name
              }}</span>
            </span>
          </router-link>
          <router-link
            tag="button"
            v-else-if="step.status === 'current-complete'"
            :to="step.to"
            class="group flex items-center w-full"
          >
            <span class="px-6 py-4 flex items-center text-sm font-medium">
              <span
                class="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800"
              >
                <CheckIcon
                  disabled="true"
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <span class="ml-4 text-sm font-medium text-indigo-600">{{
                step.name
              }}</span>
            </span>
          </router-link>
          <router-link
            v-else-if="step.status === 'current-incomplete'"
            :to="step.to"
            class="px-6 py-4 flex items-center text-sm font-medium"
            aria-current="step"
          >
            <span
              class="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-indigo-600 rounded-full"
            >
              <span class="text-indigo-600">{{ step.id }}</span>
            </span>
            <span class="ml-4 text-sm font-medium text-gray-900">{{
              step.name
            }}</span>
          </router-link>
          <router-link
            v-else-if="step.status === 'current'"
            :to="step.to"
            class="px-6 py-4 flex items-center text-sm font-medium"
            aria-current="step"
          >
            <span
              class="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-indigo-600 rounded-full"
            >
              <span class="text-indigo-600">{{ step.id }}</span>
            </span>
            <span class="ml-4 text-sm font-medium text-indigo-600">{{
              step.name
            }}</span>
          </router-link>
          <router-link
            tag="button"
            v-else-if="step.status === 'disabled'"
            :to="step.to"
            class="disabled group flex items-center w-full"
          >
            <span class="px-6 py-4 flex items-center text-sm font-medium">
              <span
                class="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800"
              >
                <CheckIcon
                  disabled="true"
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <span class="ml-4 text-sm font-medium text-gray-900">{{
                step.name
              }}</span>
            </span>
          </router-link>
          <router-link
            v-else
            :to="step.to"
            :class="{ disabled: step.visible }"
            class="group flex items-center"
          >
            <span class="px-6 py-4 flex items-center text-sm font-medium">
              <span
                class="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400"
              >
                <span class="text-gray-500 group-hover:text-gray-900">{{
                  step.id
                }}</span>
              </span>
              <span
                class="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900"
                >{{ step.name }}</span
              >
            </span>
          </router-link>
          <template
            v-if="stepIdx !== this.$store.state.endlessOfficeSteps.length - 1"
          >
            <!-- Arrow separator for lg screens and up -->
            <div
              class="hidden md:block absolute top-0 right-0 h-full w-5"
              aria-hidden="true"
            >
              <svg
                class="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </template>
        </li>
      </ol>
    </nav>
    <div class="max-w-7xl mx-auto py-6 step-component sm:px-6 lg:px-8 minh-100">
      <!-- Replace with your content -->

      <router-view />
      <!-- /End replace -->
    </div>
  </main>
</template>
<script>
import { CheckIcon } from "@heroicons/vue/solid";
import AccountsService from "../../../services/accounts.service";

export default {
  components: {
    CheckIcon,
  },
  setup() {
    return {
      index: 0,
    };
  },
  data() {
    return {
      account: {},
      tempIndex: 0,
    };
  },
  methods: {
    async getAccount() {
      let response = await new AccountsService().getAccount(
        JSON.parse(localStorage.getItem("account")).id
      );
      this.account = response.result;
    },
    async getInfo() {
      this.step = this.account.informations.find(
        (element) => element.key === "office_step"
      );

      if (this.step) {
        this.index = this.$store.state.endlessOfficeSteps.findIndex(
          (element) => element.slug === this.step.value
        );
        if (this.index !== this.$store.state.endlessOfficeSteps.length - 1) {
          for (let i = 0; i <= this.index; i++) {
            this.$store.state.endlessOfficeSteps[i].status = "complete";
            this.$store.state.endlessOfficeSteps[i].visible =
              this.step.value === "office-wait-for-approve";
          }
        }
        let newCurrentStep = this.$store.state.endlessOfficeSteps.findIndex(
          (element) => element.slug === this.step.value
        );
        this.$router.push(
          this.$store.state.endlessOfficeSteps[newCurrentStep + 1].to
        );
      } else {
        this.$store.state.endlessOfficeSteps.forEach(
          (step) => (step.status = "upcoming")
        );
        this.$store.state.endlessOfficeSteps[0].status = "current";
      }
    },
    async watchSteps() {
      this.$store.state.endlessOfficeSteps[this.tempIndex].status = "complete";
      let step = this.account.informations.find(
        (element) => element.key === "office_step"
      );
      if (step !== undefined) {
        step = this.$store.state.endlessOfficeSteps.findIndex(
          (element) => element.slug === step.value
        );
        let route_index = this.$store.state.endlessOfficeSteps.findIndex(
          (element) => element.to === this.$route.path
        );
        step = step < 4 && step !== -1 ? step + 1 : step;
        if (
          this.$route.path !== this.$store.state.endlessOfficeSteps[step].to &&
          route_index !== -1
        ) {
          this.tempIndex = route_index;
          this.$store.state.endlessOfficeSteps[route_index].status =
            "current-complete";
          if (step !== -1) {
            this.$store.state.endlessOfficeSteps[parseInt(step)].status =
              "current-incomplete";
          }
        } else if (
          this.$route.path ===
          this.$store.state.endlessOfficeSteps[parseInt(step)].to
        ) {
          this.$store.state.endlessOfficeSteps[parseInt(step)].status =
            "current";
          if (
            this.$store.state.endlessOfficeSteps[step].slug ===
            "office-wait-for-approve"
          ) {
            for (
              let i = 0;
              i < this.$store.state.endlessOfficeSteps.length;
              i++
            ) {
              if (i !== 6) {
                this.$store.state.endlessOfficeSteps[i].status = "disabled";
              }
            }
          }
        }
      } else {
        this.$store.state.endlessOfficeSteps.forEach(
          (step) => (step.status = "upcoming")
        );
        this.$store.state.endlessOfficeSteps[0].status = "current";
      }
    },
  },
  async mounted() {
    if (localStorage.getItem("account")) {
      await this.getAccount();
      await this.getInfo();
      // await this.watchSteps();
    }
  },
  computed: {
    watchRoute() {
      return this.$route;
    },
  },
  watch: {
    async watchRoute() {
      await this.watchSteps();
    },
  },
};
</script>

<style lang="css">
.disabled {
  opacity: 1;
  pointer-events: none;
}

.step-step-nav {
  border-bottom: 0px;
  box-shadow: 0px 11px 20px -11px #66666647;
}

@media only screen and (max-width: 600px) {
  .step-component {
    padding: 20px;
  }
}
</style>
