<template>
  <form class="lg:col-span-10" action="#" method="POST">
    <div class="img-content">
      <div
        class="w-full text-center img-drop"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <div>
          <input
            type="file"
            name="fields[assetsFieldHandle][]"
            id="assetsFieldHandle"
            class="w-px h-px opacity-0 overflow-hidden absolute"
            @change="onChange"
            ref="file"
            accept=".jpg,.jpeg,.png"
          />

          <label for="assetsFieldHandle" class="block cursor-pointer">
            <div class="text-gray-600 img-pp-content">
              <img
                class="profile-img rounded-full h-32 w-32"
                :src="base64Img"
                alt=""
              />
              <img
                class="img-hover-photo h-32 w-32"
                src="../../../../public/img/photo-camera.png"
                alt="photo"
              />
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="form-content">
      <div class="mt-6 flex-col">
        <div class="w-full">
          <label
            htmlFor="username"
            class="lg:block text-sm font-medium text-gray-700"
          >
            {{ $t("message.name") }} {{ $t("message.surname") }}
          </label>
          <div class="mt-1 rounded-md lg:shadow-sm flex">
            <text-input
              v-model="accountInfo.name"
              class="lg:w-full xl:w-full md:w-5/6 sm:w-5/6 rounded-md sm:text-sm"
            ></text-input>
          </div>
        </div>
      </div>
      <div class="mt-6 flex-col">
        <div class="w-full">
          <label
            htmlFor="username"
            class="lg:block text-sm font-medium text-gray-700"
          >
            {{ $t("message.phone") }}
          </label>
          <div class="mt-1 rounded-md lg:shadow-sm flex">
            <phone-number-input
              :phone="phone"
              v-model="accountInfo.phone"
              class="lg:w-full xl:w-full md:w-5/6 sm:w-5/6 rounded-md sm:text-sm"
            ></phone-number-input>
          </div>
        </div>
        <div class="w-full mt-5">
          <label
            htmlFor="lastname"
            class="lg:block text-sm font-medium text-gray-700"
          >
            E-Posta
          </label>
          <div class="mt-1 rounded-md lg:shadow-sm flex">
            <email-input
              v-model="accountInfo.email"
              class="lg:w-full xl:w-full md:w-5/6 sm:w-5/6"
            ></email-input>
          </div>
        </div>
      </div>
      <div class="mt-6 flex justify-start">
        <button
          @click="editAccountInfo"
          type="button"
          class="inline-flex items-center justify-center text-center px-3 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <SaveIcon class="mr-2 h-5 w-5" aria-hidden="true" />

          {{ $t("message.save") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";
import { SaveIcon } from "@heroicons/vue/outline";
import PhoneNumberInput from "../../Inputs/PhoneNumberInput";
import EmailInput from "../../Inputs/EmailInput";
import TextInput from "../../Inputs/TextInput";
import UserService from "../../../services/user.service";

const user = {
  name: "Debbie Lewis",
  handle: "deblewis",
  email: "debbielewis@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80",
};

export default {
  components: {
    TextInput,
    PhoneNumberInput,
    EmailInput,
    SaveIcon,
  },
  setup() {
    const availableToHire = ref(true);
    const privateAccount = ref(false);
    const allowCommenting = ref(true);
    const allowMentions = ref(true);

    return {
      user,
      availableToHire,
      privateAccount,
      allowCommenting,
      allowMentions,
    };
  },
  data() {
    return {
      accountInfo: {},
      avatar: "",
      uploadFiles: [],
      base64Img:
        "https://api.emusavirim.net/storage/uploads/1635151409_renkli.png",
    };
  },
  methods: {
    async getAccountInfo() {
      let response = await new UserService().getUserInfo(
        JSON.parse(localStorage.getItem("user")).id
      );
      this.accountInfo = response.result;
      this.accountInfo.phone = this.accountInfo.phone.substring(3, 13);
      this.base64Img =
        "https://api.emusavirim.net" + this.accountInfo.avatar !==
        "https://api.emusavirim.netnull"
          ? "https://api.emusavirim.net" + this.accountInfo.avatar
          : "https://api.emusavirim.net/storage/uploads/1635151409_renkli.png";
      this.$store.state.avatar = this.base64Img;
      localStorage.setItem("avatar", this.base64Img);
      localStorage.setItem(
        "is_email_verified",
        response.result.is_email_verified
      );
      this.$store.state.is_email_verified = response.result.is_email_verified;
    },
    async editAccountInfo() {
      await new UserService().editInfo(
        this.accountInfo,
        JSON.parse(localStorage.getItem("user")).id
      );
      await this.getAccountInfo();
    },
    async editAvatar() {
      const formData = new FormData();
      await formData.append("avatar", this.uploadFiles[0]);
      await new UserService().editAvatar(formData);

      await this.getAccountInfo();
    },
    async onChange() {
      this.uploadFiles = [...this.$refs.file.files];
      if (this.uploadFiles[0].size > 20000000) {
        this.$store.dispatch("showNotification", {
          title: "Lütfen daha küçük boyutlu bir resim ile tekrar deneyiniz",
          description: "",
          type: "error",
        });
      } else {
        this.encodeImage(this.uploadFiles[0]);
        await this.editAvatar();
      }
    },
    encodeImage(input) {
      if (input) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.base64Img = e.target.result;
        };
        reader.readAsDataURL(input);
      }
    },
    remove(i) {
      this.uploadFiles.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
  async created() {
    await this.getAccountInfo();
  },
};
</script>
<style lang="scss">
.img-content {
  background: #f5f5f5;
  position: relative;
  min-height: 130px;
  .img-drop {
    position: absolute;
    top: 51px;
  }
}
.profile-img {
  position: absolute;
  object-fit: cover;
  top: 15px;
  box-shadow: 0px 7px 20px -7px #111826b3;
  left: 50px;
}
.form-content {
  margin-top: 95px;
  padding: 0px 55px;
  width: 80%;
}
.img-pp-content {
  &:hover {
    .img-hover-photo {
      display: block !important;
    }
  }
}
.img-hover-photo {
  position: absolute;
  top: 15px;
  padding: 50px;
  background: #11182666;
  border-radius: 50%;
  display: none;
  z-index: 100000;
  left: 50px;
  &:hover {
    display: block;
  }
}
</style>
