<template>
  <div v-if="checkPermission('show accounts')" class="lg:col-span-9">
    <div class="flex flex-col px-3 m-3">
      <div
        class="flex lg:space-x-20 xl:space-x-20 md:space-x-5 sm:space-x-5 border-b border-gray-200"
      >
        <div class="tab-content">
          <button
            @click="
              personal_info = false;
              address = false;
              company = true;
            "
            :class="[
              company
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              ' py-4 px-1 text-center border-b-2 font-medium text-m',
            ]"
          >
            Şirket Bilgileri
          </button>
        </div>
        <div class="tab-content">
          <button
            @click="
              personal_info = true;
              address = false;
              company = false;
            "
            :class="[
              personal_info
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              ' py-4 px-1 text-center border-b-2 font-medium text-m',
            ]"
          >
            Kişisel Bilgiler
          </button>
        </div>
        <div class="tab-content">
          <button
            @click="
              personal_info = false;
              address = true;
              company = false;
            "
            :class="[
              address
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              ' py-4 px-1 text-center border-b-2 font-medium text-m',
            ]"
          >
            Adres Bilgileri
          </button>
        </div>
      </div>
      <div>
        <div class="mt-3 text-center sm:mt-5">
          <div class="mt-2">
            <div class="flex-grow">
              <div class="">
                <div class="p-2" v-show="company">
                  <div
                    class="rounded-lg grid grid-cols-12 gap-3 flex-grow bg-gray-100"
                  >
                    <div class="col-span-12 sm:col-span-12 px-2 mb-2">
                      <label class="text-center text-lg text-gray-900">
                        Şirket İsmi
                      </label>
                      <input
                        v-model="account.name"
                        type="text"
                        class="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
                <div class="p-2" v-show="personal_info">
                  <div
                    class="rounded-t-lg bg-gray-100 p-3 font-bold flex justify-start"
                  >
                    KİMLİK BİLGİLERİ
                  </div>
                  <div
                    class="rounded-b-lg grid grid-cols-12 gap-3 flex-grow bg-gray-100"
                  >
                    <div class="col-span-12 sm:col-span-6 px-2 mb-2">
                      <label class="text-center text-lg text-gray-900">
                        Tc Kimlik No
                      </label>
                      <identity-number-input
                        v-model="accountInfo.identityNo"
                      ></identity-number-input>
                    </div>
                    <div class="col-span-12 sm:col-span-6 px-2">
                      <label class="text-center text-base text-gray-900">
                        Telefon Numarası
                      </label>
                      <phone-number-input
                        :phone="phone"
                        v-model="accountInfo.phone"
                      ></phone-number-input>
                    </div>
                  </div>
                </div>
                <div v-show="personal_info" class="mt-2 p-2">
                  <div
                    class="rounded-t-lg bg-gray-100 p-3 font-bold flex justify-start"
                  >
                    DOĞUM BİLGİLERİ
                  </div>
                  <div
                    class="rounded-b-lg grid grid-cols-12 gap-3 flex-grow bg-gray-100"
                  >
                    <div class="col-span-12 sm:col-span-4 px-2 mb-2">
                      <label class="text-center text-base text-gray-900">
                        İl
                      </label>
                      <city-select-input
                        @change="getBirthDistricts(accountInfo.birthCity)"
                        :cities="birthCities"
                        v-model="accountInfo.birthCity"
                      ></city-select-input>
                    </div>
                    <div class="col-span-12 sm:col-span-4 px-2 mb-2">
                      <label class="text-center text-base text-gray-900">
                        İlçe
                      </label>
                      <district-select-input
                        :class="{ disabled: !accountInfo.birthCity }"
                        :districts="birthDistricts"
                        v-model="accountInfo.birthDist"
                      ></district-select-input>
                    </div>
                    <div class="col-span-12 sm:col-span-4 px-2 mb-2">
                      <label class="text-center text-base text-gray-900">
                        Tarih
                      </label>
                      <Calendar
                        id="date"
                        v-model="accountInfo.birthDate"
                        class="flex-grow"
                        dateFormat="dd-mm-yy"
                        autoComplete="off"
                        :max-date="maxDate"
                        locale="tr"
                      >
                      </Calendar>
                    </div>
                  </div>
                </div>
                <div v-show="personal_info" class="mt-2 p-2">
                  <div
                    class="rounded-t-lg bg-gray-100 p-3 font-bold flex justify-start"
                  >
                    ADRES BİLGİLERİ
                  </div>
                  <div
                    class="rounded-b-lg grid grid-cols-12 gap-3 flex-grow bg-gray-100"
                  >
                    <div class="col-span-12 sm:col-span-4 px-2">
                      <label class="text-center text-base text-gray-900">
                        İl
                      </label>
                      <city-select-input
                        @change="getDistricts(accountInfo.city)"
                        :cities="cities"
                        v-model="accountInfo.city"
                      ></city-select-input>
                    </div>
                    <div class="col-span-12 sm:col-span-4 px-2">
                      <label class="text-center text-base text-gray-900">
                        İlçe
                      </label>
                      <district-select-input
                        :class="{ disabled: !accountInfo.city }"
                        :districts="districts"
                        v-model="accountInfo.district"
                      ></district-select-input>
                    </div>
                    <div class="col-span-12 sm:col-span-4 px-2">
                      <label class="text-center text-base text-gray-900">
                        Mahalle
                      </label>
                      <input
                        v-model="accountInfo.neighbourhood"
                        type="text"
                        name="city"
                        class="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div class="col-span-12 sm:col-span-4 px-2">
                      <label class="text-center text-base text-gray-900">
                        Cadde
                      </label>
                      <input
                        v-model="accountInfo.street"
                        type="text"
                        name="city"
                        class="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div class="col-span-12 sm:col-span-4 px-2">
                      <label class="text-center text-base text-gray-900">
                        Bina No
                      </label>
                      <input
                        v-model="accountInfo.buildingNo"
                        type="text"
                        class="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div class="col-span-12 sm:col-span-4 px-2 mb-2">
                      <label class="text-center text-base text-gray-900">
                        Daire No
                      </label>
                      <input
                        v-model="accountInfo.doorNo"
                        type="text"
                        class="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
                <div v-show="address" class="mt-2 p-2">
                  <div
                    class="rounded-t-lg bg-white p-3 font-bold flex justify-start"
                  >
                    ADRES BİLGİLERİ
                  </div>
                  <div class="rounded-b-lg flex-grow bg-white">
                    <RadioGroup v-model="accountInfo.address_option">
                      <div class="rounded-md -space-y-px pt-8">
                        <RadioGroupOption
                          as="template"
                          v-for="(setting, settingIdx) in settings"
                          :key="setting.name"
                          :value="setting"
                          v-slot="{ checked, active }"
                        >
                          <div
                            :class="[
                              settingIdx === 0
                                ? 'rounded-tl-md rounded-tr-md'
                                : '',
                              settingIdx === settings.length - 1
                                ? 'rounded-bl-md rounded-br-md'
                                : '',
                              checked
                                ? 'bg-indigo-50 border-indigo-200 z-10'
                                : 'border-gray-200',
                              'relative border p-4 flex cursor-pointer focus:outline-none',
                            ]"
                          >
                            <span
                              :class="[
                                checked
                                  ? 'bg-indigo-600 border-transparent'
                                  : 'bg-white border-gray-300',
                                active
                                  ? 'ring-2 ring-offset-2 ring-indigo-500'
                                  : '',
                                'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                              ]"
                              aria-hidden="true"
                            >
                              <span class="rounded-full bg-white w-1.5 h-1.5" />
                            </span>
                            <div class="ml-3 flex flex-col">
                              <RadioGroupLabel
                                :value="setting.id"
                                as="span"
                                :class="[
                                  checked ? 'text-indigo-900' : 'text-gray-900',
                                  'block text-sm font-medium',
                                ]"
                              >
                                {{ setting.name }}
                              </RadioGroupLabel>
                            </div>
                          </div>
                        </RadioGroupOption>
                        <div
                          v-if="accountInfo.address_option === settings[0]"
                          class="pt-8 information-card mt-4"
                        >
                          <div>
                            <h3
                              class="text-lg leading-6 font-medium text-gray-900"
                            >
                              {{ $t("message.rentalInfo") }}
                            </h3>
                          </div>
                          <div
                            class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
                          >
                            <div class="sm:col-span-3">
                              <label
                                class="block text-sm font-medium text-gray-700"
                              >
                                {{ $t("message.rentalPeriod") }}
                              </label>
                              <div class="mt-1">
                                <select
                                  v-model="accountInfo.rental_period"
                                  id="location"
                                  name="location"
                                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                >
                                  <option value="aylik">Aylık</option>
                                  <option>Yıllık</option>
                                </select>
                              </div>
                            </div>
                            <div class="sm:col-span-3">
                              <label
                                class="block text-sm font-medium text-gray-700"
                              >
                                {{ $t("message.ownerTc") }}
                              </label>
                              <div class="mt-1">
                                <input
                                  v-model="accountInfo.identity_info"
                                  type="text"
                                  name="first-name"
                                  autocomplete="off"
                                  class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                            <div class="sm:col-span-2">
                              <label
                                class="block text-sm font-medium text-gray-700"
                              >
                                {{ $t("message.rentalAmount") }} (
                                {{ $t("message.net") }})
                              </label>
                              <div class="mt-1">
                                <input
                                  v-model="accountInfo.rental_amount_net"
                                  type="text"
                                  name="first-name"
                                  autocomplete="off"
                                  class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                            <div class="sm:col-span-2">
                              <label
                                class="block text-sm font-medium text-gray-700"
                              >
                                {{ $t("message.rentalAmount") }} (
                                {{ $t("message.beforeTax") }})
                              </label>
                              <div class="mt-1">
                                <input
                                  v-model="accountInfo.rental_amount_brut"
                                  type="text"
                                  name="first-name"
                                  autocomplete="off"
                                  class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                            <div class="sm:col-span-2">
                              <label
                                class="block text-sm font-medium text-gray-700"
                              >
                                {{ $t("message.currency") }}
                              </label>
                              <div class="mt-1">
                                <select
                                  id="country"
                                  name="country"
                                  autocomplete="off"
                                  class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                >
                                  <option
                                    v-for="(type, index) in currency_type"
                                    :key="index"
                                    :value="(accountInfo.currency = type)"
                                  >
                                    {{ type.name }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="accountInfo.address_option === settings[3]"
                          class="pt-8 information-card mt-4"
                        >
                          <div
                            class="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-1"
                          >
                            <fieldset class="space-y-5">
                              <!-- This example requires Tailwind CSS v2.0+ -->

                              <SwitchGroup as="div" class="flex items-center">
                                <Switch
                                  @click="setVirtualOffice"
                                  v-model="accountInfo.virtual_office_selection"
                                  :class="[
                                    accountInfo.virtual_office_selection
                                      ? 'bg-indigo-600'
                                      : 'bg-gray-200',
                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                                  ]"
                                >
                                  <span
                                    aria-hidden="true"
                                    :class="[
                                      accountInfo.virtual_office_selection
                                        ? 'translate-x-5'
                                        : 'translate-x-0',
                                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                                    ]"
                                  />
                                </Switch>
                                <SwitchLabel as="span" class="ml-3">
                                  <span
                                    class="text-sm font-medium text-gray-900"
                                  >
                                    {{ $t("message.iWantVirtualOffice") }}
                                  </span>
                                </SwitchLabel>
                              </SwitchGroup>

                              <div v-if="accountInfo.virtual_office_selection">
                                <RadioGroup
                                  v-model="accountInfo.virtual_office_id"
                                >
                                  <div class="rounded-md -space-y-px">
                                    <RadioGroupOption
                                      as="template"
                                      v-for="office in virtualOffices"
                                      :key="office.name"
                                      :value="office"
                                      v-slot="{ checked, active }"
                                    >
                                      <div
                                        class="rounded-tl-md rounded-tr-md rounded-bl-md rounded-br-md z-10 relative p-4 flex cursor-pointer focus:outline-none"
                                      >
                                        <span
                                          :class="[
                                            checked
                                              ? 'bg-indigo-600'
                                              : 'bg-white ',
                                            active
                                              ? 'ring-2 ring-offset-2 ring-indigo-500'
                                              : '',
                                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                                          ]"
                                          aria-hidden="true"
                                        >
                                          <span
                                            class="rounded-full bg-white w-1.5 h-1.5"
                                          />
                                        </span>
                                        <div class="ml-3 flex flex-col">
                                          <RadioGroupLabel
                                            :value="office.id"
                                            as="span"
                                            :class="[
                                              checked
                                                ? 'text-indigo-900'
                                                : 'text-gray-900',
                                              'block text-sm font-medium',
                                            ]"
                                          >
                                            <div class="flex space-x-2">
                                              <span> {{ office.name }}</span>
                                              <div
                                                class="text-sm text-gray-500"
                                              >
                                                150₺
                                              </div>
                                            </div>
                                          </RadioGroupLabel>
                                        </div>
                                      </div>
                                    </RadioGroupOption>
                                  </div>
                                </RadioGroup>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mt-5 p-2 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
      >
        <button
          type="button"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
          @click="editAccount"
        >
          Kaydet
        </button>
        <button
          type="button"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          @click="updateModal = false"
          ref="cancelButtonRef"
        >
          {{ $t("message.close") }}
        </button>
      </div>
    </div>
  </div>
  <div v-else class="lg:col-span-9">
    <!-- Profile section -->
    <div class="flex justify-center items-center py-12">
      <div
        class="bg-gray flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="uppercase text-lg text-center">
          <div>
            {{ $t("message.dontHavePermission") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  RadioGroup,
  SwitchLabel,
  Switch,
  SwitchGroup,
  RadioGroupOption,
  RadioGroupLabel,
} from "@headlessui/vue";
import AccountsService from "../../../services/accounts.service";
import { Mixin } from "../../../library/utils";
import CityDistrictService from "../../../services/city-district.service";
import PhoneNumberInput from "../../Inputs/PhoneNumberInput";
import CitySelectInput from "../../Inputs/CitySelectInput";
import DistrictSelectInput from "../../Inputs/DistrictSelectInput";
import IdentityNumberInput from "../../Inputs/IdentityNumberInput";
import ProductsService from "../../../services/products.service";
import UserService from "../../../services/user.service";
// import { DatePicker } from "v-calendar";

export default {
  mixins: [Mixin],
  components: {
    PhoneNumberInput,
    CitySelectInput,
    DistrictSelectInput,
    IdentityNumberInput,
    // DatePicker,
    RadioGroup,
    SwitchLabel,
    Switch,
    SwitchGroup,
    RadioGroupOption,
    RadioGroupLabel,
  },
  data() {
    return {
      accounts: [],
      deleteModal: false,
      accountId: 0,
      account: { name: "" },
      updateModal: false,
      addModal: false,
      accountInfo: {
        identityNo: "",
        birthCity: "",
        birthDist: "",
        birthDate: "",
        city: "",
        district: "",
        phone: "",
        street: "",
        neighbourhood: "",
        buildingNo: "",
        doorNo: "",
      },
      cities: [],
      districts: [],
      birthDistricts: [],
      birthCities: [],
      settings: [
        { id: 1, name: this.$t("message.renter") },
        { id: 2, name: this.$t("message.ownedByMe") },
        { id: 3, name: this.$t("message.virtualOffice") },
        { id: 4, name: this.$t("message.dontHaveOperatingAddress") },
      ],
      virtualOffices: [],
      personal_info: true,
      address: false,
      files: false,
      company: false,
      tempName: "",
      masks: {
        input: "DD-MM-YYYY",
      },
      maxDate: new Date(),
    };
  },
  methods: {
    async deleteCompany() {
      await new AccountsService().deleteAccount("", this.accountId);
      this.deleteModal = false;
      await this.getAccount();
    },
    async getAccount() {
      this.accountId = this.$route.params.id;
      let response = await new AccountsService().getAccount(
        this.$route.params.id
      );
      this.account = response.result;
      localStorage.setItem("account", JSON.stringify(this.account));
      // this.$store.state.account = this.account
      this.tempName = this.account.name;
      for (let i = 0; i < response.result.informations.length; i++) {
        this.accountInfo[response.result.informations[i].key] =
          response.result.informations[i].value;
      }
      if (this.accountInfo.birthCity) {
        await this.getBirthDistricts(this.accountInfo.birthCity);
      }
      if (this.accountInfo.city) {
        await this.getDistricts(this.accountInfo.city);
      }
      this.accountInfo.address_option = JSON.parse(
        this.accountInfo.address_option
      );
      this.accountInfo.address_option = this.settings.find(
        (element) => element.id === this.accountInfo.address_option.id
      );
      this.accountInfo.virtual_office_selection =
        this.accountInfo.virtual_office_selection === "1";
      this.accountInfo.virtual_office_id =
        this.virtualOffices.find(
          (element) => element.id == this.accountInfo.virtual_office_id
        ) !== undefined
          ? this.virtualOffices.find(
              (element) => element.id == this.accountInfo.virtual_office_id
            )
          : this.virtualOffices[0];
      this.updateModal = true;
    },
    async getAccounts() {
      let response = await new AccountsService().getAccounts();
      this.accounts = response.result.data;
      this.$store.state.accounts = this.accounts;
      let tempAcc = this.accounts.filter(
        (account) =>
          this.account.name !== "" && account.name === this.account.name
      );
      this.$store.state.account =
        tempAcc.length > 0 ? tempAcc[0] : this.accounts[0];
      localStorage.setItem(
        "account",
        JSON.stringify(this.$store.state.account)
      );
      localStorage.setItem("accounts", JSON.stringify(this.accounts));
    },
    async getCities() {
      let response = await new CityDistrictService().getCities();
      this.cities = response.result.data;
      this.birthCities = response.result.data;
    },
    async getDistricts(id) {
      let response = await new CityDistrictService().getDistricts(id);
      this.districts = response.result.data;
    },
    async getBirthDistricts(id) {
      let response = await new CityDistrictService().getDistricts(id);
      this.birthDistricts = response.result.data;
    },
    async editAccount() {
      await this.addInfo();
      if (
        this.account.name !== this.tempName &&
        this.account.name.length <= 60
      ) {
        var bodyParams = {
          name: this.account.name,
        };
        await new AccountsService().editAccount("", this.accountId, bodyParams);

        await this.getAccount();
        await this.getAccounts();
        this.updateModal = false;
        await this.getAccount();
      }
    },
    setDeleteModal(id) {
      this.accountId = id;
      this.deleteModal = true;
    },
    async getVirtualOffices() {
      let response = await new ProductsService().getProducts(
        "",
        "is_special:1"
      );
      this.virtualOffices = response.result;
      let offices = [];
      this.virtualOffices.forEach((office) => {
        if (
          office.name === "Torun Center" ||
          office.name === "Profilo" ||
          office.name === "Folkart" ||
          office.name === "Vogue"
        ) {
          offices.push(office);
        }
      });
      this.virtualOffices = offices;
    },
    setVirtualOffice() {
      if (this.accountInfo.virtual_office_selection) {
        this.accountInfo.virtual_office_id =
          this.accountInfo.virtual_office_id !== "-"
            ? this.accountInfo.virtual_office_id
            : this.virtualOffices[0];
      }
    },
    async addInfo() {
      if (this.checkData(this.accountInfo)) {
        await new UserService().addInfo(
          this.accountInfo,
          JSON.parse(localStorage.getItem("account")).id
        );
      }
    },
  },
  async created() {
    if (this.checkPermission("show accounts")) {
      await this.getCities();
      await this.getVirtualOffices();
      await this.getAccount();
    }
  },
};
</script>
<style lang="scss">
.bg-gray {
  background: #f9f9f9;
  border: 0px;
  box-shadow: 0 0 0 transparent;
}

.information-card {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 11px;
  margin-bottom: 20px !important;
  margin-top: 35px !important;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 400;
}

.small-text {
  font-size: 10px;
}

.dp__input_icon_pad {
  padding-left: 12px !important;
}
</style>
