<template>
  <main>
    <nav class="step-step-nav" aria-label="Progress">
      <ol
        role="list"
        class="rounded-md divide-y divide-gray-300 lg:flex lg:divide-y-0"
      >
        <li
          v-for="(step, stepIdx) in this.$store.state.steps"
          :key="step.name"
          class="relative md:flex-1 md:flex"
        >
          <router-link
            tag="button"
            v-if="step.status === 'complete'"
            :to="step.to"
            class="group flex items-center w-full"
          >
            <span class="px-6 py-4 flex items-center text-sm font-medium">
              <span
                class="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800"
              >
                <CheckIcon
                  disabled="true"
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <span class="ml-4 text-sm font-medium text-gray-900">{{
                step.name
              }}</span>
            </span>
          </router-link>
          <router-link
            tag="button"
            v-else-if="step.status === 'current-complete'"
            :to="step.to"
            class="group flex items-center w-full"
          >
            <span class="px-6 py-4 flex items-center text-sm font-medium">
              <span
                class="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800"
              >
                <CheckIcon
                  disabled="true"
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <span class="ml-4 text-sm font-medium text-indigo-600">{{
                step.name
              }}</span>
            </span>
          </router-link>
          <router-link
            v-else-if="step.status === 'current-incomplete'"
            :to="step.to"
            class="px-6 py-4 flex items-center text-sm font-medium"
            aria-current="step"
          >
            <span
              class="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-indigo-600 rounded-full"
            >
              <span class="text-indigo-600">{{ step.id }}</span>
            </span>
            <span class="ml-4 text-sm font-medium text-gray-900">{{
              step.name
            }}</span>
          </router-link>
          <router-link
            v-else-if="step.status === 'current'"
            :to="step.to"
            class="px-6 py-4 flex items-center text-sm font-medium"
            aria-current="step"
          >
            <span
              class="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-indigo-600 rounded-full"
            >
              <span class="text-indigo-600">{{ step.id }}</span>
            </span>
            <span class="ml-4 text-sm font-medium text-indigo-600">{{
              step.name
            }}</span>
          </router-link>
          <router-link
            tag="button"
            v-else-if="step.status === 'disabled'"
            :to="step.to"
            class="disabled group flex items-center w-full"
          >
            <span class="px-6 py-4 flex items-center text-sm font-medium">
              <span
                class="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800"
              >
                <CheckIcon
                  disabled="true"
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <span class="ml-4 text-sm font-medium text-gray-900">{{
                step.name
              }}</span>
            </span>
          </router-link>
          <router-link
            v-else
            :to="step.to"
            :class="{ disabled: step.visible }"
            class="group flex items-center"
          >
            <span class="px-6 py-4 flex items-center text-sm font-medium">
              <span
                class="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400"
              >
                <span class="text-gray-500 group-hover:text-gray-900">{{
                  step.id
                }}</span>
              </span>
              <span
                class="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900"
                >{{ step.name }}</span
              >
            </span>
          </router-link>
          <template v-if="stepIdx !== this.$store.state.steps.length - 1">
            <!-- Arrow separator for lg screens and up -->
            <div
              class="hidden md:block absolute top-0 right-0 h-full w-5"
              aria-hidden="true"
            >
              <svg
                class="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </template>
        </li>
      </ol>
    </nav>
    <div class="max-w-7xl mx-auto py-6 step-component sm:px-6 lg:px-8 minh-100">
      <!-- Replace with your content -->
      <router-view />
      <!-- /End replace -->
    </div>
  </main>
</template>
<script>
import { CheckIcon } from "@heroicons/vue/solid";
import AccountsService from "../../../services/accounts.service";

export default {
  components: {
    CheckIcon,
  },
  setup() {
    return {
      index: 0,
    };
  },
  data() {
    return {
      tempIndex: 0,
    };
  },
  methods: {
    async getInfo() {
      let account = JSON.parse(localStorage.getItem("account"));
      if (account) {
        let response = await new AccountsService().getAccount(account.id);
        this.step = response.result.informations.find(
          (element) => element.key === "step"
        );
        if (this.step) {
          this.index = this.$store.state.steps.findIndex(
            (element) => element.slug === this.step.value
          );
          if (this.index !== this.$store.state.steps.length - 1) {
            for (let i = 0; i <= this.index; i++) {
              this.$store.state.steps[i].status = "complete";
              this.$store.state.steps[i].visible =
                this.step.value === "wait-for-approve";
            }
          }
        } else {
          this.$store.state.steps.map((step) => (step.status = "upcoming"));
          this.$store.state.steps[0].status = "current";
        }
      }
    },
    async watchSteps() {
      this.$store.state.steps[this.tempIndex].status = "complete";
      let account = JSON.parse(localStorage.getItem("account"));
      if (account) {
        let response = await new AccountsService().getAccount(account.id);

        if (response.result.informations.length > 0) {
          let step = response.result.informations.find(
            (element) => element.key === "step"
          );
          step = this.$store.state.steps.findIndex(
            (element) => element.slug === step.value
          );
          let route_index = this.$store.state.steps.findIndex(
            (element) => element.to === this.$route.path
          );
          step = step < 6 ? step + 1 : step;
          if (
            this.$route.path !== this.$store.state.steps[step].to &&
            route_index !== -1
          ) {
            this.tempIndex = route_index;
            this.$store.state.steps[route_index].status = "current-complete";
            this.$store.state.steps[parseInt(step)].status =
              "current-incomplete";
          } else if (
            this.$route.path === this.$store.state.steps[parseInt(step)].to
          ) {
            this.$store.state.steps[parseInt(step)].status = "current";
            if (this.$store.state.steps[step].slug === "wait-for-approve") {
              for (let i = 0; i < this.$store.state.steps.length; i++) {
                if (i !== 6) {
                  this.$store.state.steps[i].status = "disabled";
                }
              }
            }
          }
        }
      } else {
        if (localStorage.getItem("role_name") === "customer") {
          await this.$router.push({
            name: "Şirket Durumu",
            params: { lang: this.$store.state.lang },
          });
        } else {
          await this.$router.push({
            name: "AdvisorDashboard",
            params: { lang: this.$store.state.lang },
          });
        }
      }
    },
  },
  async created() {
    await this.getInfo();
    await this.watchSteps();
  },
  computed: {
    watchRoute() {
      return this.$route;
    },
  },
  watch: {
    async watchRoute() {
      await this.watchSteps();
    },
  },
};
</script>

<style lang="css">
.disabled {
  opacity: 1;
  pointer-events: none;
}

.step-step-nav {
  border-bottom: 0px;
  box-shadow: 0px 11px 20px -11px #66666647;
}

@media only screen and (max-width: 600px) {
  .step-component {
    padding: 20px;
  }
}
</style>
