<!-- This example requires Tailwind CSS personal-infov2.0+ -->
<template>
  <main>
    <div class="max-w-7xl mx-auto minh-100">
      <!-- Replace with your content -->
      <div class="bg-white">
        <div class="pb-12 sm:mt-12 sm:pb-16 lg:mt-8 lg:pb-24">
          <div class="relative">
            <div class="relative z-10 max-w-7xl mx-auto">
              <div
                class="max-w-md mx-auto space-y-4 lg:max-w-7xl lg:grid lg:gap-5 lg:space-y-0"
                :class="
                  'lg:grid-cols-' +
                  packets.filter((x) => x.type !== 'request').length
                "
              >
                <div
                  v-for="packet in packets"
                  :key="packet.name"
                  v-show="packet.type !== 'request'"
                  class="flex flex-col rounded-lg overflow-hidden packed-shadow"
                >
                  <div
                    class="px-6 py-8 bg-white sm:p-8 sm:pb-6 padding-btm h-full relative"
                  >
                    <div>
                      <h3
                        class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600 sticker-size"
                        id="tier-standard"
                      >
                        {{ packet.name }}
                      </h3>
                    </div>
                    <div
                      class="mt-4 flex items-baseline text-6xl font-bold price-txt-size"
                    >
                      {{ packet.price }} ₺
                      <span
                        class="ml-1 text-2xl font-medium text-gray-500 small-tx"
                      >
                        / {{ $t("message.monthly") }}
                      </span>
                    </div>
                    <div
                      class="flex flex-col items-start justify-between bg-white mt-6 mb-6"
                    >
                      <ul role="list" class="space-y-4 mb-16">
                        <li
                          v-for="feature in packet.features"
                          :key="feature"
                          class="flex items-start"
                        >
                          <div class="flex-shrink-0">
                            <CheckIcon
                              class="h-6 w-6 text-green-500"
                              aria-hidden="true"
                            />
                          </div>
                          <p class="ml-3 text-base text-gray-700">
                            {{ feature.name }}
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div class="space-y-6 absolute bottom-0 left-0 w-full">
                      <hr v-show="haveCompany" />
                      <div
                        v-show="haveCompany"
                        v-for="child in packet.childrens"
                        :key="child.id"
                        class="ml-3 mr-3 text-base text-gray-700"
                      >
                        <div class="title">{{ child.name }}</div>
                        <div class="price">
                          {{ child.price }}₺
                          <span class="ml-1 text-2xl font-medium text-gray-500">
                          </span>
                          <div class="text-line text-gray-500">
                            ({{ $t("message.forOnce") }})
                          </div>
                        </div>
                      </div>
                      <div>
                        <router-link
                          @click="addInfo(packet)"
                          :to="`/${$store.state.lang}/app/office/application-form/payment`"
                          type="submit"
                          class="mb-5 ml-5 mr-5 block justify-center py-2 px-4 font-medium rounded-md text-white text-center bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          {{ $t("message.choose") }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /End replace -->
    </div>
  </main>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/outline";
import ProductsService from "../../../services/products.service";
import AccountsService from "../../../services/accounts.service";

export default {
  components: {
    CheckIcon,
  },
  computed: {
    watchLang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    async watchLang(val) {
      localStorage.setItem("lang", val);
      await this.getPackets();
    },
  },
  methods: {
    async getPackets() {
      this.accountInfo.office_location = JSON.parse(
        this.accountInfo.office_location
      );
      let response = await new ProductsService().getProducts(
        "",
        "",
        `filters=locations:${this.accountInfo.office_location.id}`
      );
      this.packets = response.result;
    },
    async addInfo(packet) {
      let response = await new ProductsService().addOfficePacketInfo(
        packet,
        "",
        this.step_name
      );
      if (response.status === 201) {
        await this.$router.push({
          name: "OfficePayment",
          params: { lang: this.$store.state.lang },
        });
        this.$store.state.endlessOfficeSteps[1].status = "complete";
        this.$store.state.endlessOfficeSteps[2].status = "current";
      }
    },
    async getInfo() {
      let response = await new AccountsService().getAccount(
        JSON.parse(localStorage.getItem("account")).id
      );
      if (response.result.informations.length > 0) {
        for (let i = 0; i < response.result.informations.length; i++) {
          this.accountInfo[response.result.informations[i].key] =
            response.result.informations[i].value;
        }
      }
      let index = this.$store.state.endlessOfficeSteps.findIndex(
        (step) => step.slug === this.accountInfo.office_step
      );
      let route_index = this.$store.state.endlessOfficeSteps.findIndex(
        (step) => step.to === this.$route.path
      );
      if (index > route_index) {
        this.step_name = this.accountInfo.step;
      } else {
        this.step_name = "office-packets";
      }
    },
  },
  data() {
    return {
      haveCompany: false,
      packets: [],
      accountInfo: {},
      step_name: "",
    };
  },
  async created() {
    await this.getInfo();
    await this.getPackets();
  },
};
</script>

<style scoped>
.price-txt-size {
  font-size: 3rem !important;
}

.sticker-size {
  font-size: 0.675rem !important;
}

.small-tx {
  font-size: 0.9rem !important;
}

.packed-shadow {
  box-shadow: 1px 6px 17px 0px #cfcfcf;
}

.started-price-info {
  font-size: 13px;
  line-height: 19px;
  color: #6d6d6d;
  font-weight: 300;
}

.title {
  font-weight: 600;
}

.price {
  font-weight: 800;
  font-size: 27px;
  margin-top: 18px;
  line-height: 10px;
}

.text-line {
  font-weight: 300 !important;
  font-size: 0.8rem !important;
}

.padding-btm {
  padding-bottom: 0px !important;
}
</style>
