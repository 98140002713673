<template>
  <div class="input-container">
    <label v-show="label" class="form-label txt-signika-regular">{{
      label
    }}</label>
    <vue-tel-input
      v-model="model"
      @input="
        $emit('update:modelValue', $event.target.value),
          $emit('getCodes', this.selectedDialCode, this.selectedCountryCode)
      "
      :inputOptions="options"
      v-on:country-changed="countryChanged"
      class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      :placeholder="placeholder"
      :preferredCountries="preferredCountries"
      v-on:keypress="phoneValidations($event)"
      :validCharactersOnly="validCharactersOnly"
      :autoFormat="false"
      v-bind:maxLength="10"
      pattern="^\d{10}$"
    ></vue-tel-input>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
export default {
  components: {
    VueTelInput,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: String,
    modelValue: String,
    setVal: Function,
  },
  data() {
    return {
      options: {
        autocomplete: "off",
        required: true,
        type: "tel",
        mode: "international",
      },
      maxLength: 10,
      model: this.modelValue,
      preferredCountries: ["TR"],
      validCharactersOnly: true,
      selectedDialCode: "",
      selectedCountryCode: "",
    };
  },
  computed: {
    watchModel() {
      return this.modelValue;
    },
  },
  watch: {
    async watchModel(val) {
      this.model = val;
    },
  },
  methods: {
    countryChanged(country) {
      this.selectedDialCode = country.dialCode;
      this.selectedCountryCode = country.iso2;
    },
    phoneValidations() {
      if (this.model.length >= 10) {
        event.preventDefault();
      }
    },
  },
};
</script>
<style>
.vue-tel-input {
  display: flex;
  border-radius: 0.375rem !important;
  border: 1px solid rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  text-align: left;
  box-shadow: 1px 1px 1px transparent !important;
}
[type="tel"]:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
</style>
