<template>
  <div
    v-if="checkPermission('show announcements')"
    class="accounts-container max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100"
  >
    <div
      v-show="checkPermission('store announcements')"
      class="bg-gray-100 rounded-md call-info-div mb-2 mt-4 border border-2 border-gray-500 border-dashed p-4"
    >
      <label class="block text-md font-large text-gray-700 mb-2 uppercase">
        Duyuru
      </label>
      <div class="call-info-content grid grid-cols-2 gap-3">
        <div class="lg:w-full mb-2">
          <label class="text-sm font-medium text-gray-700">Başlık</label>
          <div class="relative w-full">
            <input
              :maxlength="250"
              v-model="announcement.title"
              id="title"
              name="title"
              class="input-height mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div class="lg:w-full mb-2">
          <label class="text-sm font-medium text-gray-700">Paket</label>
          <div class="relative w-full">
            <select
              v-model="announcement.product"
              id="location"
              name="location"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option
                v-for="product in products"
                :key="product.id"
                :value="product.id"
              >
                {{ product.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="lg:w-full mb-2">
          <label class="text-sm font-medium text-gray-700">Roller</label>
          <div class="relative w-full">
            <select
              v-model="announcement.role_name"
              id="location"
              name="location"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option v-for="role in roles" :key="role.id" :value="role.id">
                {{ role.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full mb-2 col-start-1 col-span-2">
          <label class="text-sm font-medium text-gray-700">Metin</label>
          <div class="relative w-full">
            <textarea
              :maxlength="250"
              class="input-height mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              v-model="announcement.content"
              type="text"
            />
          </div>
        </div>
        <div class="col-start-1 col-span-2 filter-button">
          <button
            @click="addAnnouncement"
            class="mt-1 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Ekle
          </button>
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-10">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Duyuru
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Paket
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Düzenle</span>
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="announcements.length"
                class="bg-white divide-y divide-gray-200"
              >
                <tr v-for="(announcement, key) in announcements" :key="key">
                  <td
                    class="px-6 py-4 whitespace-normal text-sm font-medium text-gray-900"
                  >
                    {{ announcement.content }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    v-if="announcement.product"
                  >
                    {{ announcement.product.name }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    v-else
                  >
                    -
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <button
                      v-show="checkPermission('update announcements')"
                      @click="getAnnouncement(announcement.id)"
                      class="border border-transparent text-indigo-600 hover:text-indigo-900"
                    >
                      <PencilIcon class="h-5 w-5"></PencilIcon>
                    </button>
                    <button
                      v-show="checkPermission('destroy announcements')"
                      @click="setDeleteModal(announcement.id)"
                      class="ml-2 border border-transparent text-indigo-600 hover:text-indigo-900"
                    >
                      <TrashIcon class="h-5 w-5"></TrashIcon>
                    </button>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
              <tbody v-else class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          Listelenecek duyuru kaydınız bulunmamaktadır
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="lg:col-span-9">
    <!-- Profile section -->
    <div class="flex justify-center items-center py-12">
      <div
        class="flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="text-white uppercase text-lg text-center">
          <div>BU SAYFAYI GÖRÜNTÜLEMEK İÇİN YETKİNİZ BULUNMAMAKTADIR.</div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="updateModal"
    class="h-full fixed inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center pt-4 px-4 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="modal-cont inline-block align-bottom h-full bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:h-full sm:max-w-xl sm:w-full sm:p-6"
      >
        <div class="text-center">
          <div>
            <div class="flex-grow">
              <div class="text-center">
                <h3>Arama Bilgisi Düzenleme</h3>
              </div>
              <div>
                <div class="mt-3 sm:mt-5">
                  <div class="mt-2">
                    <div class="flex-grow">
                      <div class="mt-6 grid grid-cols-12 gap-3 flex-grow">
                        <div class="col-span-12 sm:col-span-12 mt-2">
                          <label
                            class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"
                          >
                            Metin
                          </label>
                          <input
                            v-model="announcementUpdate.content"
                            type="text"
                            name="city"
                            class="mt-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
          >
            <button
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
              @click="editAnnouncement"
            >
              Kaydet
            </button>
            <button
              @click="updateModal = false"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              ref="cancelButtonRef"
            >
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TransitionRoot as="template" :show="deleteModal">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="deleteModal = false"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="flex justify-center items-center">
              <div
                class="flex justify-center items-center w-full overflow-hidden py-2 sm:px-6 lg:px-8"
              >
                <div class="text-lg text-center">
                  <div>Arama Bilgisini silmek istediğinize emin misiniz?</div>
                </div>
              </div>
            </div>

            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                @click="deleteAnnouncement"
              >
                Sil
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                @click="deleteModal = false"
                ref="cancelButtonRef"
              >
                Kapat
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import { Mixin } from "../../library/utils";
import { PencilIcon, TrashIcon } from "@heroicons/vue/outline";
import AnnouncementService from "../../services/announcements.service";
import PermissionsService from "@/services/permissions.service";

import {
  TransitionChild,
  TransitionRoot,
  Dialog,
  DialogOverlay,
} from "@headlessui/vue";
import ProductsService from "@/services/products.service";

export default {
  mixins: [Mixin],
  components: {
    PencilIcon,
    TransitionRoot,
    TransitionChild,
    TrashIcon,
    Dialog,
    DialogOverlay,
  },
  setup() {},
  data() {
    return {
      announcements: [],
      deleteModal: false,
      accountId: 0,
      account: {},
      updateModal: false,
      addModal: false,
      advisors: [],
      accountCheck: "",
      selectedItem: { name: "" },
      advisor: {},
      user_id: 0,
      informations: {},
      announcement: {
        title: "",
        content: "",
      },
      announcementUpdate: {
        content: "",
      },
      masks: {
        input: "DD-MM-YYYY",
      },
      maxDate: new Date(),
      id: 0,
      products: [],
      role_name: "",
      roles: [],
    };
  },
  computed: {
    setUserPermission() {
      return this.role_name;
    },
  },
  methods: {
    async getAnnouncement(id) {
      if (id) {
        let response = await new AnnouncementService().getAnnouncements(id);
        this.announcementUpdate = response.result;
        this.tempannouncementUpdate = this.announcementUpdate;
        this.updateModal = true;
      } else {
        let response = await new AnnouncementService().getAnnouncements();
        this.announcements = response.result.data;
      }
    },
    async addAnnouncement() {
      let response = await new AnnouncementService().addAnnouncement(
        this.announcement
      );
      if (response.status === 201) {
        this.announcement = {
          content: "",
        };
        this.$store.dispatch("showNotification", {
          title: "İşlem başarıyla gerçekleşti",
          description: "",
          type: "success",
        });
        await this.getAnnouncement();
      } else if (response.status === 422) {
        this.$store.dispatch("showNotification", {
          title: "Hata",
          description: response.data.errors.title[0],
          type: "error",
        });
      } else {
        this.$store.dispatch("showNotification", {
          title: "Lütfen daha sonra tekrar deneyiniz",
          description: "",
          type: "error",
        });
      }
    },
    async editAnnouncement() {
      await new AnnouncementService().editAnnouncement(
        this.announcementUpdate,
        this.announcementUpdate.id
      );
      this.updateModal = false;
      await this.getAnnouncement();
    },
    setDeleteModal(id) {
      this.id = id;
      this.deleteModal = true;
    },
    async deleteAnnouncement() {
      await new AnnouncementService().deleteAnnouncement(this.id);
      await this.getAnnouncement();
      this.deleteModal = false;
    },
    async getProducts() {
      let response = await new ProductsService().getProducts("", "is_main:1");
      this.products = response.result;
    },
    async getRoles() {
      let response = await new PermissionsService().getRoles();
      this.roles = response.result.data;
    },
  },
  async created() {
    if (this.checkPermission("show announcements")) {
      await this.getAnnouncement();
      await this.getProducts();
      await this.getRoles();
    }
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .accounts-container {
    padding: 15px;
  }
}

.input-height {
  height: 37px;
}
</style>
