import axios from "axios";
import authHeader from "./auth-header";

class LocationService {
  getLocations(params) {
    return axios
      .get(`locations`, { params, headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  addLocationInfo(location, step_name) {
    return axios
      .post(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/informations`,
        {
          informations: [
            {
              key: "office_location",
              value: location,
            },
            {
              key: "office_step",
              value: step_name,
            },
          ],
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default LocationService;
