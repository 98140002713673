<template>
  <main>
    <div
      class="flex justify-center bg-blue-100 rounded-lg p-3 align-center text-center"
    >
      <div class="text-center text-gray-800 mt-1">
        {{ $t("message.stageText") }}
      </div>
      <div>
        <button
          @click="
            $router.push({
              name: 'EndlessNetworkMyCompanyStage',
              params: { lang: this.$store.state.lang },
            })
          "
          style="align-items: center"
          class="w-48 h-8 ml-3 inline-flex justify-center text-center px-4 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 align-center focus:ring-offset-2 focus:ring-blue-500"
        >
          {{ $t("message.seminar") }}
        </button>
      </div>
    </div>
    <div class="max-w-7xl mx-auto minh-100 mt-5">
      <div class="inset-0 mb-10">
        <div class="bg-white h-1/3 sm:h-2/3">
          {{ $t("message.seminars_description") }}
        </div>
      </div>
      <div class="bg-white pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
        <div class="grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          <div
            v-for="stage in this.stages"
            :key="stage.id"
            class="shadow-lg rounded-lg overflow-hidden"
          >
            <div class="flex justify-between">
              <img
                v-if="stage.stand_image"
                class=""
                style="width: 100%; height: 150px; object-fit: cover"
                :src="stage.stand_image"
                alt="torun"
              />
              <img
                v-else
                class=""
                style="width: 100%; height: 150px; object-fit: cover"
                src="/img/torun-center.jpeg"
                alt="torun"
              />
            </div>
            <a href="#" class="block mt-5 px-2">
              <p class="text-xl font-semibold text-gray-900 p-2">
                {{ stage.stand_name }}
              </p>
            </a>
            <div class="mt-5 flex items-center px-4 border-t py-2">
              <div class="ml-3 mt-2">
                <div
                  class="flex items-center justify-center space-x-1 text-center text-sm text-gray-500"
                >
                  <div class="flex justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="blue"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    <span class="text-blue-700"></span>
                  </div>
                  <div>
                    <button
                      v-if="stage.active"
                      @click="
                        $router.push({
                          name: 'EndlessNetworkStage',
                          params: { id: 1, lang: this.$store.state.lang },
                        })
                      "
                      class="w-32 h-8 ml-3 inline-flex justify-center items-center text-center px-4 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Katıl
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import StageService from "@/services/stage.service";

export default {
  name: "Stand",
  data() {
    return {
      stages: [],
    };
  },
  methods: {
    async getStage() {
      let response = await new StageService().getStages();
      this.stages = response;
    },
  },
  async created() {
    await this.getStage();
  },
};
</script>

<style scoped></style>
