<template>
  <div v-if="checkPackets" class="">
    <div
      class="accounts-container max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100"
    >
      <div class="flex flex-col justify-center items-center gap-4">
        <div class="text-center text-gray-600 font-bold">
          <div class="text-xl">Üzgünüz !</div>
          Office paketi satın almadan aramaları görüntüleyemezsiniz.
        </div>
        <router-link
          class="bg-indigo-200 font-bold text-md m-auto px-4 py-2 rounded mt-2"
          :to="{
            name: 'OfficeLocation',
            params: { lang: this.$store.state.lang },
          }"
        >
          Paket Al
        </router-link>
      </div>
    </div>
  </div>
  <div v-else>
    <div
      v-if="checkPermission('show calls')"
      class="accounts-container max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100"
    >
      <div
        v-if="checkPermission('store calls')"
        class="bg-gray-100 rounded-md call-info-div mb-2 mt-4 border border-2 border-gray-500 border-dashed"
      >
        <label
          class="block text-md font-large text-gray-700 pt-3 px-5 mb-2 uppercase"
        >
          {{ $t("message.callInfo") }}
        </label>
        <div
          class="call-info-content px-5 lg:flex xl:flex lg:space-x-5 xl:space-x-5"
        >
          <div class="lg:w-full md:w-5/6 mb-2">
            <label class="text-sm font-medium text-gray-700">{{
              $t("message.companyName")
            }}</label>
            <div class="relative w-full">
              <input
                class="input-height mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                v-model="call.company_name"
                type="text"
              />
            </div>
          </div>
          <div class="lg:w-full md:w-4/6 mb-2">
            <label class="text-sm font-medium text-gray-700">{{
              $t("message.callerPerson")
            }}</label>
            <text-input v-model="call.caller"></text-input>
          </div>
          <div class="lg:w-full md:w-5/6 mb-2">
            <label class="text-sm font-medium text-gray-700">{{
              $t("message.phone")
            }}</label>
            <phone-number-input
              :phone="phone"
              v-model="call.phone"
            ></phone-number-input>
          </div>
          <div class="lg:w-full md:w-5/6 mb-2">
            <label class="text-sm font-medium text-gray-700">{{
              $t("message.callDate")
            }}</label>
            <Calendar
              id="date"
              v-model="call.call_date"
              class="flex-grow w-full border-gray-300"
              dateFormat="dd-mm-yy"
              :max-date="maxDate"
              autoComplete="off"
              locale="tr"
            >
            </Calendar>
          </div>
          <div class="lg:w-full md:w-5/6 mb-2">
            <label class="text-sm font-medium text-gray-700">{{
              $t("message.notes")
            }}</label>
            <div class="relative w-full">
              <input
                class="input-height mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                v-model="call.note"
                type="text"
              />
            </div>
          </div>

          <div class="mt-5 filter-button">
            <button
              @click="addCall"
              class="mt-1 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {{ $t("message.add") }}
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("message.companyName") }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("message.callerPerson") }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("message.phone") }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("message.notes") }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("message.callDate") }}
                    </th>

                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>

                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">{{ $t("message.edit") }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="calls.length"
                  class="bg-white divide-y divide-gray-200"
                >
                  <tr v-for="(call, key) in calls" :key="key">
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      {{ call.company_name }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      <span> {{ call.caller }}</span>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      <span>
                        {{ call.phone }}
                      </span>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      <span> {{ call.note }}</span>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      <span
                        >{{ call.call_date.substring(8, 11) }}-{{
                          call.call_date.substring(5, 8)
                        }}{{ call.call_date.substring(0, 4) }}
                        {{ call.call_date.substring(11, 16) }}</span
                      >
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      <span>{{ call.user.name }}</span>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <button
                        v-show="checkPermission('update calls')"
                        @click="getCall(call.id)"
                        class="border border-transparent text-indigo-600 hover:text-indigo-900"
                      >
                        <PencilIcon class="h-5 w-5"></PencilIcon>
                      </button>
                      <button
                        v-show="checkPermission('destroy calls')"
                        @click="setDeleteModal(call.id)"
                        class="ml-2 border border-transparent text-indigo-600 hover:text-indigo-900"
                      >
                        <TrashIcon class="h-5 w-5"></TrashIcon>
                      </button>
                    </td>
                  </tr>

                  <!-- More people... -->
                </tbody>
                <tbody v-else class="bg-white divide-y divide-gray-200">
                  <tr>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {{ $t("message.noResultFound") }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="lg:col-span-9">
      <!-- Profile section -->
      <div class="flex justify-center items-center py-12">
        <div
          class="flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
        >
          <div class="text-white uppercase text-lg text-center">
            <div>{{ $t("message.dontHavePermission") }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="updateModal"
      class="h-full fixed inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center pt-4 px-4 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="modal-cont inline-block align-bottom h-full bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:h-full sm:max-w-xl sm:w-full sm:p-6"
        >
          <div class="text-center">
            <div>
              <div class="flex-grow">
                <div class="text-center">
                  <h3>{{ $t("message.callInfo") }} {{ $t("message.edit") }}</h3>
                </div>
                <div>
                  <div class="mt-3 sm:mt-5">
                    <div class="mt-2">
                      <div class="flex-grow">
                        <div class="mt-6 grid grid-cols-12 gap-3 flex-grow">
                          <div class="col-span-12 sm:col-span-12 mt-2">
                            <label
                              class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"
                            >
                              {{ $t("mesaage.companyName") }}
                            </label>
                            <input
                              v-model="callUpdate.company_name"
                              type="text"
                              name="city"
                              class="mt-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div class="col-span-12 sm:col-span-12">
                            <label
                              class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"
                            >
                              {{ $t("mesaage.callerPerson") }}
                            </label>
                            <text-input
                              v-model="callUpdate.caller"
                            ></text-input>
                          </div>
                          <div class="col-span-12 sm:col-span-12 mt-2">
                            <label
                              class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"
                            >
                              {{ $t("mesaage.phone") }}
                            </label>
                            <phone-number-input
                              :phone="callUpdate.phone"
                              v-model="callUpdate.phone"
                            ></phone-number-input>
                          </div>
                          <div class="col-span-12 sm:col-span-12 mt-2">
                            <label
                              class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"
                            >
                              {{ $t("mesaage.notes") }}
                            </label>
                            <input
                              v-model="callUpdate.note"
                              type="text"
                              name="city"
                              class="mt-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                          <div class="col-span-12 sm:col-span-12 mt-2">
                            <label
                              class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"
                            >
                              {{ $t("mesaage.callDate") }}
                            </label>
                            <DatePicker
                              v-model="callUpdate.call_date"
                              mode="dateTime"
                              is24hr
                              :masks="masks"
                              :max-date="maxDate"
                              locale="tr"
                            >
                              <template v-slot="{ inputValue, inputEvents }">
                                <input
                                  class="input-height mt-1 bg-white text-gray-700 w-full py-2 px-3 appearance-none border border-gray-300 rounded-md focus:outline-none h-10"
                                  :value="inputValue"
                                  v-on="inputEvents"
                                />
                              </template>
                            </DatePicker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                @click="editCall"
              >
                {{ $t("mesaage.save") }}
              </button>
              <button
                @click="updateModal = false"
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                ref="cancelButtonRef"
              >
                {{ $t("mesaage.close") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TransitionRoot as="template" :show="deleteModal">
      <Dialog
        as="div"
        class="fixed z-10 inset-0 overflow-y-auto"
        @close="deleteModal = false"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            >
              <div class="flex justify-center items-center">
                <div
                  class="flex justify-center items-center w-full overflow-hidden py-2 sm:px-6 lg:px-8"
                >
                  <div class="text-lg text-center">
                    <div>{{ $t("mesaage.areYouSure") }}</div>
                  </div>
                </div>
              </div>

              <div
                class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
              >
                <button
                  type="button"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  @click="deleteCall"
                >
                  {{ $t("mesaage.delete") }}
                </button>
                <button
                  type="button"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  @click="deleteModal = false"
                  ref="cancelButtonRef"
                >
                  {{ $t("mesaage.close") }}
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script>
import { Mixin } from "../../library/utils";
import { PencilIcon, TrashIcon } from "@heroicons/vue/outline";
import PhoneNumberInput from "../Inputs/PhoneNumberInput";
import TextInput from "../Inputs/TextInput";
import { DatePicker } from "v-calendar";
import CallsService from "../../services/calls.service";
import moment from "moment";

import {
  TransitionChild,
  TransitionRoot,
  Dialog,
  DialogOverlay,
} from "@headlessui/vue";
// import AccountsService from "@/services/accounts.service";

export default {
  mixins: [Mixin],
  components: {
    TextInput,
    PhoneNumberInput,
    PencilIcon,
    DatePicker,
    TransitionRoot,
    TransitionChild,
    TrashIcon,
    Dialog,
    DialogOverlay,
  },
  setup() {},
  data() {
    return {
      calls: [],
      deleteModal: false,
      accountId: 0,
      account: {},
      updateModal: false,
      addModal: false,
      advisors: [],
      accountCheck: "",
      selectedItem: { name: "" },
      advisor: {},
      user_id: 0,
      informations: {},
      call: {
        phone: "",
        note: "",
        company_name: "",
        call_date: null,
        caller: "",
      },
      callUpdate: {
        phone: "",
        note: "",
        company_name: "",
        call_date: null,
        caller: "",
      },
      masks: {
        input: "DD-MM-YYYY",
      },
      maxDate: new Date(),
      id: 0,
      tempCallUpdate: {
        phone: "",
        note: "",
        company_name: "",
        call_date: null,
        caller: "",
      },
      stepOffice: {},
      stepsOffice: [
        {
          id: "1",
          name: "Lokasyon",
          to: `/${this.$store.state.lang}/app/office/application-form`,
          status: "current",
          visible: true,
          slug: "location",
        },
        {
          id: "2",
          name: "Paketler",
          to: `/${this.$store.state.lang}/app/office/application-form/packets`,
          status: "upcoming",
          visible: true,
          slug: "office-packets",
        },
        {
          id: "3",
          name: "Ödeme",
          to: `/${this.$store.state.lang}/app/office/application-form/payment`,
          status: "upcoming",
          visible: true,
          slug: "office-payment",
        },
        {
          id: "4",
          name: "Onay",
          to: `/${this.$store.state.lang}/app/office/application-form/wait-for-approve`,
          status: "upcoming",
          visible: true,
          slug: "office-wait-for-approve",
        },
      ],
      officeIndex: -1,
      officePackets: [],
      secretary: { phone: 0, name: "" },
    };
  },
  methods: {
    checkPackets() {
      return (
        localStorage.getItem("role_name") === "customer" &&
        this.$store.state.officePackets.length > 0
      );
    },
    async getCall(id) {
      if (this.$store.state.officePackets.length > 0) {
        if (id) {
          let response = await new CallsService().getCalls(id);
          this.callUpdate = response.result;
          this.tempCallUpdate = this.callUpdate;
          this.updateModal = true;
        } else {
          let response = await new CallsService().getCalls();
          this.calls = response.result.data;
        }
      }
    },
    async addCall() {
      this.call.call_date = moment(this.call.call_date).format(
        "YYYY-MM-DD hh:mm:ss"
      );
      let response = await new CallsService().addCall(this.call);
      if (response.status === 201) {
        this.call = {
          sender_phone: "",
          note: "",
          company_name: "",
          call_date: null,
          caller: "",
        };
        this.$store.dispatch("showNotification", {
          title: "İşlem başarıyla gerçekleşti",
          description: "",
          type: "success",
        });
        await this.getCall();
      } else if (response.status === 422) {
        this.$store.dispatch("showNotification", {
          title: "Hata",
          description: "Lütfen tüm alanları doldurduğunuzdan emin olun",
          type: "error",
        });
      } else {
        this.$store.dispatch("showNotification", {
          title: "Lütfen daha sonra tekrar deneyiniz",
          description: "",
          type: "error",
        });
      }
    },
    async editCall() {
      if (this.callUpdate.call_date !== this.tempCallUpdate.call_date) {
        this.callUpdate.call_date = moment(this.callUpdate.call_date).format(
          "DD-MM-YYYY hh:mm"
        );
      }
      await new CallsService().editCall(this.callUpdate, this.callUpdate.id);
      this.updateModal = false;
      await this.getCall();
    },
    setDeleteModal(id) {
      this.id = id;
      this.deleteModal = true;
    },
    async deleteCall() {
      await new CallsService().deleteCall(this.id);
      await this.getCall();
      this.deleteModal = false;
    },
    // async getUsersProducts(id) {
    //   let response = await new AccountsService().getAccountsProducts(
    //     id,
    //     "filters=brands:2"
    //   );
    //   if (response.result.data.length !== 0) {
    //     this.$store.state.officePackets = response.result.data;
    //     this.officePackets = response.result.data;
    //   }
    // },
    // async getUserInfo(id) {
    //   if (localStorage.getItem("role_name") === "customer") {
    //     let response = await new AccountsService().getAccount(id);
    //     this.secretary =
    //       response.result.users.find(
    //         (user) => user.roles[0].name === "secretary"
    //       ) !== undefined
    //         ? response.result.users.find(
    //             (user) => user.roles[0].name === "secretary"
    //           )
    //         : {
    //             name: "",
    //             phone: 0,
    //           };
    //     if (this.secretary.name === "" || this.officePackets.length === 0) {
    //       if (this.officeIndex !== -1) {
    //         await this.$router.push(this.stepsOffice[this.officeIndex].to);
    //       } else {
    //         await this.$router.push({
    //           name: "OfficeLocation",
    //           params: { lang: this.$store.state.lang },
    //         });
    //       }
    //     }
    //   }
    // },
  },
  async created() {
    // await this.getInfo(JSON.parse(localStorage.getItem("account")).id);
    // await this.getUsersProducts(JSON.parse(localStorage.getItem("account")).id);
    // await this.getUserInfo(JSON.parse(localStorage.getItem("account")).id);
    if (this.checkPermission("show calls")) {
      await this.getCall();
    }
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .accounts-container {
    padding: 15px;
  }
}

.input-height {
  height: 37px;
}
</style>
