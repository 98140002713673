<!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <div
    class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-12 w-auto"
        src="../../../public/img/siyah.png"
        alt="Workflow"
      />
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <h2 class="text-center text-3xl font-extrabold text-gray-900">
          ŞİFRENİ DEĞİŞTİR
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          Aşağıdaki bilgilerinizi doldurarak şifrenizi güncelleyebilirsiniz
          <a
            href="#"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          ></a>
        </p>
        <div class="mt-6 space-y-6">
          <div>
            <label class="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div class="mt-1">
              <email-input v-model="user.email"></email-input>
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">
              {{ $t("message.new") }} {{ $t("message.password") }}
            </label>
            <div class="mt-1">
              <password-input v-model="user.password"></password-input>
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">
              {{ $t("message.new") }} {{ $t("message.passwordConfirmation") }}
            </label>
            <div class="mt-1">
              <password-input v-model="password"></password-input>
            </div>
          </div>
          <div>
            <button
              @click="changePass"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {{ $t("message.change") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PasswordInput from "../Inputs/PasswordInput";
import AuthService from "../../services/new/auth";
import EmailInput from "../Inputs/EmailInput";
export default {
  components: {
    EmailInput,
    PasswordInput,
  },
  data() {
    return {
      user: {},
      password: "",
      showError: false,
    };
  },
  methods: {
    async changePass() {
      if (
        this.user.password === this.password &&
        this.user.password !== "" &&
        this.user.password.length >= 6
      ) {
        this.user.token = this.$route.query.token;
        await new AuthService().resetPassword(this.user);
        await this.$router.push({
          name: "Login",
          params: { lang: this.$store.state.lang },
        });
      }
    },
  },
};
</script>
