<template>
  <!--  Page header-->
  <div class="max-w-7xl mx-auto minh-100 mb-4">
    <router-link :to="`/${$store.state.lang}/app/accounts`" class="mt-5 flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 19l-7-7 7-7"
        />
      </svg>
      <span class="hover:underline"> Şirket listesi </span></router-link
    >
  </div>
  <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
    <div class="items-center mb-16">
      <div class="border-gray-500 border-b pb-6 border-dotted">
        <div class="">
          <h1 class="text-2xl font-bold text-indigo-600">
            {{ accounts.name }}
          </h1>
          <p class="text-sm font-medium text-gray-500">
            {{ formatedDate(accounts.created_at) }}
          </p>
          <div class="underline" style="width: 8%"></div>
        </div>
        <div class="mt-6">
          <h2 class="mb-4 text-lg leading-6 font-medium text-gray-900">
            Şirket Durumu nedir?
          </h2>
          <p class="text-sm font-medium text-gray-500">
            {{ packet_status }}
          </p>
        </div>
        <div class="mt-6">
          <h2 class="mb-4 text-lg leading-6 font-medium text-gray-900">
            Hangi Pakete Sahip?
          </h2>
          <div class="flex gap-3 items-center">
            <p class="text-sm font-medium text-gray-500">
              {{ informations.packet }}
            </p>
            <div>
              <span
                class="rounded-xl px-4 py-2"
                :class="
                  accounts.status
                    ? 'bg-green-400 text-white'
                    : 'bg-gray-200 text-gray-900 '
                "
              >
                {{ accounts.status ? "Ödendi" : " Ödeme Bekliyor" }}
              </span>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <h2 class="mb-4 text-lg leading-6 font-medium text-gray-900">
            Hangi Office Sahip?
          </h2>
          <div class="flex gap-3 items-center">
            <p class="text-sm font-medium text-gray-500">
              {{ informations.office_packet }}
            </p>
            <div></div>
          </div>
        </div>
      </div>
      <div class="border-gray-500 border-b pb-6 border-dotted">
        <h2 class="mt-6 mb-6 text-lg leading-6 font-medium text-gray-900">
          Kişisel Bilgiler
          <div class="underline"></div>
        </h2>
        <p class="text-sm font-medium text-gray-500"></p>
        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              >Ad-Soyad
            </label>
            <div class="mt-1">
              <p class="text-sm font-medium text-gray-500">
                {{ users.name }}
              </p>
            </div>
          </div>
          <div class="sm:col-span-3">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
              >Email</label
            >
            <div class="mt-1">
              <p class="text-sm font-medium text-gray-500">
                {{ users.email }}
              </p>
            </div>
          </div>
          <div class="sm:col-span-3">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              >Tc
            </label>
            <div class="mt-1">
              <p class="text-sm font-medium text-gray-500">
                {{ informations.identityNo }}
              </p>
            </div>
          </div>
          <div class="sm:col-span-3">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
              >Telefon</label
            >
            <div class="mt-1">
              <p class="text-sm font-medium text-gray-500">
                {{ users.phone }}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <h2 class="text-lg leading-6 font-medium text-gray-900">
            Adres Bilgiler
          </h2>
          <div class="mt-6 grid grid-cols-12 gap-3 flex-grow">
            <div class="col-span-12 sm:col-span-6 px-2">
              <label class="text-center text-base text-gray-900"> İl </label>
              <city-select-input
                @change="getDistricts(accountInfo.city)"
                :cities="cities"
                v-model="accountInfo.city"
                :disabled="true"
              ></city-select-input>
            </div>
            <div class="col-span-12 sm:col-span-6 px-2">
              <label class="text-center text-base text-gray-900"> İlçe </label>
              <district-select-input
                :class="{ disabled: !accountInfo.city }"
                :districts="districts"
                v-model="accountInfo.district"
                :disabled="true"
              ></district-select-input>
            </div>
            <div class="col-span-12 sm:col-span-6 px-2">
              <label class="text-center text-base text-gray-900">
                Mahalle
              </label>
              <p class="text-sm font-medium text-gray-500">
                {{ accountInfo.neighbourhood }}
              </p>
            </div>
            <div class="col-span-12 sm:col-span-6 px-2">
              <label class="text-center text-base text-gray-900"> Cadde </label>
              <p class="text-sm font-medium text-gray-500">
                {{ accountInfo.street }}
              </p>
            </div>
            <div class="col-span-12 sm:col-span-6 px-2">
              <label class="text-center text-base text-gray-900">
                Bina No
              </label>
              <p class="text-sm font-medium text-gray-500">
                {{ accountInfo.buildingNo }}
              </p>
            </div>
            <div class="col-span-12 sm:col-span-6 px-2 mb-2">
              <label class="text-center text-base text-gray-900">
                Daire No
              </label>
              <p class="text-sm font-medium text-gray-500">
                {{ accountInfo.doorNo }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="border-gray-500 border-b pb-6 border-dotted">
        <div class="mt-6">
          <h2 class="text-lg leading-6 font-medium text-gray-900">
            Yüklenen belgeler
            <div class="underline"></div>
          </h2>
          <div
            v-if="
              uploadFilesOther.files.length > 0 ||
              uploadFilesTc.files.length > 0 ||
              uploadFilesCar.files.length > 0 ||
              uploadFilesRental.files.length > 0 ||
              uploadFilesCarInvoice.files.length > 0 ||
              uploadFilesSecondCar.files.length > 0
            "
            class="mt-4 grid grid-cols-12 gap-3 flex-grow"
          >
            <div class="sm:col-span-6">
              <p class="text-sm font-medium text-gray-500">
                {{ $t("message.identityCardPhoto") }}
              </p>
              <ul
                class="sm:col-span-2"
                v-if="uploadFilesTc.files.length > 0"
                v-cloak
              >
                <li
                  class="text-sm p-1 remove-btn-content"
                  v-for="(file, index) in uploadFilesTc.files"
                  :key="index"
                >
                  <div class="flex justify-between">
                    <div class="flex justify-center space-x-6">
                      <a
                        :href="`https://api.emusavirim.net/${file.path}`"
                        download
                      >
                        {{ file.name }}
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="sm:col-span-6">
              <p class="text-sm font-medium text-gray-500">
                {{ $t("message.otherFiles") }}
              </p>
              <ul
                class="sm:col-span-2"
                v-if="uploadFilesOther.files.length > 0"
                v-cloak
              >
                <li
                  class="text-sm p-1 remove-btn-content"
                  v-for="(file, index) in uploadFilesOther.files"
                  :key="index"
                >
                  <div class="flex justify-between">
                    <div class="flex justify-center space-x-6">
                      <a
                        :href="`https://api.emusavirim.net/${file.path}`"
                        download
                      >
                        {{ file.name }}
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="sm:col-span-6">
              <p class="text-sm font-medium text-gray-500">
                {{ $t("message.carLicense") }}
              </p>
              <ul
                class="sm:col-span-2"
                v-if="uploadFilesCar.files.length > 0"
                v-cloak
              >
                <li
                  class="text-sm p-1 remove-btn-content"
                  v-for="(file, index) in uploadFilesCar.files"
                  :key="index"
                >
                  <div class="flex justify-between">
                    <div class="flex justify-center space-x-6">
                      <a
                        :href="`https://api.emusavirim.net/${file.path}`"
                        download
                      >
                        {{ file.name }}
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="sm:col-span-6">
              <p class="text-sm font-medium text-gray-500">
                {{ $t("message.rentalContract") }}
              </p>
              <ul
                class="sm:col-span-2"
                v-if="uploadFilesRental.files.length > 0"
                v-cloak
              >
                <li
                  class="text-sm p-1 remove-btn-content"
                  v-for="(file, index) in uploadFilesRental.files"
                  :key="index"
                >
                  <div class="flex justify-between">
                    <div class="flex justify-center space-x-6">
                      <a
                        :href="`https://api.emusavirim.net/${file.path}`"
                        download
                      >
                        {{ file.name }}
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="sm:col-span-6">
              <p class="text-sm font-medium text-gray-500">
                {{ $t("message.carInvoice") }}
              </p>
              <ul
                class="sm:col-span-2"
                v-if="uploadFilesCarInvoice.files.length > 0"
                v-cloak
              >
                <li
                  class="text-sm p-1 remove-btn-content"
                  v-for="(file, index) in uploadFilesCarInvoice.files"
                  :key="index"
                >
                  <div class="flex justify-between">
                    <div class="flex justify-center space-x-6">
                      <a
                        :href="`https://api.emusavirim.net/${file.path}`"
                        download
                      >
                        {{ file.name }}
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="sm:col-span-6">
              <p class="text-sm font-medium text-gray-500">
                {{ $t("message.salesContract") }}
              </p>
              <ul
                class="sm:col-span-2"
                v-if="uploadFilesSecondCar.files.length > 0"
                v-cloak
              >
                <li
                  class="text-sm p-1 remove-btn-content"
                  v-for="(file, index) in uploadFilesSecondCar.files"
                  :key="index"
                >
                  <div class="flex justify-between">
                    <div class="flex justify-center space-x-6">
                      <a
                        :href="`https://api.emusavirim.net/${file.path}`"
                        download
                      >
                        {{ file.name }}
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-else class="mt-6">
            <p class="text-sm font-medium text-gray-500">
              Yüklenen belge bulunmamaktadir.
            </p>
          </div>
        </div>
      </div>
      <div class="border-gray-500 border-b pb-6 border-dotted">
        <div class="mt-6">
          <h2 class="text-lg leading-6 font-medium text-gray-900">
            Ek hizmetler
            <div class="underline"></div>
          </h2>
          <div
            v-if="accountInfo.additional_services !== {}"
            class="sm:col-span-6 mt-6"
          >
            <ul
              class="sm:col-span-2"
              v-if="accountInfo.additional_services.length > 0"
              v-cloak
            >
              <li
                class="text-sm font-medium p-1 remove-btn-content"
                v-for="(packet, index) in accountInfo.additional_services"
                :key="index"
              >
                {{ packet.name }}
              </li>
            </ul>
          </div>
          <div v-else class="mt-6">
            <p class="text-sm font-medium text-gray-500">
              Yüklenen belge bulunmamaktadir.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountsService from "@/services/accounts.service";
import CityDistrictService from "@/services/city-district.service";

import CitySelectInput from "@/views/Inputs/CitySelectInput.vue";
import DistrictSelectInput from "@/views/Inputs/DistrictSelectInput.vue";
import DocumentsService from "@/services/documents.service";
import message from "primevue/message/Message.vue";
import moment from "moment/moment";

export default {
  name: "AccountsDetail",
  components: { CitySelectInput, DistrictSelectInput },
  data() {
    return {
      accounts: [],
      packet_name: "",
      users: {},
      accountInfo: {
        identityNo: "",
        birthCity: "",
        birthDist: "",
        birthDate: "",
        city: "",
        district: "",
        phone: "",
        street: "",
        neighbourhood: "",
        buildingNo: "",
        doorNo: "",
        additional_services: [],
      },
      cities: [],
      districts: [],
      uploadFilesTc: { type: "tc", files: [] },
      uploadFilesOther: { type: "other", files: [] },
      uploadFilesCar: { type: "car", files: [] },
      uploadFilesRental: { type: "rental", files: [] },
      uploadFilesCarInvoice: { type: "invoice", files: [] },
      uploadFilesSecondCar: { type: "second", files: [] },
    };
  },
  methods: {
    formatedDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    message() {
      return message;
    },
    async getAccount() {
      this.informations = {};
      let response = await new AccountsService().getAccountAll(
        this.$route.params.id,
        "all"
      );
      this.accounts = response.result;
      this.users = response.result.users[0];
      for (let i = 0; i < response.result.informations.length; i++) {
        this.informations[response.result.informations[i].key] =
          response.result.informations[i].value;
      }
      if (this.informations.packet_type === "1")
        this.packet_status = "Şirket Kurma";
      else if (this.informations.packet_type === "2")
        this.packet_status = "Şirket Taşıma";

      for (let i = 0; i < response.result.informations.length; i++) {
        this.accountInfo[response.result.informations[i].key] =
          response.result.informations[i].value;
      }
      if (this.accountInfo.city) {
        await this.getDistricts(this.accountInfo.city);
      }
    },
    async getCities() {
      let response = await new CityDistrictService().getCities();
      this.cities = response.result.data;
    },
    async getDistricts(id) {
      let response = await new CityDistrictService().getDistricts(id);
      this.districts = response.result.data;
    },
    async getFileInfo(id) {
      this.accountId = id;
      let response = await new AccountsService().getAccountAll(id, "all");
      if (response.result.informations.length > 0) {
        for (let i = 0; i < response.result.informations.length; i++) {
          this.accountInfo[response.result.informations[i].key] =
            response.result.informations[i].value;
        }
      }

      this.accountInfo.additional_services =
        this.accountInfo.additional_services.id !== 0
          ? JSON.parse(this.accountInfo.additional_services)
          : "";
      this.accountInfo.files = this.accountInfo.files
        ? JSON.parse(this.accountInfo.files)
        : [];
      this.uploadFilesTc.files = this.accountInfo.files[0].files;
      this.uploadFilesOther.files = this.accountInfo.files[1].files;
      this.uploadFilesRental.files = this.accountInfo.files[2].files;
      this.uploadFilesCar.files = this.accountInfo.files[3].files;
      this.uploadFilesCarInvoice.files = this.accountInfo.files[4].files;
      this.uploadFilesSecondCar.files = this.accountInfo.files[5].files;
    },
    async getFiles() {
      let response = await new DocumentsService().getFiles();
      this.files = response.result.data;
    },
  },
  async created() {
    await this.getAccount();
    await this.getCities();
    await this.getFileInfo(this.$route.params.id);
    await this.getFiles();
  },
};
</script>

<style scoped>
.underline {
  height: 2px;
  width: 5%;
  background: #4f46e5;
  margin-top: 6px;
}
</style>
