<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <main>
    <div
      class="additional-container max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100"
    >
      <!-- Replace with your content -->
      <div class="bg-white">
        <div
          class="mt-8 text-xl font-bold text-gray-800 packet-name"
          data-v-12b9f82b=""
        >
          {{ $t("message.additionalServices") }}
          <div class="underline" data-v-12b9f82b=""></div>
        </div>
        <div class="max-w-7xl mx-auto">
          <div
            class="mt-2 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-2"
          >
            <div
              class="extra-card rounded-lg shadow-sm divide-y divide-gray-200"
            >
              <div class="p-6 additional-card">
                <h2 class="text-lg leading-6 font-bold text-gray-900">
                  {{ $t("message.stageENB") }}
                </h2>
                <p class="mt-4 font-light text-sm">
                  {{ $t("message.stageAdditionalDesc") }}
                </p>
                <p class="mt-9">
                  <span class="text-4xl font-extrabold text-gray-900"
                    >500 ₺</span
                  >
                </p>
                <router-link
                  :to="`/${$store.state.lang}/app/endless-network/my-company-stage`"
                  class="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >Satın Al</router-link
                >
              </div>
            </div>
            <div
              class="extra-card rounded-lg shadow-sm divide-y divide-gray-200"
            >
              <div class="p-6 additional-card">
                <h2 class="text-lg leading-6 font-bold text-gray-900">
                  {{ $t("message.companyENB") }}
                </h2>
                <p class="mt-4 font-light text-sm">
                  {{ $t("message.companyAdditionalDesc") }}
                </p>
                <p class="mt-4">
                  <span class="text-4xl font-extrabold text-gray-900"
                    >200 ₺</span
                  >
                </p>
                <router-link
                  :to="`/${$store.state.lang}/app/endless-network/for-my-company`"
                  class="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >Abone Ol</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /End replace -->
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      packets: [],
      show: false,
    };
  },
};
</script>
<style lang="scss">
.bg-gray {
  background: #f9f9f9;
  border: 0px;
  box-shadow: 0 0 0 transparent;
}
.additional-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: auto;
}
.text-gray {
  color: #1f2a37;
}
.extra-card {
  box-shadow: 0px 18px 20px -10px #ddd;
  border: 1px solid #f3f3f3;
}
.price-button {
  margin-top: 6.5rem;
}
.packet-name {
  font-size: 25px;
  padding-bottom: 15px;
  width: max-content;
  .underline {
    height: 2px;
    width: 70%;
    background: #4f46e5;
    margin-top: 6px;
  }
}
@media only screen and (max-width: 600px) {
  .additional-container {
    padding: 20px;
  }
}
</style>
