<template>
  <main v-if="checkPermission('update accounts')">
    <div v-if="company_name" class="mb-2">
      <span class="font-medium mb-4">{{ $t("message.companyName") }} : </span>
      <span class="font-medium text-indigo-600">{{ company_name }}</span>
    </div>
    <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mt-4">
      <div class="sm:col-span-3">
        <label class="file-label block text-sm font-medium text-gray-700">
          {{ $t("message.identityCardPhoto") }}
        </label>
        <div>
          <div class="sm:col-span-6">
            <div class="flex w-full items-center text-center" id="appTc">
              <div
                class="file-drop-content p-12 w-full bg-indigo-50 border-dashed border-2 border-indigo-300"
                @dragover="dragoverTc"
                @dragleave="dragleaveTc"
                @drop="dropTc"
              >
                <input
                  type="file"
                  multiple
                  name="fields[assetsFieldHandleIdentity][]"
                  id="assetsFieldHandleIdentity"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChangeTc()"
                  ref="fileTc"
                  accept="/*"
                />

                <label
                  for="assetsFieldHandleIdentity"
                  class="block cursor-pointer"
                >
                  <div>
                    {{ $t("message.uploadFile") }}
                    <span class="underline">{{ $t("message.clickHere") }}</span>
                  </div>
                </label>
                <ul class="mt-4" v-if="uploadFilesTc.files.length > 0" v-cloak>
                  <li
                    class="text-sm p-1 remove-btn-content"
                    v-for="(file, index) in uploadFilesTc.files"
                    :key="index"
                  >
                    <div class="flex justify-between">
                      <div class="flex justify-center space-x-6">
                        <span>
                          {{ file.name }}
                        </span>
                      </div>
                      <button
                        class="remove-btn"
                        type="button"
                        @click="removeTc(uploadFilesTc.files.indexOf(file))"
                        title="Remove file"
                      >
                        <img
                          src="../../../../public/img/delete-bin-fill.svg"
                          alt="delete"
                        />
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sm:col-span-3">
        <label class="file-label block text-sm font-medium text-gray-700">
          {{ $t("message.otherFiles") }}
        </label>
        <div>
          <div class="sm:col-span-6">
            <div class="flex w-full items-center text-center" id="app">
              <div
                class="file-drop-content p-12 w-full bg-indigo-50 border-dashed border-2 border-indigo-300"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
              >
                <input
                  type="file"
                  multiple
                  name="fields[assetsFieldHandleOther][]"
                  id="assetsFieldHandleOther"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChangeOther"
                  ref="fileOther"
                  accept="/*"
                />

                <label
                  for="assetsFieldHandleOther"
                  class="block cursor-pointer"
                >
                  <div>
                    {{ $t("message.uploadFile") }}
                    <span class="underline">{{ $t("message.clickHere") }}</span>
                  </div>
                </label>
                <ul
                  class="mt-4"
                  v-if="uploadFilesOther.files.length > 0"
                  v-cloak
                >
                  <li
                    class="text-sm p-1 remove-btn-content"
                    v-for="(file, index) in uploadFilesOther.files"
                    :key="index"
                  >
                    <div class="flex justify-between">
                      <div class="flex justify-center space-x-6">
                        <span class="mt-3">
                          {{ file.name }}
                        </span>
                      </div>
                      <button
                        class="remove-btn"
                        type="button"
                        @click="
                          removeOther(uploadFilesOther.files.indexOf(file))
                        "
                        title="Remove file"
                      >
                        <img
                          src="../../../../public/img/delete-bin-fill.svg"
                          alt="delete"
                        />
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SwitchGroup
      as="div"
      class="flex items-center pt-8"
      @click="resetSelectBox"
    >
      <Switch
        v-model="accountInfo.have_car"
        @click="setCarOptions"
        :class="[
          accountInfo.have_car ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        ]"
      >
        <span
          aria-hidden="true"
          :class="[
            accountInfo.have_car ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
          ]"
        />
      </Switch>
      <SwitchLabel as="span" class="ml-3">
        <span class="text-sm font-medium text-gray-900">
          {{ $t("message.haveCar") }}</span
        >
      </SwitchLabel>
    </SwitchGroup>
    <div></div>
    <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div v-show="accountInfo.have_car == 1" class="space-y-4 col-span-3">
        <RadioGroup v-model="selected">
          <div class="bg-white rounded-md -space-y-px pt-8">
            <RadioGroupOption
              as="template"
              v-for="(option, settingIdx) in options"
              :key="option.name"
              :value="option"
              v-slot="{ checked, active }"
            >
              <div
                :class="[
                  settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  settingIdx === options.length - 1
                    ? 'rounded-bl-md rounded-br-md'
                    : '',
                  checked
                    ? 'bg-indigo-50 border-indigo-200 z-10'
                    : 'border-gray-200',
                  'relative border p-4 flex cursor-pointer focus:outline-none',
                ]"
              >
                <span
                  :class="[
                    checked
                      ? 'bg-indigo-600 border-transparent'
                      : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                  ]"
                  aria-hidden="true"
                >
                  <span class="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div class="ml-3 flex flex-col">
                  <RadioGroupLabel
                    as="span"
                    :class="[
                      checked ? 'text-indigo-900' : 'text-gray-900',
                      'block text-sm font-medium',
                    ]"
                  >
                    {{ option.name }}
                  </RadioGroupLabel>
                </div>
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </div>
      <div class="col-span-3 sm:grid-cols-6 mb-5">
        <div v-if="selected.id !== 0" class="sm:col-span-3">
          <div class="mb-5">
            <label class="block file-label text-sm font-medium text-gray-700">
              {{ $t("message.carLicense") }}
            </label>
            <div>
              <div class="sm:col-span-6">
                <div class="flex w-full items-center text-center">
                  <div
                    class="file-drop-content p-12 w-full bg-indigo-50 border-dashed border-2 border-indigo-300"
                  >
                    <input
                      type="file"
                      multiple
                      name="fields[assetsFieldHandleCar][]"
                      id="assetsFieldHandleCar"
                      class="w-px h-px opacity-0 overflow-hidden absolute"
                      @change="onChangeCar"
                      ref="fileCar"
                      accept=".pdf,.jpg,.jpeg,.png"
                    />

                    <label
                      for="assetsFieldHandleCar"
                      class="block cursor-pointer"
                    >
                      <div>
                        {{ $t("message.uploadFile") }}
                        <span class="underline">{{
                          $t("message.clickHere")
                        }}</span>
                      </div>
                    </label>
                    <ul
                      class="mt-4"
                      v-if="uploadFilesCar.files.length > 0"
                      v-cloak
                    >
                      <li
                        class="text-sm p-1 remove-btn-content"
                        v-for="(file, index) in uploadFilesCar.files"
                        :key="index"
                      >
                        <div class="flex justify-between">
                          <div class="flex justify-center space-x-6">
                            <span class="mt-3">
                              {{ file.name }}
                            </span>
                          </div>
                          <button
                            class="remove-btn"
                            type="button"
                            @click="
                              removeCar(uploadFilesCar.files.indexOf(file))
                            "
                            title="Remove file"
                          >
                            <img
                              src="../../../../public/img/delete-bin-fill.svg"
                              alt="delete"
                            />
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selected.id === 2">
            <label class="block file-label text-sm font-medium text-gray-700">
              {{ $t("message.carInvoice") }}
            </label>
            <div>
              <div class="sm:col-span-6">
                <div class="flex w-full items-center text-center">
                  <div
                    class="file-drop-content p-12 w-full bg-indigo-50 border-dashed border-2 border-indigo-300"
                  >
                    <input
                      type="file"
                      multiple
                      name="fields[assetsFieldHandleCarInvoice][]"
                      id="assetsFieldHandleCarInvoice"
                      class="w-px h-px opacity-0 overflow-hidden absolute"
                      @change="onChangeCarInvoice"
                      ref="fileCarInvoice"
                      accept=".pdf,.jpg,.jpeg,.png"
                    />

                    <label
                      for="assetsFieldHandleCarInvoice"
                      class="block cursor-pointer"
                    >
                      <div>
                        {{ $t("message.uploadFile") }}
                        <span class="underline">{{
                          $t("message.clickHere")
                        }}</span>
                      </div>
                    </label>
                    <ul
                      class="mt-4"
                      v-if="uploadFilesCarInvoice.files.length > 0"
                      v-cloak
                    >
                      <li
                        class="text-sm p-1 remove-btn-content"
                        v-for="(file, index) in uploadFilesCarInvoice.files"
                        :key="index"
                      >
                        <div class="flex justify-between">
                          <div class="flex justify-center space-x-6">
                            <span class="mt-3">
                              {{ file.name }}
                            </span>
                          </div>
                          <button
                            class="remove-btn"
                            type="button"
                            @click="
                              removeCarInvoice(
                                uploadFilesCarInvoice.files.indexOf(file)
                              )
                            "
                            title="Remove file"
                          >
                            <img
                              src="../../../../public/img/delete-bin-fill.svg"
                              alt="delete"
                            />
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selected.id === 3">
            <label class="block file-label text-sm font-medium text-gray-700">
              {{ $t("message.salesContract") }}
            </label>
            <div>
              <div class="sm:col-span-6">
                <div class="flex w-full items-center text-center">
                  <div
                    class="file-drop-content p-12 w-full bg-indigo-50 border-dashed border-2 border-indigo-300"
                  >
                    <input
                      type="file"
                      multiple
                      name="fields[assetsFieldHandleSecondCar][]"
                      id="assetsFieldHandleSecondCar"
                      class="w-px h-px opacity-0 overflow-hidden absolute"
                      @change="onChangeSecond"
                      ref="fileSecondCar"
                      accept=".pdf,.jpg,.jpeg,.png"
                    />

                    <label
                      for="assetsFieldHandleSecondCar"
                      class="block cursor-pointer"
                    >
                      <div>
                        {{ $t("message.uploadFile") }}
                        <span class="underline">{{
                          $t("message.clickHere")
                        }}</span>
                      </div>
                    </label>
                    <ul
                      class="mt-4"
                      v-if="uploadFilesSecondCar.files.length > 0"
                      v-cloak
                    >
                      <li
                        class="text-sm p-1 remove-btn-content"
                        v-for="(file, index) in uploadFilesSecondCar.files"
                        :key="index"
                      >
                        <div class="flex justify-between">
                          <div class="flex justify-center space-x-6">
                            <span class="mt-3">
                              {{ file.name }}
                            </span>
                          </div>
                          <button
                            class="remove-btn"
                            type="button"
                            @click="
                              removeSecond(
                                uploadFilesSecondCar.files.indexOf(file)
                              )
                            "
                            title="Remove file"
                          >
                            <img
                              src="../../../../public/img/delete-bin-fill.svg"
                              alt="delete"
                            />
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selected.id === 1">
            <label class="block text-sm font-medium text-gray-700">
              {{ $t("message.rentalContract") }}
            </label>
            <div>
              <div class="sm:col-span-6">
                <div class="flex w-full items-center text-center">
                  <div
                    class="file-drop-content p-12 w-full bg-indigo-50 border-dashed border-2 border-indigo-300"
                  >
                    <input
                      type="file"
                      multiple
                      name="fields[assetsFieldHandleRental][]"
                      id="assetsFieldHandleRental"
                      class="w-px h-px opacity-0 overflow-hidden absolute"
                      @change="onChangeRental"
                      ref="fileRental"
                      accept=".pdf,.jpg,.jpeg,.png"
                    />

                    <label
                      for="assetsFieldHandleRental"
                      class="block cursor-pointer"
                    >
                      <div>
                        {{ $t("message.uploadFile") }}
                        <span class="underline">{{
                          $t("message.clickHere")
                        }}</span>
                      </div>
                    </label>
                    <ul
                      class="mt-4"
                      v-if="uploadFilesRental.files.length > 0"
                      v-cloak
                    >
                      <li
                        class="text-sm p-1 remove-btn-content"
                        v-for="(file, index) in uploadFilesRental.files"
                        :key="index"
                      >
                        <div class="flex justify-between">
                          <div class="flex justify-center space-x-6">
                            <span class="mt-3">
                              {{ file.name }}
                            </span>
                          </div>
                          <button
                            class="remove-btn"
                            type="button"
                            @click="
                              removeRental(
                                uploadFilesRental.files.indexOf(file)
                              )
                            "
                            title="Remove file"
                          >
                            <img
                              src="../../../../public/img/delete-bin-fill.svg"
                              alt="delete"
                            />
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5">
      <div class="flex justify-end">
        <!--        <router-link-->
        <!--          :to="`${$store.state.lang}/app/application-form/business-info`"-->
        <!--          type="submit"-->
        <!--          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"-->
        <!--        >-->
        <!--          {{ $t("message.prevPage") }}-->
        <!--        </router-link>-->
        <button
          @click="prevPage()"
          type="submit"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {{ $t("message.prevPage") }}
        </button>
        <button
          @click="addFile"
          type="submit"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {{ $t("message.nextPage") }}
        </button>
      </div>
    </div>
  </main>
  <div v-else class="lg:col-span-9">
    <!-- Profile section -->
    <div class="flex justify-center items-center py-12">
      <div
        class="flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="text-white uppercase text-lg text-center">
          <div>BU SAYFAYI GÖRÜNTÜLEMEK İÇİN YETKİNİZ BULUNMAMAKTADIR.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  Switch,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import FilesService from "../../../services/files.service";
import AccountsService from "../../../services/accounts.service";
import DocumentsService from "../../../services/documents.service";
import { Mixin } from "../../../library/utils";

export default {
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  },
  mixins: [Mixin],
  data() {
    return {
      haveCar: false,
      uploadFilesTc: { type: "tc", files: [] },
      uploadFilesOther: { type: "other", files: [] },
      uploadFilesCar: { type: "car", files: [] },
      uploadFilesRental: { type: "rental", files: [] },
      uploadFilesCarInvoice: { type: "invoice", files: [] },
      uploadFilesSecondCar: { type: "second", files: [] },
      file: { files: [], have_car: false, car_info: false },
      image: "",
      selected: { id: 0, name: "" },
      uploadFiles: [],
      accountId: 0,
      accountInfo: {
        files: [
          { type: "tc", files: [] },
          { type: "other", files: [] },
          { type: "rental", files: [] },
          { type: "car", files: [] },
          { type: "invoice", files: [] },
          { type: "second", files: [] },
        ],
        car_info: { name: "", id: 0 },
      },
      uploadFilesURL: [],
      options: [
        { id: 1, name: this.$t("message.rental") },
        { id: 2, name: this.$t("message.firstHand") },
        { id: 3, name: this.$t("message.secondHand") },
      ],
      filesLength: 0,
      files: [],
      differenceTc: [],
      differenceCar: [],
      differenceCarInvoice: [],
      differenceRental: [],
      differenceOther: [],
      differenceSecond: [],
      deletedFiles: [],
      addTc: [],
      addCar: [],
      addCarInvoice: [],
      addRental: [],
      addOther: [],
      addSecond: [],
      step_name: "",
      company_name: null,
    };
  },
  methods: {
    resetSelectBox() {
      if (!this.accountInfo.have_car) {
        this.selected = { id: 0, name: "" };
      }
    },
    setStatus() {
      this.$store.state.steps[4].status = "complete";
      // this.$store.state.steps[5].status = "current";
    },
    setCarOptions() {
      if (this.accountInfo.have_car) {
        this.selected = this.options[0];
      }
    },
    async onChangeTc() {
      this.uploadFilesTc.files.push(...this.$refs.fileTc.files);
      for (let i = 0; i < this.uploadFilesTc.files.length; i++) {
        if (this.uploadFilesTc.files[i].size > 20000000) {
          await this.removeTc(i);
          this.$store.dispatch("showNotification", {
            title: "Hata",
            description: "Lütfen daha küçük boyutlu bir dosya yükleyiniz",
            type: "error",
          });
        } else {
          await this.fileUrlTc(i);
        }
      }
    },
    async onChangeOther() {
      this.uploadFilesOther.files.push(...this.$refs.fileOther.files);
      for (let i = 0; i < this.uploadFilesOther.files.length; i++) {
        if (this.uploadFilesOther.files[i].size > 20000000) {
          await this.removeOther(i);
          this.$store.dispatch("showNotification", {
            title: "Hata",
            description: "Lütfen daha küçük boyutlu bir dosya yükleyiniz",
            type: "error",
          });
        } else {
          await this.fileUrlOther(i);
        }
      }
    },
    async onChangeCar() {
      this.uploadFilesCar.files = [...this.$refs.fileCar.files];
      for (let i = 0; i < this.uploadFilesCar.files.length; i++) {
        if (this.uploadFilesCar.files[i].size > 20000000) {
          await this.removeCar(i);
          this.$store.dispatch("showNotification", {
            title: "Hata",
            description: "Lütfen daha küçük boyutlu bir dosya yükleyiniz",
            type: "error",
          });
        } else {
          await this.fileUrlCar(i);
        }
      }
    },
    async onChangeCarInvoice() {
      this.uploadFilesCarInvoice.files = [...this.$refs.fileCarInvoice.files];
      for (let i = 0; i < this.uploadFilesCarInvoice.files.length; i++) {
        if (this.uploadFilesCarInvoice.files[i].size > 20000000) {
          await this.removeCarInvoice(i);
          this.$store.dispatch("showNotification", {
            title: "Hata",
            description: "Lütfen daha küçük boyutlu bir dosya yükleyiniz",
            type: "error",
          });
        } else {
          await this.fileUrlCarInvoice(i);
        }
      }
    },
    async onChangeRental() {
      this.uploadFilesRental.files = [...this.$refs.fileRental.files];
      for (let i = 0; i < this.uploadFilesRental.files.length; i++) {
        if (this.uploadFilesRental.files[i].size > 20000000) {
          await this.removeRental(i);
          this.$store.dispatch("showNotification", {
            title: "Hata",
            description: "Lütfen daha küçük boyutlu bir dosya yükleyiniz",
            type: "error",
          });
        } else {
          await this.fileUrlRental(i);
        }
      }
    },
    async onChangeSecond() {
      this.uploadFilesSecondCar.files = [...this.$refs.fileSecondCar.files];
      for (let i = 0; i < this.uploadFilesSecondCar.files.length; i++) {
        if (this.uploadFilesSecondCar.files[i].size > 20000000) {
          await this.removeSecond(i);
          this.$store.dispatch("showNotification", {
            title: "Hata",
            description: "Lütfen daha küçük boyutlu bir dosya yükleyiniz",
            type: "error",
          });
        } else {
          await this.fileUrlSecond(i);
        }
      }
    },
    async removeTc(i) {
      // let deletedFile = this.uploadFilesTc.files[i] !== "" ? this.files.find(f => f.path === this.uploadFilesTc.files[i].path) : "
      let deletedFile = this.files.find(
        (f) => f.path === this.uploadFilesOther.files[i].path
      );
      await this.deleteFilesFromDocuments(deletedFile);
      this.uploadFilesTc.files.splice(i, 1);
    },
    async removeOther(i) {
      let deletedFile = this.files.find(
        (f) => f.path === this.uploadFilesOther.files[i].path
      );
      await this.deleteFilesFromDocuments(deletedFile);
      this.uploadFilesOther.files.splice(i, 1);
    },
    async removeCar(i) {
      let deletedFile =
        this.uploadFilesCar.files[i] !== ""
          ? this.files.find((f) => f.path === this.uploadFilesCar.files[i].path)
          : "";
      await this.deleteFilesFromDocuments(deletedFile);
      this.uploadFilesCar.files.splice(i, 1);
    },
    async removeCarInvoice(i) {
      let deletedFile =
        this.uploadFilesCarInvoice.files[i] !== ""
          ? this.files.find(
              (f) => f.path === this.uploadFilesCarInvoice.files[i].path
            )
          : "";
      await this.deleteFilesFromDocuments(deletedFile);
      this.uploadFilesCarInvoice.files.splice(i, 1);
    },
    async removeRental(i) {
      let deletedFile =
        this.uploadFilesRental.files[i] !== ""
          ? this.files.find(
              (f) => f.path === this.uploadFilesRental.files[i].path
            )
          : "";
      await this.deleteFilesFromDocuments(deletedFile);
      this.uploadFilesRental.files.splice(i, 1);
    },
    async removeSecond(i) {
      let deletedFile =
        this.uploadFilesSecondCar.files[i] !== ""
          ? this.files.find(
              (f) => f.path === this.uploadFilesSecondCar.files[i].path
            )
          : "";
      await this.deleteFilesFromDocuments(deletedFile);
      this.uploadFilesSecondCar.files.splice(i, 1);
    },
    async fileUrlTc(i) {
      const formData = new FormData();
      formData.append("type", "images");
      formData.append("pid", JSON.parse(localStorage.getItem("user")).id);
      formData.append("files", this.uploadFilesTc.files[i]);
      formData.append("name", this.uploadFilesTc.files[i].name);
      let response = await new FilesService().getFileUrl(formData);
      this.uploadFilesTc.files[i] = {
        path: response.result[0].file_path,
        name: this.uploadFilesTc.files[i].name,
      };
    },
    async fileUrlOther(i) {
      const formData = new FormData();
      formData.append("type", "images");
      formData.append("pid", JSON.parse(localStorage.getItem("user")).id);
      formData.append("files", this.uploadFilesOther.files[i]);
      formData.append("name", this.uploadFilesOther.files[i].name);
      let response = await new FilesService().getFileUrl(formData);
      this.uploadFilesOther.files[i] = {
        path: response.result[0].file_path,
        name: this.uploadFilesOther.files[i].name,
      };
    },
    async fileUrlCar(i) {
      const formData = new FormData();
      formData.append("type", "images");
      formData.append("pid", JSON.parse(localStorage.getItem("user")).id);
      formData.append("files", this.uploadFilesCar.files[i]);
      formData.append("name", this.uploadFilesCar.files[i].name);
      let response = await new FilesService().getFileUrl(formData);
      this.uploadFilesCar.files[i] = {
        path: response.result[0].file_path,
        name: this.uploadFilesCar.files[i].name,
      };
    },
    async fileUrlCarInvoice(i) {
      const formData = new FormData();
      formData.append("type", "images");
      formData.append("pid", JSON.parse(localStorage.getItem("user")).id);
      formData.append("files", this.uploadFilesCarInvoice.files[i]);
      formData.append("name", this.uploadFilesCarInvoice.files[i].name);
      let response = await new FilesService().getFileUrl(formData);
      this.uploadFilesCarInvoice.files[i] = {
        path: response.result[0].file_path,
        name: this.uploadFilesCarInvoice.files[i].name,
      };
    },
    async fileUrlSecond(i) {
      const formData = new FormData();
      formData.append("type", "images");
      formData.append("pid", JSON.parse(localStorage.getItem("user")).id);
      formData.append("files", this.uploadFilesSecondCar.files[i]);
      formData.append("name", this.uploadFilesSecondCar.files[i].name);
      let response = await new FilesService().getFileUrl(formData);
      this.uploadFilesSecondCar.files[i] = {
        path: response.result[0].file_path,
        name: this.uploadFilesSecondCar.files[i].name,
      };
    },
    async fileUrlRental(i) {
      const formData = new FormData();
      formData.append("type", "images");
      formData.append("pid", JSON.parse(localStorage.getItem("user")).id);
      formData.append("files", this.uploadFilesRental.files[i]);
      formData.append("name", this.uploadFilesRental.files[i].name);
      let response = await new FilesService().getFileUrl(formData);
      this.uploadFilesRental.files[i] = {
        path: response.result[0].file_path,
        name: this.uploadFilesRental.files[i].name,
      };
    },
    async deleteCarFromDocuments() {
      for (let i = 0; i < this.differenceCar.length; i++) {
        if (this.differenceCar[i] !== "") {
          let deletedFile = this.files.find(
            (f) => f.path === this.differenceCar[i].path
          );
          await new DocumentsService().deleteFile("", deletedFile.id);
        }
      }
    },
    async deleteCarInvoiceFromDocuments() {
      for (let i = 0; i < this.differenceCarInvoice.length; i++) {
        if (this.differenceCarInvoice[i] !== "") {
          let deletedFile = this.files.find(
            (f) => f.path === this.differenceCarInvoice[i].path
          );
          await new DocumentsService().deleteFile("", deletedFile.id);
        }
      }
    },
    async deleteRentalFromDocuments() {
      for (let i = 0; i < this.differenceRental.length; i++) {
        if (this.differenceRental[i] !== "") {
          let deletedFile = this.files.find(
            (f) => f.path === this.differenceRental[i].path
          );
          await new DocumentsService().deleteFile("", deletedFile.id);
        }
      }
    },
    async deleteOtherFromDocuments() {
      for (let i = 0; i < this.differenceOther.length; i++) {
        if (this.differenceOther[i] !== "") {
          let deletedFile = this.files.find(
            (f) => f.path === this.differenceOther[i].path
          );
          await new DocumentsService().deleteFile("", deletedFile.id);
        }
      }
    },
    async deleteSecondFromDocuments() {
      for (let i = 0; i < this.differenceSecondCar.length; i++) {
        if (this.differenceSecondCar[i] !== "") {
          let deletedFile = this.files.find(
            (f) => f.path === this.differenceSecondCar[i].path
          );
          await new DocumentsService().deleteFile("", deletedFile.id);
        }
      }
    },
    async deleteTcFromDocuments() {
      for (let i = 0; i < this.differenceTc.length; i++) {
        let deletedFile = this.files.find(
          (f) => f.path === this.differenceTc[i].path
        );
        await new DocumentsService().deleteFile("", deletedFile.id);
      }
    },
    async deleteFilesFromDocuments(file) {
      if (file) {
        await new DocumentsService().deleteFile("", file.id);
      }
    },
    async addFileToDocumentsTc() {
      for (let i = 0; i < this.addTc.length; i++) {
        let file = {
          name: this.addTc[i].name,
          path: this.addTc[i].path,
          description: "tc kimlik arkalı önlü fotoğraf",
          type_id: 24,
        };
        await new DocumentsService().addFile(file);
      }
    },
    async addFileToDocumentsCar() {
      for (let i = 0; i < this.addCar.length; i++) {
        let file = {
          name: this.addCar[i].name,
          path: this.addCar[i].path,
          description: "araç ruhsatı",
          type_id: 24,
        };
        await new DocumentsService().addFile(file);
      }
    },
    async addFileToDocumentsCarInvoice() {
      for (let i = 0; i < this.addCarInvoice.length; i++) {
        let file = {
          name: this.addCarInvoice[i].name,
          path: this.addCarInvoice[i].path,
          description: "araç faturası",
          type_id: 24,
        };
        await new DocumentsService().addFile(file);
      }
    },
    async addFileToDocumentsSecond() {
      for (let i = 0; i < this.addSecondCar.length; i++) {
        let file = {
          name: this.addSecondCar[i].name,
          path: this.addSecondCar[i].path,
          description: "noter satış sözleşmesi",
          type_id: 24,
        };
        await new DocumentsService().addFile(file);
      }
    },
    async addFileToDocumentsRental() {
      for (let i = 0; i < this.addRental.length; i++) {
        let file = {
          name: this.addRental[i].name,
          path: this.addRental[i].path,
          description: "kira sözleşmesi",
          type_id: 24,
        };
        await new DocumentsService().addFile(file);
      }
    },
    async addFileToDocumentsOther() {
      for (let i = 0; i < this.addOther.length; i++) {
        let file = {
          name: this.addOther[i].name,
          path: this.addOther[i].path,
          description: "other",
          type_id: 24,
        };
        await new DocumentsService().addFile(file);
      }
    },
    async addFile() {
      this.file.have_car = this.accountInfo.have_car === true ? 1 : 0;
      this.file.car_info = this.selected ? this.selected : { id: 0, name: "" };
      this.file.files.push(this.uploadFilesTc);
      this.file.files.push(this.uploadFilesOther);
      this.file.files.push(this.uploadFilesRental);
      this.file.files.push(this.uploadFilesCar);
      this.file.files.push(this.uploadFilesCarInvoice);
      this.file.files.push(this.uploadFilesSecondCar);
      this.differenceTc =
        this.accountInfo.files[0].length > 0
          ? this.accountInfo.files[0].files.filter(
              (x) => !this.uploadFilesTc.files.includes(x)
            )
          : [];
      this.differenceOther =
        this.uploadFilesOther.length > 0
          ? this.accountInfo.files[4].files.filter(
              (x) => !this.uploadFilesOther.files.includes(x)
            )
          : [];
      this.differenceRental =
        this.uploadFilesRental.length > 0
          ? this.accountInfo.files[3].files.filter(
              (x) => !this.uploadFilesRental.files.includes(x)
            )
          : [];
      this.differenceCar =
        this.uploadFilesCar.length > 0
          ? this.accountInfo.files[1].files.filter(
              (x) => !this.uploadFilesCar.files.includes(x)
            )
          : [];
      this.differenceCarInvoice =
        this.uploadFilesCarInvoice.length > 0
          ? this.accountInfo.files[2].files.filter(
              (x) => !this.uploadFilesCarInvoice.files.includes(x)
            )
          : [];
      this.differenceSecondCar =
        this.uploadFilesSecondCar.length > 0
          ? this.accountInfo.files[5].files.filter(
              (x) => !this.uploadFilesSecondCar.files.includes(x)
            )
          : [];
      this.addTc = this.uploadFilesTc.files.filter(
        (x) => !this.accountInfo.files[0].files.includes(x)
      );
      this.addOther = this.uploadFilesOther.files.filter(
        (x) => !this.accountInfo.files[1].files.includes(x)
      );
      this.addRental = this.uploadFilesRental.files.filter(
        (x) => !this.accountInfo.files[2].files.includes(x)
      );
      this.addCar = this.uploadFilesCar.files.filter(
        (x) => !this.accountInfo.files[3].files.includes(x)
      );
      this.addCarInvoice = this.uploadFilesCarInvoice.files.filter(
        (x) => !this.accountInfo.files[4].files.includes(x)
      );
      this.addSecondCar = this.uploadFilesSecondCar.files.filter(
        (x) => !this.accountInfo.files[5].files.includes(x)
      );
      if (this.checkFilesData()) {
        let response = await new FilesService().addFile(
          this.file,
          this.accountId,
          "",
          this.step_name
        );
        if (response.status === 201) {
          await this.deleteTcFromDocuments();
          await this.deleteCarFromDocuments();
          await this.deleteCarInvoiceFromDocuments();
          await this.deleteOtherFromDocuments();
          await this.deleteRentalFromDocuments();
          await this.deleteSecondFromDocuments();
          await this.addFileToDocumentsCar();
          await this.addFileToDocumentsCarInvoice();
          await this.addFileToDocumentsTc();
          await this.addFileToDocumentsSecond();
          await this.addFileToDocumentsRental();
          await this.addFileToDocumentsOther();
          await this.setStatus();
          await this.$router.push(
            `/${this.$store.state.lang}/app/application-form/payment`
          );
          this.uploadFiles = [];
        }
      }
    },
    async prevPage() {
      await this.$router.push(
        `/${this.$store.state.lang}/app/application-form/business-info`
      );
    },
    checkFilesData() {
      if (this.file.have_car !== 0) {
        if (
          this.file.car_info.id === 1 &&
          this.uploadFilesRental.files.length !== 0 &&
          this.uploadFilesCar.files.length !== 0
        ) {
          return true;
        } else if (
          this.file.car_info.id === 2 &&
          this.uploadFilesCarInvoice.files.length !== 0 &&
          this.uploadFilesCar.files.length !== 0
        ) {
          this.file.files.push(this.uploadFilesCar);
          this.differenceCar = this.accountInfo.files[1].files.filter(
            (x) => !this.uploadFilesCar.files.includes(x)
          );
          return true;
        } else if (
          this.file.car_info.id === 3 &&
          this.uploadFilesSecondCar.files.length !== 0 &&
          this.uploadFilesCar.files.length !== 0
        ) {
          return true;
        } else {
          this.$store.dispatch("showNotification", {
            title: "Uyarı",
            description: "Lütfen tüm alanları doldurunuz",
            type: "warning",
          });
          return false;
        }
      } else {
        return true;
      }
    },
    async getFileInfo(id) {
      this.accountId = id;
      let response = await new AccountsService().getAccount(id);
      if (response.result.informations.length > 0) {
        for (let i = 0; i < response.result.informations.length; i++) {
          this.accountInfo[response.result.informations[i].key] =
            response.result.informations[i].value;
        }
        let companyName = JSON.parse(localStorage.getItem("company_name"));
        let sonuc = response.result.users[0].accounts.find((element) => {
          element.name === companyName;
          return true;
        });
        if (sonuc) this.company_name = companyName;
      }
      let index = this.$store.state.steps.findIndex(
        (step) => step.slug === this.accountInfo.step
      );
      let route_index = this.$store.state.steps.findIndex(
        (step) => step.to === this.$route.path
      );
      if (index > route_index) {
        this.step_name = this.accountInfo.step;
      } else {
        this.step_name = "files";
      }
      this.accountInfo.have_car = !(
        this.accountInfo.have_car === undefined ||
        this.accountInfo.have_car === "0"
      );
      this.selected =
        this.accountInfo.car_info.id !== 0
          ? JSON.parse(this.accountInfo.car_info)
          : { id: 0, name: "" };
      this.selected =
        this.selected.id !== 0
          ? this.options.find((element) => element.id === this.selected.id)
          : {
              id: 0,
              name: "",
            };
      this.accountInfo.files = this.accountInfo.files
        ? JSON.parse(this.accountInfo.files)
        : [];
      this.uploadFilesTc.files = this.accountInfo.files[0].files;
      this.uploadFilesOther.files = this.accountInfo.files[1].files;
      this.uploadFilesRental.files = this.accountInfo.files[2].files;
      this.uploadFilesCar.files = this.accountInfo.files[3].files;
      this.uploadFilesCarInvoice.files = this.accountInfo.files[4].files;
      this.uploadFilesSecondCar.files = this.accountInfo.files[5].files;
    },
    async getFiles() {
      let response = await new DocumentsService().getFiles();
      this.files = response.result.data;
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-gray-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-gray-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-gray-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileOther.files = event.dataTransfer.files;
      this.onChangeOther(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-gray-300");
    },
    dragoverTc(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-gray-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-gray-300");
      }
    },
    dragleaveTc(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-gray-300");
    },
    dropTc(event) {
      event.preventDefault();
      this.$refs.fileTc.files = event.dataTransfer.files;
      this.onChangeTc(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-gray-300");
    },
  },
  async created() {
    if (this.checkPermission("update accounts")) {
      await this.getFileInfo(JSON.parse(localStorage.getItem("account")).id);
      await this.getFiles();
    }
  },
};
</script>
<style lang="scss" scoped>
.file-label {
  margin-bottom: 7px;
}

.file-drop-content {
  border-radius: 10px;
  height: 163px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.underline {
  color: #4f46e5;

  &:hover {
    color: #4940d4;
  }
}

.remove-btn {
  img {
    width: 19px;
    margin-left: 7px;
  }
}

.remove-btn-content {
  display: flex;
  align-items: center;
}

.files-show-content {
  background: #f7f7f7;
  border: 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-bottom: 40px;

  h1 {
    font-weight: 600;
    font-size: 19px;
    margin-bottom: 20px;
  }

  .files-cont {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 13px;
    border-bottom: 1px solid #ededed;

    .delete {
      &:hover {
        cursor: pointer;
      }
    }

    &:last-child {
      border: 0px !important;
    }

    .left-cont {
      display: flex;
      gap: 15px;
      align-items: center;
    }

    img {
    }
  }
}
</style>
