<template>
  <main v-if="checkPermission('update accounts')">
    <div v-if="company_name" class="mb-2">
      <span class="font-medium mb-4">{{ $t("message.companyName") }} : </span>
      <span class="font-medium text-indigo-600">{{ company_name }}</span>
    </div>
    <RadioGroup v-model="businessInfo.address_option">
      <div class="bg-white rounded-md -space-y-px pt-8">
        <RadioGroupOption
          as="template"
          v-for="(setting, settingIdx) in settings"
          :key="setting.name"
          :value="setting"
          v-slot="{ checked, active }"
        >
          <div
            @click="clearAddressOption"
            :class="[
              settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
              settingIdx === settings.length - 1
                ? 'rounded-bl-md rounded-br-md'
                : '',
              checked
                ? 'bg-indigo-50 border-indigo-200 z-10'
                : 'border-gray-200',
              'relative border p-4 flex cursor-pointer focus:outline-none',
            ]"
          >
            <span
              :class="[
                checked
                  ? 'bg-indigo-600 border-transparent'
                  : 'bg-white border-gray-300',
                active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
              ]"
              aria-hidden="true"
            >
              <span class="rounded-full bg-white w-1.5 h-1.5" />
            </span>
            <div class="ml-3 flex flex-col">
              <RadioGroupLabel
                @click="clearAddressOption"
                :value="setting.id"
                as="span"
                :class="[
                  checked ? 'text-indigo-900' : 'text-gray-900',
                  'block text-sm font-medium',
                ]"
              >
                {{ setting.name }}
              </RadioGroupLabel>
            </div>
          </div>
        </RadioGroupOption>
        <div
          v-if="businessInfo.address_option === settings[0]"
          class="pt-8 information-card mt-4"
        >
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("message.rentalInfo") }}
            </h3>
          </div>
          <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <label class="block text-sm font-medium text-gray-700">
                {{ $t("message.rentalPeriod") }}
              </label>
              <div class="mt-1">
                <select
                  v-model="businessInfo.rental_period"
                  id="location"
                  name="location"
                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option value="aylik">Aylık</option>
                  <option>Yıllık</option>
                </select>
              </div>
            </div>
            <div class="sm:col-span-3">
              <label class="block text-sm font-medium text-gray-700">
                {{ $t("message.ownerTc") }}
              </label>
              <div class="mt-1">
                <input
                  v-model="businessInfo.identity_info"
                  type="text"
                  name="first-name"
                  class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label class="block text-sm font-medium text-gray-700">
                {{ $t("message.rentalAmount") }} ( {{ $t("message.net") }})
              </label>
              <div class="mt-1">
                <input
                  v-model="businessInfo.rental_amount_net"
                  type="text"
                  name="first-name"
                  class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label class="block text-sm font-medium text-gray-700">
                {{ $t("message.rentalAmount") }} (
                {{ $t("message.beforeTax") }})
              </label>
              <div class="mt-1">
                <input
                  v-model="businessInfo.rental_amount_brut"
                  type="text"
                  name="first-name"
                  autocomplete="off"
                  class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label class="block text-sm font-medium text-gray-700">
                {{ $t("message.currency") }}
              </label>
              <div class="mt-1">
                <select
                  id="country"
                  name="country"
                  autocomplete="off"
                  class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                  <option
                    v-for="(type, index) in currency_type"
                    :key="index"
                    :value="(businessInfo.currency = type)"
                  >
                    {{ type.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="businessInfo.address_option === settings[3]"
          class="pt-8 information-card mt-4"
        >
          <div class="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-1">
            <fieldset class="space-y-5">
              <!-- This example requires Tailwind CSS v2.0+ -->

              <select
                id="tabs"
                name="tabs"
                v-model="selectedLocation"
                @change="onSelectedLocation()"
                class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              >
                <option
                  v-for="location in locations"
                  :key="location.id"
                  :value="location.id"
                  :selected="selectedLocation == locations.id"
                >
                  {{ location.name }}
                </option>
              </select>

              <div class="packets grid grid-cols-3 gap-4">
                <div
                  v-for="product in products"
                  :key="product.name"
                  :class="
                    selectedProduct == product.id
                      ? 'border border-indigo-800'
                      : ''
                  "
                  class="flex flex-col rounded-lg shadow-lg overflow-hidden border border-transparent"
                >
                  <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6 padding-btm">
                    <div>
                      <h3
                        class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600 sticker-size"
                        id="tier-standard"
                      >
                        {{ product.name }}
                      </h3>
                    </div>
                    <div
                      class="mt-4 flex items-baseline text-4xl font-extrabold price-txt-size"
                    >
                      {{ product.price }} ₺
                      <span
                        class="ml-1 text-xl font-medium text-gray-500 small-tx"
                      >
                        / {{ $t("message.monthly") }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="flex flex-col h-full justify-between bg-white px-6 pt-6 pb-8 space-y-6 sm:p-10 sm:pt-6"
                  >
                    <ul role="list" class="space-y-4">
                      <li
                        v-for="(feature, index) in product.features"
                        :key="index"
                        class="flex items-start"
                      >
                        <div class="flex-shrink-0">•</div>
                        <p class="ml-3 text-base text-gray-700">
                          {{ feature.name }}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="flex flex-col justify-end bg-gray-50 px-6 pt-6 pb-6 space-y-6 sm:p-10 sm:pt-6"
                  >
                    <div class="rounded-md shadow">
                      <div
                        @click="selectedPacket(product)"
                        class="flex items-center justify-center px-5 py-3 hover:cursor-pointer border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                        aria-describedby="tier-standard"
                      >
                        {{
                          selectedProduct == product.id
                            ? $t("message.choosed")
                            : $t("message.choose")
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <SwitchGroup as="div" class="flex items-center mt-6">
                <Switch
                  v-model="physicalSpaceSelection"
                  :class="[
                    physicalSpaceSelection ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                  ]"
                  @click="setPhysicalSpace"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      physicalSpaceSelection
                        ? 'translate-x-5'
                        : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    ]"
                  />
                </Switch>
                <SwitchLabel as="span" class="ml-3">
                  <span class="text-sm font-medium text-gray-900">
                    Fiziki alana ihtiyacın mı var?
                  </span>
                </SwitchLabel>
              </SwitchGroup>

              <div v-if="physicalSpaceSelection">
                <!--                  v-model="businessInfo.virtual_office_id"-->
                <RadioGroup
                  v-model="this.selectedPhysclSpace"
                  @change="selectedPhysicalSpace"
                >
                  <div class="rounded-md -space-y-px">
                    <RadioGroupOption
                      as="template"
                      v-for="office in physicalSpace"
                      :key="office.name"
                      :value="office.id"
                      :aria-checked="
                        this.selectedPhysclSpace == office.id ? true : false
                      "
                      v-slot="{ active }"
                    >
                      <div
                        class="rounded-tl-md rounded-tr-md rounded-bl-md rounded-br-md z-10 relative p-4 flex cursor-pointer focus:outline-none"
                      >
                        <span
                          :class="[
                            this.selectedPhysclSpace == office.id
                              ? 'bg-indigo-600'
                              : 'bg-white ',
                            active
                              ? 'ring-2 ring-offset-2 ring-indigo-500'
                              : '',
                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                          ]"
                          aria-hidden="true"
                          :aria-checked="
                            this.selectedPhysclSpace == office.id ? true : false
                          "
                        >
                          <span class="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <div class="ml-3 flex flex-col">
                          <RadioGroupLabel
                            :value="office.id"
                            as="span"
                            :class="[
                              this.selectedPhysclSpace == office.id
                                ? 'text-indigo-900'
                                : 'text-gray-900',
                              'block text-sm font-medium',
                            ]"
                          >
                            <div class="flex space-x-2">
                              <span>
                                {{ office.name }}
                              </span>
                            </div>
                          </RadioGroupLabel>
                        </div>
                      </div>
                    </RadioGroupOption>
                  </div>
                </RadioGroup>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </RadioGroup>
    <div class="pt-5">
      <div class="flex justify-end">
        <router-link
          :to="`/${$store.state.lang}/app/application-form/personal-info`"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {{ $t("message.prevPage") }}
        </router-link>
        <button
          @click="addBusinessInfo"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {{ $t("message.nextPage") }}
        </button>
      </div>
    </div>
  </main>
  <div v-else class="lg:col-span-9">
    <!-- Profile section -->
    <div class="flex justify-center items-center py-12">
      <div
        class="flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="text-white uppercase text-lg text-center">
          <div>BU SAYFAYI GÖRÜNTÜLEMEK İÇİN YETKİNİZ BULUNMAMAKTADIR.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  Switch,
  SwitchLabel,
  SwitchGroup,
} from "@headlessui/vue";
import { Mixin } from "../../../library/utils";
import BusinessInfoService from "../../../services/businessInfo.service";
import ProductsService from "../../../services/products.service";
import locationService from "../../../services/location.service";

export default {
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    Switch,
    SwitchLabel,
    SwitchGroup,
  },
  mixins: [Mixin],
  setup() {
    const enabled = ref(false);

    return {
      enabled,
    };
  },
  data() {
    return {
      businessInfo: {
        rental_period: "-",
        identity_info: "-",
        rental_amount_net: "-",
        rental_amount_brut: "-",
        currency: "-",
        location_id: "-",
        address_option: { id: 0, name: "" },
        virtual_office_selection: false,
        packet_id: "-",
        physical_space_selection: false,
        physical_space: "-",
      },
      physicalSpaceSelection: false,
      virtualOffices: [],
      selectedOffice: "",
      accountId: 0,
      selected: {},
      settings: [
        { id: 1, name: this.$t("message.renter") },
        { id: 2, name: this.$t("message.ownedByMe") },
        { id: 3, name: this.$t("message.virtualOffice") },
        { id: 4, name: this.$t("message.dontHaveOperatingAddress") },
      ],
      employeeType: [
        {
          id: 1,
          name: "Hayır",
        },
        {
          id: 2,
          name: "Evet. 1-3 İşçi [50.85 ₺ + KDV/Ay]",
        },
        {
          id: 3,
          name: "Evet. 4-9 İşçi [76.27 ₺ + KDV/Ay]",
        },
      ],
      currency_type: [
        { id: 1, name: "TL (₺)" },
        { id: 2, name: "USD ($)" },
        { id: 3, name: "Euro (€)" },
      ],
      step_name: "",
      locations: [],
      selectedLocation: null,
      products: [],
      selectedProduct: null,
      physicalSpace: [],
      selectedPhysclSpace: "-",
      features: {
        Başlangıç: [
          "Resmi Adres Kullanımı",
          "Kargo Karşılaması",
          "Tebligat takibi ve bildirimi",
          "Stopaj Ödemezsiniz",
        ],
        Standart: [
          "Resmi Adres Kullanımı",
          "Kargo Karşılaması",
          "Tebligat takibi ve bildirimi",
          "Stopaj Ödemezsiniz",
          "Şirketinize Özel IP Telefon Numarası ( 0212, 0232, 0216, 0850 ile başlayan )",
          "Çağrılarınızı Şirketinize Özel Yanıtlayan Sekreter ( Türkçe ve İngilizce )",
          "Gelen Çağrıların E-posta Adresinize Bildirilmesi",
        ],
        Premium: [
          "Resmi Adres Kullanımı",
          "Kargo Karşılaması",
          "Tebligat takibi ve bildirimi",
          "Stopaj Ödemezsiniz",
          "Şirketinize Özel IP Telefon Numarası ( 0212, 0232, 0216, 0850 ile başlayan )",
          "Çağrılarınızı Şirketinize Özel Yanıtlayan Sekreter ( Türkçe ve İngilizce )",
          "Gelen Çağrıların E-posta Adresinize Bildirilmesi",
          "Ayda 10 Saat Toplantı Odası Kullanımı",
          "Çay, Kahve, Projeksiyon, Yüksek Hızda İnternet ve Ücretsiz Otopark Hizmeti",
          "4 Ayrı lokasyonda toplantı rezervasyonu",
        ],
      },
      company_name: null,
    };
  },
  computed: {
    watchLang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    async watchLang(val) {
      localStorage.setItem("lang", val);
      await this.getVirtualOffices();
    },
  },
  methods: {
    setStatus() {
      this.$store.state.steps[3].status = "complete";
      // this.$store.state.steps[4].status = "current";
    },
    async addBusinessInfo() {
      this.businessInfo.physical_space =
        this.selectedPhysclSpace != null ? this.selectedPhysclSpace : "-";
      this.businessInfo.physical_space_selection = this.physicalSpaceSelection;
      let response = await new BusinessInfoService().addBusinessInfo(
        this.businessInfo,
        this.accountId,
        "",
        this.step_name
      );
      if (response.status === 201) {
        this.setStatus();
        await this.$router.push(
          `/${this.$store.state.lang}/app/application-form/files`
        );
      }
    },
    async getBusinessInfo(id) {
      this.accountId = id;
      let response = await new BusinessInfoService().getBusinessInfo(id);
      if (response.result.informations.length > 0) {
        for (let i = 0; i < response.result.informations.length; i++) {
          this.businessInfo[response.result.informations[i].key] =
            response.result.informations[i].value;
        }
        this.businessInfo.address_option =
          this.businessInfo.address_option.id !== 0
            ? JSON.parse(this.businessInfo.address_option)
            : "";
        this.businessInfo.address_option =
          this.businessInfo !== ""
            ? this.settings.find(
                (element) => element.id === this.businessInfo.address_option.id
              )
            : { id: 0, name: "" };

        this.selectedLocation = this.businessInfo.location_id;
        this.onSelectedLocation();
        this.selectedProduct = this.businessInfo.packet_id;
        this.selectedPhysclSpace = this.businessInfo.physical_space;
        this.physicalSpaceSelection =
          this.businessInfo.physical_space_selection;

        let index = this.$store.state.steps.findIndex(
          (step) => step.slug === this.businessInfo.step
        );
        let route_index = this.$store.state.steps.findIndex(
          (step) => step.to === this.$route.path
        );
        if (index > route_index) {
          this.step_name = this.businessInfo.step;
        } else {
          this.step_name = "business-info";
        }
        let companyName = JSON.parse(localStorage.getItem("company_name"));
        let sonuc = response.result.users[0].accounts.find((element) => {
          element.name === companyName;
          return true;
        });
        if (sonuc) this.company_name = companyName;
      }
    },
    clearAddressOption() {
      this.businessInfo = {
        rental_period: "-",
        identity_info: "-",
        rental_amount_net: "-",
        rental_amount_brut: "-",
        currency: "-",
        address_option: { id: 0, name: "" },
        virtual_office_selection: false,
        packed_id: "-",
        physical_space_selection: false,
        physical_space: "-",
        packet_id: "",
        location_id: this.locations[0].id,
      };
    },
    setPhysicalSpace() {
      if (!this.physicalSpaceSelection) {
        this.selectedPhysclSpace =
          this.selectedPhysclSpace !== null
            ? this.selectedPhysclSpace
            : this.physicalSpace[0];
      } else {
        this.selectedPhysclSpace = null;
      }
    },
    async getVirtualOffices() {
      let response = await new ProductsService().getVirtualOffices(
        "",
        "type:request"
      );
      this.virtualOffices = response.result.data;
    },
    async getLocations() {
      const params = {
        fixed: true,
      };
      const response = await new locationService().getLocations(params);
      this.locations = response.result.data;
      this.selectedLocation = this.locations[0].id;
      this.businessInfo.location_id = this.locations[0].id;
      this.onSelectedLocation();
    },
    async onSelectedLocation() {
      this.businessInfo.location_id = this.selectedLocation;
      const paramsPysical = {
        filters: `locations:${this.selectedLocation};types:request`,
      };
      const paramsSubscription = {
        filters: `locations:${this.selectedLocation};types:subscription`,
      };
      const responsePysical = await new ProductsService().getPackets(
        paramsPysical
      );
      const responseSubscription = await new ProductsService().getPackets(
        paramsSubscription
      );

      this.products = responseSubscription.result.filter(
        (x) => x.features.length > 0
      );
      this.physicalSpace = responsePysical.result.filter(
        (x) => x.product_plan == null
      );
    },
    selectedPacket(packet) {
      this.selectedProduct = packet.id;

      this.businessInfo.virtual_office_selection = true;
      this.businessInfo.packet_id = this.selectedProduct;
    },
    selectedPhysicalSpace() {
      this.businessInfo.physical_space = this.selectedPhysclSpace;
    },
  },
  async created() {
    if (this.checkPermission("update accounts")) {
      await this.getVirtualOffices();
      await this.getLocations();
      await this.getBusinessInfo(
        JSON.parse(localStorage.getItem("account")).id,
        JSON.parse(localStorage.getItem("user")).id
      );
    }
  },
};
</script>
<style scoped lang="scss">
.information-card {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 11px;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.customInputClass {
  height: 40px !important;
}
</style>
