import axios from "axios";
import authHeader from "./auth-header";
import authFairHeader from "./auth-fair-header";

class CompaniesService {
  getCompanies() {
    return axios
      .get(`enb/companies`, { headers: authFairHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  getStages(id) {
    return axios
      .get(`enb/stages${id ? `/${id}` : ""} `, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
  addCompanyOnList(form) {
    return axios.post(`enb/companies`, form, { headers: authHeader() });
  }
  addInfo(information, id, user_id) {
    return axios
      .post(
        `accounts/${id}/informations`,
        {
          informations: [
            {
              key: "company_name",
              value: information.company_name,
            },
            {
              key: "company_year",
              value: information.company_year,
            },
            {
              key: "company_sector",
              value: information.company_sector,
            },
            {
              key: "company_staffCount",
              value: information.company_staffCount,
            },
            {
              key: "company_country",
              value: information.company_country,
            },
            {
              key: "company_city",
              value: information.company_city,
            },
            {
              key: "company_district",
              value: information.company_district,
            },
            {
              key: "company_address",
              value: information.company_address,
            },
            {
              key: "company_mail",
              value: information.company_mail,
            },
            {
              key: "company_phone",
              value: information.company_phone,
            },
            {
              key: "company_official_person",
              value: information.official_info,
            },
            {
              key: "company_logo",
              value: information.company_logo,
            },
            {
              key: "company_area",
              value: information.company_area,
            },
            {
              key: "company_info",
              value: information.company_info,
            },
            {
              key: "company_packet",
              value: information.packet,
            },
            {
              key: "agent_name",
              value: information.agent_name,
            },
            {
              key: "agent_mail",
              value: information.agent_mail,
            },
          ],
        },
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
  addPayment(info, card, products) {
    return axios
      .post(
        `enb/buy-company`,
        {
          companies: {
            company_name: info.company_name,
            company_details: info.company_details,
            contact_name: info.contact_name,
            contact_email: info.contact_email,
            contact_address: info.contact_address,
            company_logo: info.company_logo,
            contact_phone: info.contact_phone,
            agent: {
              agent_email: info.agent.agent_email,
              agent_name: info.agent.agent_name,
            },
          },
          cards: {
            card_number: card.cardNumber,
            card_expire_month: card.card_expire_month,
            card_expire_year: card.card_expire_year,
            card_cvc: card.card_cvc,
            card_holder_name: card.name,
          },
          account_id: JSON.parse(localStorage.getItem("account")).id,
          products: {
            product_id: products.id,
            brand_id: 7,
          },
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}

export default CompaniesService;
