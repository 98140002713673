import axios from "axios";
import authHeader from "./auth-header";

class BusinessInfoService {
  addBusinessInfo(businessInfo, id, user_id, step_name) {
    return axios
      .post(
        `accounts/${id}/informations`,
        {
          informations: [
            {
              key: "address_option",
              value: businessInfo.address_option,
            },
            {
              key: "rental_amount_brut",
              value:
                businessInfo.rental_amount_brut === ""
                  ? "-"
                  : businessInfo.rental_amount_brut,
            },
            {
              key: "rental_period",
              value:
                businessInfo.rental_period === ""
                  ? "-"
                  : businessInfo.rental_period,
            },
            {
              key: "rental_amount_net",
              value:
                businessInfo.rental_amount_net === ""
                  ? "-"
                  : businessInfo.rental_amount_net,
            },
            {
              key: "currency",
              value: businessInfo.currency === "" ? "-" : businessInfo.currency,
            },
            {
              key: "step",
              value: step_name,
            },
            {
              key: "location_id",
              value:
                businessInfo.location_id === ""
                  ? "-"
                  : businessInfo.location_id,
            },
            {
              key: "virtual_office_selection",
              value:
                businessInfo.virtual_office_selection === ""
                  ? "-"
                  : businessInfo.virtual_office_selection,
            },
            {
              key: "packet_id",
              value:
                businessInfo.packet_id === "" ? "-" : businessInfo.packet_id,
            },
            {
              key: "physical_space_selection",
              value:
                businessInfo.physical_space_selection === ""
                  ? "-"
                  : businessInfo.physical_space_selection,
            },
            {
              key: "physical_space",
              value:
                businessInfo.physical_space === ""
                  ? "-"
                  : businessInfo.physical_space,
            },
          ],
        },
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  getBusinessInfo(id, user_id) {
    return axios
      .get(
        `accounts/${id}`,
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default BusinessInfoService;
