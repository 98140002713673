export default function authFairHeader() {
  let user = localStorage.getItem("token");

  if (user) {
    return {
      Authorization: "Bearer " + user,
      "Content-Type": "application/json",
      "X-API-KEY": "aaf888374030789176a9f9505bfc32d8",
      Accept: "application/json",
      "Accept-Language": localStorage.getItem("lang"),
    };
  } else {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-API-KEY": "aaf888374030789176a9f9505bfc32d8",
      "Accept-Language": localStorage.getItem("lang"),
    };
  }
}
