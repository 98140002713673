<template>
  <div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <input
        :value="modelValue"
        v-on:blur="checkPassword"
        @input="$emit('update:modelValue', $event.target.value)"
        name="password"
        :type="type"
        autocomplete="off"
        required=""
        class="user_password form-control appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
      <div class="absolute inset-y-0 right-0 flex items-center">
        <label for="currency" class="sr-only">Currency</label>
        <EyeIcon
          class="mr-1 h-6 w-6 text-gray-500"
          @click="changeInputType"
          v-if="type === 'password'"
        ></EyeIcon>
        <EyeOffIcon
          class="mr-1 h-6 w-6 text-gray-500"
          @click="changeInputType"
          v-else
        ></EyeOffIcon>
      </div>
    </div>
    <div v-if="showError">
      <div
        class="mb-7 absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none"
      ></div>
      <p class="mt-2 text-sm text-red-600" id="email-error">
        Şifreniz minimum 6 karakter uzunluğunda olmalıdır
      </p>
    </div>
  </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from "@heroicons/vue/outline";

export default {
  name: "PasswordInput",
  components: {
    EyeIcon,
    EyeOffIcon,
  },
  props: {
    text: String,
    modelValue: String,
  },
  data() {
    return {
      type: "password",
      showError: false,
    };
  },
  methods: {
    changeInputType() {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    checkPassword() {
      if (this.modelValue.length < 6) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="css"></style>
