<template>
  <div class="lg:col-span-9">
    <!-- Profile section -->
    <div class="py-6 px-3 sm:p-6 lg:pb-8">
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="px-5 mt-8">
            <div class="">
              <div class="bg-white">
                <div class="bg-white">
                  <div>
                    <div
                      class="mt-2 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4"
                    >
                      <div
                        v-for="(packet, id) in packets"
                        :key="id"
                        class="relative packet-cards rounded-lg shadow-sm divide-y divide-gray-200 p-2"
                      >
                        <div class="p-6 min-h-[168px]">
                          <h2
                            class="text-xl leading-6 font-medium text-gray-900"
                          >
                            {{ packet.name }}
                          </h2>
                          <!--                        <p class="mt-2 text-sm text-gray-500">{{// $t('message.refreshDate')}}: {{ // pack }}/{{ tier }}/{{ tier.start_date.substring(0,4) }}</p>-->
                          <p class="mt-8">
                            <span class="text-4xl font-extrabold text-gray-900"
                              >{{ packet.price }} TL
                            </span>
                            {{ " " }}
                            <span
                              v-if="packet.type === 'subscription'"
                              class="text-sm font-small text-gray-500"
                            >
                              / {{ $t("message.monthly") }}</span
                            >
                            <span
                              v-else-if="packet.type === 'one-time'"
                              class="text-sm font-small text-gray-500"
                            >
                              / Tek Seferlik</span
                            >
                          </p>
                        </div>
                        <button
                          @click="removeProduct(packet)"
                          class="my-1 m-auto w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Paketi iptal et
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Mixin } from "../../../library/utils";
import AccountsService from "@/services/accounts.service";
import ProductsService from "@/services/products.service";

export default {
  mixins: [Mixin],
  components: {},
  setup() {},
  data() {
    return {
      packets: [],
      account_id: JSON.parse(localStorage.getItem("account")).id,
    };
  },
  methods: {
    async getUsersProducts() {
      let all_products = await new AccountsService().getAccountsProducts(
        JSON.parse(localStorage.getItem("account")).id
      );
      this.packets = all_products.result.data;
    },
    async removeProduct(product) {
      await new ProductsService().removeProduct(this.account_id, product.id);
      await this.getUsersProducts();
    },
  },
  async created() {
    await this.getUsersProducts();
  },
};
</script>
<style lang="scss">
.bg-gray {
  background: #f9f9f9;
  border: 0px;
  box-shadow: 0 0 0 transparent;
}
.packet-name {
  font-size: 25px;
  padding-bottom: 15px;
  width: max-content;

  .underline {
    height: 2px;
    width: 70%;
    background: #4f46e5;
    margin-top: 6px;
  }
}

.packet-cards {
  box-shadow: 0px 18px 20px -10px #ddd;
  border: 1px solid #f3f3f3;
}
</style>
