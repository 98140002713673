<template>
  <main>
    <div class="max-w-7xl mx-auto minh-100">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="relative">
        <div class="absolute inset-0">
          <div class="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div class="relative max-w-7xl mx-auto">
          <div
            class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none"
          >
            <div
              v-for="office in locationsTemp"
              :key="office.id"
              class="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div class="flex-shrink-0">
                <img
                  class="h-48 w-full object-fill"
                  :src="office.src"
                  alt="torun"
                />
              </div>
              <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                <div class="flex-1">
                  <p class="text-lg font-bold text-indigo-600">
                    <a href="#" class="hover:underline">
                      {{ office.name }}
                    </a>
                  </p>
                  <a href="#" class="block mt-2">
                    <div
                      class="flex text-m items-center gap-2 font-semibold text-gray-900"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="white"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      <span>
                        {{ office.desc }}
                      </span>
                    </div>
                    <p class="mt-4 text-sm text-gray-500">
                      {{ office.address }}
                    </p>
                  </a>
                </div>
                <div class="mt-6 flex items-center">
                  <div class="flex-shrink-0">
                    <a href="#">
                      <span class="sr-only">Brenna Goyette</span>
                    </a>
                  </div>
                  <div class="w-full">
                    <p class="text-sm font-medium text-gray-900">
                      <router-link
                        @click="addLocation(office)"
                        to="/app/office/application-form/packets"
                        class="mt-2 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                      >
                        SEÇ
                      </router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import LocationService from "../../../services/location.service";
import AccountsService from "../../../services/accounts.service";

export default {
  components: {},
  computed: {
    watchLang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    async watchLang(val) {
      localStorage.setItem("lang", val);
      await this.getLocations();
    },
  },
  methods: {
    async getLocations() {
      let response = await new LocationService().getLocations();
      this.locations = response.result.data;
    },
    async addLocation(location) {
      await new LocationService().addLocationInfo(location, this.step_name);
      await this.$router.push({
        name: "OfficePackets",
        params: { lang: this.$store.state.lang },
      });
    },
    async getInfo() {
      let response = await new AccountsService().getAccount(
        JSON.parse(localStorage.getItem("account")).id
      );
      if (response.result.informations.length > 0) {
        for (let i = 0; i < response.result.informations.length; i++) {
          this.accountInfo[response.result.informations[i].key] =
            response.result.informations[i].value;
        }
      }
      let index = this.$store.state.endlessOfficeSteps.findIndex(
        (step) => step.slug === this.accountInfo.office_step
      );
      let route_index = this.$store.state.endlessOfficeSteps.findIndex(
        (step) => step.to === this.$route.path
      );
      if (index > route_index) {
        this.step_name = this.accountInfo.step;
      } else {
        this.step_name = "locations";
      }
    },
  },
  data() {
    return {
      haveCompany: false,
      locationsTemp: [
        {
          id: 1,
          name: "TORUN CENTER",
          desc: "İstanbul",
          address:
            "Fulya Mahallesi Büyükdere Caddesi No:74/D Torun Center Kat:3 Ofis:10 34394 Şişli / İstanbul",
          src: "/img/torun-center.jpeg",
        },
        {
          id: 2,
          name: "PROFILO PLAZA",
          desc: "İstanbul",
          address:
            "Cemal Sahir Sok. No: 26-28 Profilo Plaza A Blok K:1 Mecidiyeköy 34394 Şişli / İstanbul",
          src: "/img/profilo-plaza.jpeg",
        },
        {
          id: 3,
          name: "VOGUE BUSINESS CENTER",
          desc: "İstanbul",
          address:
            "Küçükbakkalköy Mah. Rüya Sok. No:12-18 Kat:8 Vogue Business Center 34750 Ataşehir/İstanbul ",
          src: "/img/vouge-busıness.jpg",
        },
        {
          id: 4,
          name: "FOLKART TOWERS",
          desc: "İzmir",
          address:
            "Adalet Mah. Manas Bulvarı Folkart Towers A Kule No:47 B Kat:35 Daire: 3509 Bayraklı / İzmir",
          src: "/img/folkart.jpeg",
        },
        {
          id: 5,
          name: "NEXT LEVEL LOFT OFIS",
          desc: "Ankara",
          address:
            "Kızılırmak Mah. Ufuk Üniversitesi Cad. Next Levet Loft Ofis 4/72-76 A Kat:27 06510 Sögütözü / Ankara",
          src: "/img/AnkaraNextLevel.jpeg",
        },
        {
          id: 6,
          name: "BAKü ÇINAR PLAZA",
          desc: "Bakü",
          address:
            "Haydar Aliyev Prospekti 152 Çinar Plaza İş Merkezi A Blok 4.Kat 64m Bakü/Azerbaycan",
          src: "/img/BakuCinarPlaza.jpg",
        },
      ],
      accountInfo: {},
      locations: [],
      step_name: "",
    };
  },
  async created() {
    await this.getInfo();
    await this.getLocations();
  },
};
</script>

<style scoped>
.price-txt-size {
  font-size: 2rem !important;
}

.sticker-size {
  font-size: 0.675rem !important;
}

.small-tx {
  font-size: 0.9rem !important;
}

.packed-shadow {
  box-shadow: 1px 6px 17px 0px #cfcfcf;
}

.started-price-info {
  font-size: 13px;
  line-height: 19px;
  color: #6d6d6d;
  font-weight: 300;
}

.title {
  font-weight: 600;
}

.price {
  font-weight: 800;
  font-size: 27px;
  margin-top: 18px;
  line-height: 10px;
}

.text-line {
  font-weight: 300 !important;
  font-size: 0.8rem !important;
}

.padding-btm {
  padding-bottom: 0px !important;
}
</style>
