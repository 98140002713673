<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <main>
    <div
      v-if="show > 0"
      class="documents-container max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100"
    >
      <!-- Replace with your content -->
      <div
        class="space-y-8 divide-y divide-gray-100 px-5 bg-gray-100 border border-2 border-gray-500 border-dashed rounded-md"
      >
        <div class="space-y-8 divide-y divide-gray-200">
          <div>
            <div class="sm:col-span-6">
              <div
                class="flex w-full items-center justify-center text-center"
                id="app"
              >
                <div
                  class="px-12 py-5 w-full text-center mt-1"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop"
                >
                  <svg
                    class="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <input
                    type="file"
                    multiple
                    name="fields[assetsFieldHandle][]"
                    id="assetsFieldHandle"
                    class="w-px h-px opacity-0 overflow-hidden absolute"
                    @change="onChange"
                    ref="fileUpload"
                    accept="/*"
                  />

                  <label for="assetsFieldHandle" class="block cursor-pointer">
                    <div class="text-gray-600">
                      {{ $t("message.uploadFile") }}
                      <span class="underline text-indigo-600">{{
                        $t("message.clickHere")
                      }}</span>
                    </div>
                    <p class="text-xs text-gray-500">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </label>
                  <ul class="mt-4" v-if="uploadFiles.length" v-cloak>
                    <li
                      class="text-sm p-1"
                      v-for="(file, index) in uploadFiles"
                      :key="index"
                    >
                      {{ file.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="uploadFiles.length > 0"
        class="bg-gray-100 border border-2 border-gray-500 border-dashed rounded-md p-2 mt-2"
      >
        <div
          v-for="(index, f) in uploadFiles"
          :key="index"
          class="px-5 mt-6 lg:flex xl:flex lg:justify-center xl:justify-center lg:space-x-5 xl:space-x-5"
        >
          <div class="w-full">
            <label class="block text-sm font-medium text-gray-700">
              {{ $t("message.documentName") }} {{ f }}
            </label>
            <div class="mt-1">
              <input
                :value="uploadFiles[f].name"
                @change="changeFileName($event, f)"
                type="text"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div class="w-full">
            <label
              for="country"
              class="block text-sm font-medium text-gray-700"
            >
              {{ $t("message.documentType") }}
            </label>
            <div class="mt-1">
              <select
                id="country"
                name="country"
                autocomplete="off"
                v-model="uploadFiles[f].type_id"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option
                  v-for="(type, index) in fileTypes"
                  :key="index"
                  :value="(uploadFiles[f].type_id = type.id)"
                >
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="w-full">
            <label for="state" class="block text-sm font-medium text-gray-700">
              {{ $t("message.description") }}
            </label>
            <div class="mt-1">
              <textarea
                v-model="uploadFiles[f].description"
                type="text"
                name="state"
                id="state"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div class="pt-5">
            <div class="flex justify-end">
              <button
                @click="remove(uploadFiles.indexOf(file))"
                class="lg:ml-3 mt-3 py-1 px-2 inline-flex justify-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <TrashIcon class="mr-2 h-5 w-5" aria-hidden="true" />

                {{ $t("message.delete") }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="uploadFiles.length > 0" class="pt-5">
          <div class="flex justify-end">
            <button
              @click="getFileUrl"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <SaveIcon class="mr-2 h-5 w-5" aria-hidden="true" />

              {{ $t("message.save") }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="bg-gray-100 rounded-md custom-div mb-2 mt-4 border border-2 border-gray-500 border-dashed"
      >
        <label
          class="block text-md font-large text-gray-700 pt-3 px-5 mb-2 uppercase"
        >
          {{ $t("message.filterDocs") }}
        </label>
        <div class="px-5 lg:flex xl:flex lg:space-x-5 xl:space-x-5">
          <div class="lg:w-full md:w-5/6 mb-2">
            <label class="text-sm font-medium text-gray-700">{{
              $t("message.searching")
            }}</label>
            <div class="relative w-full flex justify-center items-center">
              <Calendar
                id="date"
                v-model="date.start"
                class="flex-grow"
                dateFormat="dd-mm-yy"
                autoComplete="off"
                locale="tr"
              >
              </Calendar>
              <Calendar
                id="date"
                v-model="date.end"
                class="flex-grow"
                dateFormat="dd-mm-yy"
                autoComplete="off"
                locale="tr"
              >
              </Calendar>
              <!--              <DatePicker v-model="date" is-range :masks="masks" locale="tr">-->
              <!--                <template v-slot="{ inputValue, inputEvents }">-->
              <!--                  <div class="flex justify-center items-center">-->
              <!--                    <input-->
              <!--                        :value="inputValue.start"-->
              <!--                        v-on="inputEvents.start"-->
              <!--                        class="bg-white text-gray-700 w-full py-2 px-3 appearance-none border border-gray-300 rounded-md focus:outline-none h-10"-->
              <!--                    />-->
              <!--                    <input-->
              <!--                        :value="inputValue.end"-->
              <!--                        v-on="inputEvents.end"-->
              <!--                        class="bg-white text-gray-700 w-full py-2 px-3 appearance-none border border-gray-300 rounded-md focus:outline-none h-10"-->

              <!--                    />-->
              <!--                  </div>-->
              <!--                </template>-->
              <!--              </DatePicker>-->
            </div>
          </div>
          <div class="lg:w-full md:w-4/6 mb-2">
            <label class="text-sm font-medium text-gray-700"
              >{{ $t("message.incoming") }}/{{ $t("message.outgoing") }}</label
            >
            <Listbox as="div" class="filter-input" v-model="selected">
              <div class="relative">
                <ListboxButton
                  class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <span class="block truncate">{{ selected.name }}</span>
                  <span
                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <SelectorIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  >
                    <ListboxOption
                      as="template"
                      v-for="filter in filter"
                      :key="filter.id"
                      :value="filter"
                      v-slot="{ active, selected }"
                    >
                      <li
                        :class="[
                          active ? 'text-white bg-indigo-600' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9',
                        ]"
                      >
                        <span
                          :class="[
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          ]"
                        >
                          {{ filter.name }}
                        </span>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
          <div class="lg:w-full md:w-5/6 mb-2">
            <label class="text-sm font-medium text-gray-700">{{
              $t("message.documentType")
            }}</label>
            <Listbox as="div" class="filter-input" v-model="selectedFileType">
              <div class="relative">
                <ListboxButton
                  class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <span class="block truncate">{{
                    selectedFileType.name
                  }}</span>
                  <span
                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <SelectorIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  >
                    <ListboxOption
                      as="template"
                      v-for="filter in fileTypes"
                      :key="filter"
                      :value="filter"
                      v-slot="{ active, selected }"
                    >
                      <li
                        :class="[
                          active ? 'text-white bg-indigo-600' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9',
                        ]"
                      >
                        <span
                          :class="[
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          ]"
                        >
                          {{ filter.name }}
                        </span>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
          <div class="lg:w-full md:w-5/6 mb-2">
            <label class="text-sm font-medium text-gray-700">{{
              $t("message.searching")
            }}</label>
            <input
              class="filter-input focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              v-model="search"
              type="text"
            />
          </div>
          <div class="mt-5 filter-button">
            <button
              @click="filterFiles"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {{ $t("message.search") }}
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-col pt-8 mt-3">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-100">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("message.documentName") }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("message.description") }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Tür
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Gelen/Giden
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("message.uploadedDate") }}
                    </th>

                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only"></span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="files.length > 0"
                  class="bg-white divide-y divide-gray-200"
                >
                  <tr v-for="file in files" :key="file">
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      {{ file.name }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ file.description }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ file.type.name }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      <span v-if="user_id == file.user_id"> Giden</span>
                      <span v-else>Gelen</span>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ formatedDate(file.created_at) }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <button
                        @click="getSingleFile(file.id)"
                        class="text-indigo-600 font-medium hover:text-indigo-900 mr-3"
                      >
                        {{ $t("message.edit") }}
                      </button>
                      <button
                        @click="setDeleteModal(file.id)"
                        class="text-indigo-600 font-medium hover:text-indigo-900 mr-3"
                      >
                        {{ $t("message.delete") }}
                      </button>
                      <a
                        @click="
                          downloadFile(file.id, file.account_id, file.name)
                        "
                        target="_blank"
                        :href="`https://api.emusavirim.net/` + file.path"
                        class="text-indigo-600 hover:text-indigo-900"
                        >{{ $t("message.download") }}</a
                      >
                    </td>
                  </tr>
                </tbody>
                <tbody v-else class="bg-white divide-y divide-gray-200">
                  <tr>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      {{ $t("message.noResultForFile") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <TransitionRoot as="template" :show="updateModal">
        <Dialog
          as="div"
          class="fixed z-10 inset-0 overflow-y-auto"
          @close="updateModal = false"
        >
          <div
            class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in duration-200"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              />
            </TransitionChild>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span
              class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
              >&#8203;</span
            >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              >
                <div>
                  <div class="mt-3 text-center sm:mt-5">
                    <div class="mt-2">
                      <form class="space-y-6" action="#" method="POST">
                        <div
                          class="flex border border-indigo-300 border-dashed rounded-md items-center justify-center space-x-7"
                        >
                          <div>
                            <img
                              class="ml-3 w-44 h-32 py-2 flex-shrink-0 mx-auto rounded-md"
                              :src="`https://api.emusavirim.net/` + file.path"
                              alt=""
                            />
                          </div>
                          <div class="p-12 w-full text-center">
                            <input
                              v-show="uploadFilesUpdate.length === 0"
                              type="file"
                              name="fields[assetsFieldHandleEdit][]"
                              id="assetsFieldHandleEdit"
                              class="w-px h-px opacity-0 overflow-hidden absolute"
                              @change="onChange"
                              ref="fileUpdate"
                              accept="/*"
                            />
                            <label
                              v-show="uploadFilesUpdate.length === 0"
                              for="assetsFieldHandleEdit"
                              class="block cursor-pointer"
                            >
                              <svg
                                class="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <div class="text-gray-600">
                                Yüklemek için tıklayın
                              </div>
                            </label>
                            <ul v-if="uploadFilesUpdate.length" v-cloak>
                              <li
                                class="text-sm p-1 remove-btn-content"
                                v-for="(file, index) in uploadFilesUpdate"
                                :key="index"
                              >
                                {{ file.name }}
                                <button
                                  class="remove-btn"
                                  type="button"
                                  @click="remove(uploadFiles.indexOf(file))"
                                  title="Remove file"
                                >
                                  <img
                                    src="../../../public/img/delete-bin-fill.svg"
                                    width="15"
                                    height="15"
                                    alt="delete"
                                  />
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div>
                          <label
                            class="block text-sm font-medium text-gray-700"
                          >
                            {{ $t("message.documentName") }}
                          </label>
                          <div class="mt-1">
                            <input
                              v-model="file.name"
                              name="text"
                              type="text"
                              autocomplete="off"
                              required=""
                              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            class="block text-sm font-medium text-gray-700"
                          >
                            {{ $t("message.description") }}
                          </label>
                          <div class="mt-1">
                            <input
                              v-model="file.description"
                              name="text"
                              type="text"
                              autocomplete="off"
                              required=""
                              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div>
                          <label class="text-sm font-medium text-gray-700">{{
                            $t("message.documentType")
                          }}</label>
                          <div class="mt-1">
                            <Listbox as="div" v-model="selectedFileType">
                              <div class="relative">
                                <ListboxButton
                                  class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                  <span class="block truncate">{{
                                    selectedFileType.name
                                  }}</span>
                                  <span
                                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                                  >
                                    <SelectorIcon
                                      class="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </ListboxButton>

                                <transition
                                  leave-active-class="transition ease-in duration-100"
                                  leave-from-class="opacity-100"
                                  leave-to-class="opacity-0"
                                >
                                  <ListboxOptions
                                    class="mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                  >
                                    <ListboxOption
                                      as="template"
                                      v-for="filter in fileTypes"
                                      :key="filter"
                                      :value="filter"
                                      v-slot="{ active, selected }"
                                    >
                                      <li
                                        :class="[
                                          active
                                            ? 'text-white bg-indigo-600'
                                            : 'text-gray-900',
                                          'cursor-default select-none relative py-2 pl-3 pr-9',
                                        ]"
                                      >
                                        <span
                                          :class="[
                                            selected
                                              ? 'font-semibold'
                                              : 'font-normal',
                                            'block truncate',
                                          ]"
                                        >
                                          {{ filter.name }}
                                        </span>

                                        <span
                                          v-if="selected"
                                          :class="[
                                            active
                                              ? 'text-white'
                                              : 'text-indigo-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                          ]"
                                        >
                                          <CheckIcon
                                            class="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </li>
                                    </ListboxOption>
                                  </ListboxOptions>
                                </transition>
                              </div>
                            </Listbox>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                >
                  <button
                    type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    @click="editFile"
                  >
                    {{ $t("message.update") }}
                  </button>
                  <button
                    type="button"
                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    @click="updateModal = false"
                    ref="cancelButtonRef"
                  >
                    {{ $t("message.giveUp") }}
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
      <TransitionRoot as="template" :show="deleteModal">
        <Dialog
          as="div"
          class="fixed z-10 inset-0 overflow-y-auto"
          @close="deleteModal = false"
        >
          <div
            class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in duration-200"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              />
            </TransitionChild>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span
              class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
              >&#8203;</span
            >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              >
                <div class="sm:flex sm:items-start">
                  <div
                    class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <ExclamationIcon
                      class="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle
                      as="h3"
                      class="text-lg leading-6 font-medium text-gray-900"
                    >
                      {{ $t("message.warning") }}
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        {{ $t("message.areYouSureDeleteThisFile") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    @click="deleteFile"
                  >
                    Sil
                  </button>
                  <button
                    type="button"
                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="deleteModal = false"
                    ref="cancelButtonRef"
                  >
                    {{ $t("message.giveUp") }}
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
      <!-- /End replace -->
    </div>
    <div v-if="show === 0" class="flex justify-center items-center py-12">
      <div
        class="bg-gray flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="text-gray-600 text-lg text-center">
          <div>
            {{ $t("message.buyAPacketForShowThisPage") }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { ref } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
  DialogTitle,
} from "@headlessui/vue";
import {
  CheckIcon,
  SelectorIcon,
  SaveIcon,
  ExclamationIcon,
  TrashIcon,
} from "@heroicons/vue/solid";
import DocumentsService from "../../services/documents.service";
// import {DatePicker} from "v-calendar";
import AccountsService from "../../services/accounts.service";
import FilesService from "../../services/files.service";
import moment from "moment/moment";

const filter = [
  { id: 1, name: "Tümü", value: "all" },
  { id: 2, name: "Giden Dosyalar", value: "outgoing" },
  { id: 3, name: "Gelen Dosyalar", value: "incoming" },
];

const people = [
  {
    documentName: "Vergi Levhası",
    date: "01-09-2021",
    desc: "Güncellenmiş Hali",
  },
];
export default {
  components: {
    // DatePicker,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
    SaveIcon,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
    DialogTitle,
    TrashIcon,
  },
  setup() {
    const selected = ref(filter[0]);
    return {
      selected,
      people,
      filter,
    };
  },
  data() {
    return {
      selectedFile: [],
      images: [],
      uploadFiles: [],
      file: "",
      fileTypes: [],
      files: [],
      image: "",
      updateModal: false,
      fileId: 0,
      deleteModal: false,
      date: {
        start: new Date(),
        end: new Date(),
      },
      selectedFileType: "",
      selectFileUpdate: {},
      show: false,
      uploadFilesUpdate: [],
      search: "",
      deletedFile: { name: "", path: "" },
      user_id: JSON.parse(localStorage.getItem("user")).id,
      masks: {
        input: "DD-MM-YYYY",
      },
      steps: [
        {
          id: "1",
          name: this.$t("message.companySituation"),
          to: `/${this.$store.state.lang}/app/application-form`,
          status: "current",
          visible: false,
          slug: "status",
        },
        {
          id: "2",
          name: this.$t("message.packets"),
          to: `/${this.$store.state.lang}/app/application-form/packets`,
          status: "upcoming",
          visible: true,
          slug: "packets",
        },
        {
          id: "3",
          name: this.$t("message.personalInfo"),
          to: `/${this.$store.state.lang}/app/application-form/personal-info`,
          status: "upcoming",
          visible: true,
          slug: "personal-info",
        },
        {
          id: "4",
          name: this.$t("message.addressInfo"),
          to: `/${this.$store.state.lang}/app/application-form/business-info`,
          status: "upcoming",
          visible: true,
          slug: "business-info",
        },
        {
          id: "5",
          name: this.$t("message.files"),
          to: `/${this.$store.state.lang}/app/application-form/files`,
          status: "upcoming",
          visible: true,
          slug: "files",
        },
        {
          id: "6",
          name: this.$t("message.payment"),
          to: `/${this.$store.state.lang}/app/application-form/payment`,
          status: "upcoming",
          visible: true,
          slug: "payment",
        },
        {
          id: "7",
          name: this.$t("message.approve"),
          to: `/${this.$store.state.lang}/app/application-form/wait-for-approve`,
          status: "upcoming",
          visible: true,
          slug: "wait-for-approve",
        },
      ],
      step: {},
      index: -1,
      packetsMusavirim: [],
      advisor: { phone: 0, name: "" },
    };
  },
  methods: {
    async onChange() {
      if (this.updateModal) {
        this.uploadFilesUpdate = [...this.$refs.fileUpdate.files];
        this.deletedFile.name = this.file.name;
        this.file.name = this.uploadFilesUpdate[0].name;
        const formData = new FormData();
        formData.append("files", this.uploadFilesUpdate[0]);
        formData.append("type", "documents");
        formData.append("pid", JSON.parse(localStorage.getItem("user")).id);
        let response = await new FilesService().getFileUrl(formData);
        this.deletedFile.path = this.file.path;
        this.file.path = response.result[0].file_path;
      } else {
        this.uploadFiles = [...this.$refs.fileUpload.files];
        for (let i = 0; i < this.uploadFiles.length; i++) {
          if (this.uploadFiles[i].size > 20000000) {
            this.$store.dispatch("showNotification", {
              title: "Lütfen Daha Küçük Boyutlu Bir Dosya Yükleyiniz",
              description: `Dosya Adı: ${this.uploadFiles[i].name}`,
              type: "warning",
            });
            this.remove(i);
          }
        }
      }
    },
    remove(i) {
      if (this.updateModal) {
        this.uploadFilesUpdate.splice(i, 1);
        this.file.path = this.deletedFile.path;
        this.file.name = this.deletedFile.name;
      } else {
        this.uploadFiles.splice(i, 1);
      }
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-gray-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-gray-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-gray-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileUpload.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-gray-300");
    },
    async getFileUrl() {
      for (let i = 0; i < this.uploadFiles.length; i++) {
        const formData = new FormData();
        formData.append("files", this.uploadFiles[i]);
        formData.append("name", this.uploadFiles[i].name);
        formData.append("type", "documents");
        formData.append("pid", JSON.parse(localStorage.getItem("user")).id);
        let response = await new FilesService().getFileUrl(formData);
        this.uploadFiles[i].path = response.result[0].file_path;
        let res = await new DocumentsService().addFile(this.uploadFiles[i]);
        if (res.status === 201) {
          this.$store.dispatch("showNotification", {
            title: "İşlem başarıyla gerçekleşti",
            description: "",
            type: "success",
          });
        }
      }
      await this.getFiles();
      this.uploadFiles = [];
    },
    async getFileTypes() {
      let role =
        localStorage.getItem("role_name") === "customer"
          ? "roles:2"
          : "roles:3";
      let response = await new DocumentsService().getFileTypes(role);
      this.fileTypes = response.result.data;
      this.selectedFileType = this.fileTypes[0];
    },
    async getFiles() {
      let response = await new DocumentsService().getFiles("", "", "all");
      this.files = response.result.data;
    },
    async getSingleFile(id) {
      let response = await new DocumentsService().getFiles("", id);
      this.file = response.result;
      this.selectedFileType = this.file.type;
      this.file.id = id;
      this.updateModal = true;
    },
    async editFile() {
      const formData = new FormData();
      let file = this.uploadFilesUpdate.length
        ? this.uploadFilesUpdate[0]
        : this.file.path;
      if (this.file.path !== file) {
        formData.append("files", file);
        formData.append("type", "documents");
        formData.append("name", file.name);
        formData.append("pid", JSON.parse(localStorage.getItem("user")).id);
        let get_url = await new FilesService().getFileUrl(formData);
        this.file = {
          id: this.file.id,
          path: get_url.result[0].file_path,
          name: this.file.name,
          type_id: this.selectedFileType.id,
          description: this.file.description,
        };
        await new DocumentsService().editFile(this.file, "", this.file.id);
        this.updateModal = false;
        await this.getFiles();
      } else {
        this.file.type_id = this.selectedFileType.id;
        await new DocumentsService().editFile(this.file, "", this.file.id);
        this.updateModal = false;
        await this.getFiles();
      }
      this.file = {};
    },
    setDeleteModal(id) {
      this.fileId = id;
      this.deleteModal = true;
    },
    async deleteFile() {
      await new DocumentsService().deleteFile("", this.fileId);

      this.deleteModal = false;
      await this.getFiles();
    },
    async downloadFile(file_id, account_id, label) {
      await new DocumentsService().fileDownload("", file_id, account_id, label);
    },
    async filterFiles() {
      let start_date = "";
      let finish_date = "";
      if (this.date) {
        var start_dd = String(this.date.start.getDate()).padStart(2, "0");
        var start_mm = String(this.date.start.getMonth() + 1).padStart(2, "0"); //January is 0!
        var start_yyyy = this.date.start.getFullYear();
        var finish_dd = String(this.date.end.getDate()).padStart(2, "0");
        var finish_mm = String(this.date.end.getMonth() + 1).padStart(2, "0"); //January is 0!
        var finish_yyyy = this.date.end.getFullYear();
        start_date = start_yyyy + "-" + start_mm + "-" + start_dd;
        finish_date = finish_yyyy + "-" + finish_mm + "-" + finish_dd;
      }
      let response = await new DocumentsService().filterFiles(
        start_date,
        finish_date,
        this.selectedFileType,
        this.search,
        this.selected.value
      );
      this.files = response.result.data;
    },
    changeFileName(event, f) {
      var element = this.$refs.fileUpload;
      var file = element.files[f];
      var blob = file.slice(0, file.size, "image/png");
      let newFile = new File([blob], event.target.value, {
        type: "image/png",
      });
      this.uploadFiles[f] = newFile;
    },
    // async getInfo(id) {
    //   if ((id === 0 || id === undefined) && localStorage.getItem("role_name") === "customer") {
    //     id = localStorage.getItem("account") === "{}" ? await this.$router.push({name: "Şirket Durumu",params:{lang:this.$store.state.lang}}) : JSON.parse(localStorage.getItem("account")).id;
    //   } else {
    //     let response = await new AccountsService().getAccount(id);
    //     if (response.status === 200 && response.result.informations.length > 0) {
    //       if (localStorage.getItem("type") === "1") {
    //         this.step = response.result.informations.find(
    //             (element) => element.key === "step"
    //         );
    //         this.index = this.step !== undefined ? this.steps.findIndex(
    //             (element) => element.slug === this.step.value
    //         ) : -1;
    //       }
    //     } else {
    //       if (localStorage.getItem("type") === "1") {
    //         await this.$router.push({name: "Şirket Durumu",params:{lang:this.$store.state.lang}})
    //       }
    //     }
    //   }
    // },
    async getUsersProducts(id) {
      if (localStorage.getItem("role_name") === "customer") {
        if (localStorage.getItem("type") === "1") {
          let response = await new AccountsService().getAccountsProducts(
            id,
            "filters=brands:1"
          );
          if (response.result.data.length !== 0) {
            this.packetsMusavirim = response.result.data;
            this.show = 1;
          } else {
            this.show = 0;
          }
        }
      } else {
        this.show = 1;
      }
    },
    // async getUserInfo(id) {
    //   if (localStorage.getItem("role_name") === "customer") {
    //     let response = await new AccountsService().getAccount(id);
    //     this.advisor = response.result.users.find((user) => user.roles[0].name === "advisor") !== undefined ? response.result.users.find((user) => user.roles[0].name === "advisor") : {
    //       name: '',
    //       phone: 0
    //     };
    //     if (localStorage.getItem("type") === "1") {
    //       if (this.advisor.name === '' || this.packetsMusavirim.length === 0) {
    //         if (this.index !== -1) {
    //           this.packetsShow = 0
    //           let id = this.index === 6 ? this.index : this.index + 1;
    //           await this.$router.push(this.steps[id].to);
    //           this.showAdvisor = false;
    //         } else {
    //           await this.$router.push({name: "Şirket Durumu",params:{lang:this.$store.state.lang}})
    //         }
    //       } else {
    //         this.packetsShow = 1
    //         this.showAdvisor = true;
    //       }
    //     }
    //   }
    // },
    formatedDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  async created() {
    if (localStorage.getItem("account")) {
      await this.getUsersProducts(
        JSON.parse(localStorage.getItem("account")).id
      );
      await this.getFileTypes();
      await this.getFiles();
    }

    for (let i = 0; i < this.$store.state.steps.length; i++) {
      this.$store.state.steps[i].status = "upcoming";
    }
  },
  computed: {
    watchModal() {
      return this.updateModal;
    },
  },
  watch: {
    watchModal(val) {
      if (!val) {
        this.updateModal = false;
        this.uploadFilesUpdate = [];
        this.file = {};
      }
    },
  },
};
</script>
<style>
.dp__input_icon_pad {
  padding-left: 12px !important;
}

.bg-gray {
  background: #f9f9f9;
  border: 0px;
  box-shadow: 0 0 0 transparent;
}

.text-gray {
  color: #1f2a37;
}
.p-datepicker-today {
  padding: 0;
}
.p-datepicker-header {
  padding: 0 !important;
}
.p-datepicker {
  font-size: 0.875rem;
  width: auto !important;
  min-width: 100px !important;
}
.p-datepicker table td > span {
  width: auto !important;
  height: auto !important;
}
.p-datepicker table td {
  padding: 0 !important;
}
.special-day {
  text-decoration: line-through;
}
.p-datepicker .p-timepicker span {
  font-size: 0.875rem;
}
@media screen and (max-width: 760px) {
  .filter-input {
    width: 100%;
  }

  .filter-button {
    width: 100%;
  }

  .custom-section {
    width: 100%;
  }

  .custom-div {
    padding: 0px 10px;
  }

  @media only screen and (max-width: 600px) {
    .documents-container {
      padding: 20px;
    }
  }
}
</style>
