import axios from "axios";
import authHeader from "./auth-header";

class CityDistrictService {
  getSectors() {
    return axios
      .get(`sectors`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  getCities() {
    return axios
      .get(`provinces`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  getDistricts(id) {
    return axios
      .get(`provinces/${id}/districts`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default CityDistrictService;
