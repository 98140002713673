<template>
  <main>
    <div
      v-show="packetsShow !== 0"
      class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100"
    >
      <div class="grid grid-cols-3">
        <div class="col-span-2 flex-col">
          <div v-if="advisor.name !== ''" class="py-6 sm:px-6 lg:px-8">
            <ul
              role="list"
              class="musavir-content w60 border border-gray-300 rounded-lg"
            >
              <li
                class="col-span-1 bg-white rounded-lg divide-y divide-gray-200"
              >
                <div class="flex items-center justify-between p-6 space-x-6">
                  <div class="flex-1 truncate">
                    <div class="items-center space-x-3">
                      <span
                        class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full"
                      >
                        MALİ MÜŞAVİR</span
                      >
                      <h3
                        class="text-gray-900 text-lg font-large truncate mt-3 advisor-name"
                      >
                        {{ advisor.name }}
                      </h3>
                    </div>
                    <div class="mt-1 text-gray-500 text-sm truncate">
                      <p class="small-text">Telefon : {{ advisor.phone }}</p>
                      <p class="small-text">Email : {{ advisor.email }}</p>
                    </div>
                  </div>
                </div>
                <div class="-mt-px flex divide-x divide-gray-200">
                  <div class="w-0 flex-1 flex">
                    <button
                      @click="appointmentRequest(advisor.id)"
                      class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center text-sm bg-indigo-500 text-white font-medium border border-transparent rounded-bl-lg"
                    >
                      <VideoCameraIcon class="w-5 h-5" aria-hidden="true" />
                      <span class="ml-3">Benimle İletişime Geçin</span>
                    </button>
                  </div>
                  <div class="w-0 flex-1 flex">
                    <a
                      :href="`mailto:${advisor.email}`"
                      :disabled="!advisor.email"
                      :class="[!advisor.email ? 'cursor-not-allowed' : '']"
                      class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                    >
                      <MailIcon
                        class="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span class="ml-3">Email Gönder</span>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            <div class="py-6 sm:px-6 lg:px-8">
              <ul
                role="list"
                class="musavir-content w60 border border-gray-300 rounded-lg"
              >
                <li
                  class="col-span-1 bg-white rounded-lg divide-y divide-gray-200"
                >
                  <div class="flex items-center justify-between p-6 space-x-6">
                    <div class="flex-1 truncate">
                      <div class="items-center space-x-3">
                        <h3
                          class="text-center text-gray-900 text-lg font-large truncate mt-3 advisor-name"
                        >
                          {{ $t("message.warningAdvisorText") }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="grid grid-cols-2" v-if="officePackets.length > 0">
            <div
              v-if="virtual_office"
              class="flex-col justify-center align-center text-center py-6 sm:px-6 lg:px-8"
            >
              <ul
                role="list"
                class="musavir-content w60 border border-gray-300 rounded-lg"
              >
                <li
                  class="col-span-1 bg-white rounded-lg divide-y divide-gray-200"
                >
                  <div class="flex items-center justify-between p-6 space-x-6">
                    <div class="flex-1 truncate">
                      <div class="items-center space-x-3">
                        <span
                          class="flex-shrink-0 inline-block px-2 py-0.5 text-red-800 text-xs font-medium bg-red-100 rounded-full"
                        >
                          SANAL OFIS</span
                        >
                        <h3
                          class="text-gray-900 text-lg font-large truncate mt-3 advisor-name"
                        >
                          {{ virtual_office.name }}
                        </h3>
                      </div>
                      <div class="mt-1 text-gray-500 text-sm truncate">
                        <p class="small-text">
                          Ücret : Aylık {{ virtual_office.price }} ₺
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div
              v-if="secretary.name !== ''"
              class="flex-col justify-center align-center text-left py-6 sm:px-6 lg:px-8"
            >
              <ul
                role="list"
                class="musavir-content w60 border border-gray-300 rounded-lg"
              >
                <li
                  class="col-span-1 bg-white rounded-lg divide-y divide-gray-200"
                >
                  <div class="flex items-center justify-between p-6 space-x-6">
                    <div class="flex-1 truncate">
                      <div class="items-center space-x-3">
                        <span
                          class="flex-shrink-0 inline-block px-2 py-0.5 text-yellow-800 text-xs font-medium bg-yellow-100 rounded-full"
                        >
                          SEKRETER</span
                        >
                        <h3
                          class="text-gray-900 text-lg font-large truncate mt-3 advisor-name"
                        >
                          {{ secretary.name }}
                        </h3>
                      </div>
                      <div class="mt-1 text-gray-500 text-sm truncate">
                        <p class="small-text">
                          Telefon :
                          {{ secretary.phone }}
                        </p>
                        <p class="small-text">Email : {{ secretary.email }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="-mt-px flex divide-x divide-gray-200">
                    <div class="w-0 flex-1 flex">
                      <a
                        :href="`tel:${secretary.phone}`"
                        target="_blank"
                        :disabled="!secretary.phone"
                        :class="[!secretary.phone ? 'cursor-not-allowed' : '']"
                        class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center text-sm bg-indigo-500 text-white font-medium border border-transparent rounded-bl-lg"
                      >
                        <PhoneIcon class="w-5 h-5" aria-hidden="true" />
                        <span class="ml-3">Ara</span>
                      </a>
                    </div>
                    <div class="w-0 flex-1 flex">
                      <a
                        :href="`mailto:${secretary.email}`"
                        :disabled="!secretary.email"
                        :class="[!secretary.email ? 'cursor-not-allowed' : '']"
                        class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                      >
                        <MailIcon
                          class="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span class="ml-3">Email Gönder</span>
                      </a>
                    </div>
                    <!--                <div class="-ml-px w-0 flex-1 flex">-->
                    <!--                  <a-->
                    <!--                      class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">-->
                    <!--                    <PhoneIcon class="w-5 h-5 text-gray-400" aria-hidden="true"/>-->
                    <!--                    <span class="ml-3">Randevu Al</span>-->
                    <!--                  </a>-->
                    <!--                </div>-->
                  </div>
                </li>
              </ul>
            </div>
            <div
              v-else
              class="flex-col justify-center align-center text-center py-6 sm:px-6 lg:px-8"
            >
              <ul
                role="list"
                class="musavir-content w60 border border-gray-300 rounded-lg"
              >
                <li
                  class="col-span-1 bg-white rounded-lg divide-y divide-gray-200"
                >
                  <div class="flex items-center justify-between p-6 space-x-6">
                    <div class="flex-1 truncate">
                      <div class="items-center space-x-3">
                        <h3
                          class="text-gray-900 text-lg font-large truncate mt-3 advisor-name"
                        >
                          Hesabınıza atanan bir <br />
                          sekreter bulunmamaktadır
                        </h3>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="flex-col">
          <div>
            <div class="py-6 sm:px-3">
              <div
                class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
              >
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="header-card px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("message.announcement") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="announcements.length"
                    class="bg-white divide-y divide-gray-200"
                  >
                    <tr
                      v-for="announcement in announcements"
                      :key="announcement.email"
                      class="bg-white"
                    >
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                      >
                        {{ announcement.title }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else class="bg-white divide-y divide-gray-200">
                    <tr>
                      <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                          <div class="ml-4">
                            <div class="text-sm font-medium text-gray-900">
                              {{ $t("message.noResultFound") }}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="px-2">
            <div class="flex-col shadow rounded-lg">
              <div
                class="header-card px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
              >
                {{ $t("message.notes") }}
              </div>
              <div
                class="flex-col py-6 px-5 space-y-5 space-y-3 filter-content"
              >
                <div class="flex items-center filter-date-cont">
                  <div class="">
                    <label for="">{{ $t("message.startDate") }}</label>
                    <Calendar
                      id="date"
                      v-model="date.start"
                      class="flex-grow w-full border-gray-300"
                      dateFormat="dd-mm-yy"
                      :max-date="maxDate"
                      autoComplete="off"
                      locale="tr"
                      @input="filterNotes"
                    >
                    </Calendar>
                  </div>
                  <div class="">
                    <label for="">{{ $t("message.endDate") }}</label>
                    <Calendar
                      id="date"
                      v-model="date.end"
                      class="flex-grow w-full border-gray-300"
                      dateFormat="dd-mm-yy"
                      :max-date="maxDate"
                      autoComplete="off"
                      locale="tr"
                      @input="filterNotes"
                    >
                    </Calendar>
                  </div>
                </div>
                <div class="flex justify-end">
                  <button
                    @click="filterNotes"
                    type="button"
                    class="w-20 h-8 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1 bg-indigo-600 text-base text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    {{ $t("message.filter") }}
                  </button>
                </div>
              </div>
              <div
                class="flex-col py-6 px-5 space-y-5 add-note-button"
                v-if="addNoteShow == false"
                @click="addNoteShow = true"
              >
                + {{ $t("message.addNew") }}
              </div>
              <div
                class="flex-col py-6 px-5 space-y-4 space-y-3"
                v-if="addNoteShow == true"
              >
                <div>
                  <textarea
                    @input="updateValue"
                    :value="note.content"
                    placeholder="Notunuzu giriniz"
                    type="text"
                    name="name-on-card"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <div class="text-sm text-gray-400 text-right">
                    {{ note.content.length }}/140
                  </div>
                </div>

                <div class="flex justify-end">
                  <button
                    @click="addNote"
                    type="button"
                    class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    Ekle
                  </button>
                </div>
              </div>
              <div class="py-6 px-5 space-y-5 border-top-line">
                <ul
                  role="list"
                  class="grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1"
                >
                  <li
                    v-for="note in notes"
                    :key="note.id"
                    class="col-span-1 bg-white rounded-lg bg-pink"
                  >
                    <div
                      class="w-full flex items-center justify-between p-6 space-x-6"
                    >
                      <div class="flex-1 flex flex-col gap-1">
                        <span class="text-gray-900 text-sm font-medium">
                          {{ note.content }}
                        </span>
                        <span class="text-xs text-gray-500">
                          {{ formatedDate(note.creation_date) }}</span
                        >
                      </div>
                      <div class="flex space-x-3">
                        <button @click="getNotes(note.id)">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="gray"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                          </svg>
                        </button>
                        <button @click="setDeleteModal(note.id)">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="gray"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div></div>
                  </li>
                </ul>
                <div v-if="notes.length > 3" class="flex justify-center">
                  <button class="text-indigo-600 text-sm">Tümünü gör</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TransitionRoot as="template" :show="deleteModal">
        <Dialog
          as="div"
          class="fixed z-10 inset-0 overflow-y-auto"
          @close="deleteNote = false"
        >
          <div
            class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in duration-200"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              />
            </TransitionChild>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span
              class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
              >&#8203;</span
            >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              >
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click="deleteModal = false"
                  >
                    <span class="sr-only">Close</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <div
                    class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <ExclamationIcon
                      class="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle
                      as="h3"
                      class="text-lg leading-6 font-medium text-gray-900"
                    >
                      UYARI</DialogTitle
                    >
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Notu silmek istediğinize emin misiniz?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    @click="deleteNote"
                  >
                    Evet
                  </button>
                  <button
                    type="button"
                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="deleteModal = false"
                  >
                    Hayır
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
      <TransitionRoot as="template" :show="updateModal">
        <Dialog
          as="div"
          class="fixed z-10 inset-0 overflow-y-auto"
          @close="updateModal = false"
        >
          <div
            class="flex items-end justify-center min-h-screen text-center sm:block sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in duration-200"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              />
            </TransitionChild>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span
              class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
              >&#8203;</span
            >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              >
                <div class="flex-grow">
                  <div class="grid grid-cols-12 gap-3 flex-grow">
                    <div class="col-span-12 sm:col-span-12">
                      <label
                        class="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Not
                      </label>
                      <textarea
                        v-model="noteUpdate.content"
                        type="text"
                        name="city"
                        class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                >
                  <button
                    type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    @click="editNote"
                  >
                    Kaydet
                  </button>
                  <button
                    type="button"
                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    @click="updateModal = false"
                    ref="cancelButtonRef"
                  >
                    Kapat
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </main>
</template>

<script>
import { MailIcon, VideoCameraIcon, PhoneIcon } from "@heroicons/vue/solid";
import AccountsService from "../../services/accounts.service";
import AnnouncementService from "@/services/announcements.service";
import ProductsService from "@/services/products.service";
import NoteService from "@/services/note.service";
import moment from "moment";

import { ExclamationIcon, XIcon } from "@heroicons/vue/outline";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
  DialogTitle,
} from "@headlessui/vue";
import AppointmentService from "@/services/appointment.service";

export default {
  name: "Dashboard",
  components: {
    MailIcon,
    VideoCameraIcon,
    PhoneIcon,
    // DatePicker,
    Dialog,
    DialogOverlay,
    TransitionRoot,
    TransitionChild,
    DialogTitle,
    ExclamationIcon,
    XIcon,
  },
  setup() {
    return {};
  },
  data() {
    return {
      addNoteShow: false,
      packetsShow: 0,
      packetsMusavirim: [],
      showAdvisor: false,
      advisor: { phone: 0, name: "" },
      officePackets: [],
      secretary: { phone: 0, name: "" },
      packets: [],
      announcements: [],
      announcement: {},
      virtual_office: "",
      note_id: 0,
      deleteModal: false,
      masks: {
        input: "DD-MM-YYYY",
      },
      date: {
        start: null,
        end: null,
      },
      updateModal: false,
      notes: [],
      note: { content: "" },
      noteUpdate: {},
    };
  },
  methods: {
    formatedDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    async getInfo(id) {
      if (
        (id === 0 || id === undefined) &&
        localStorage.getItem("role_name") === "customer"
      ) {
        id =
          localStorage.getItem("account") === "{}"
            ? await this.$router.push({
                name: "Şirket Durumu",
                params: { lang: this.$store.state.lang },
              })
            : JSON.parse(localStorage.getItem("account")).id;
      } else {
        let response = await new AccountsService().getAccount(id);
        this.virtual_office =
          response.result.informations.find(
            (element) => element.key === "virtual_office_id"
          ) !== undefined
            ? response.result.informations.find(
                (element) => element.key === "virtual_office_id"
              )
            : { key: "virtual_office_id", value: "-" };
        await this.getVirtualOffice();
      }
    },
    async getUsersProducts(id) {
      if (
        (id === 0 || id === undefined) &&
        localStorage.getItem("role_name") === "customer"
      ) {
        id =
          localStorage.getItem("account") === "{}"
            ? await this.$router.push({
                name: "Şirket Durumu",
                params: { lang: this.$store.state.lang },
              })
            : JSON.parse(localStorage.getItem("account")).id;
      } else {
        let all_products = await new AccountsService().getAccountsProducts(id);
        let office_products = await new AccountsService().getAccountsProducts(
          id,
          "filters=brands:2"
        );
        let advisor_products = await new AccountsService().getAccountsProducts(
          id,
          "filters=brands:1"
        );
        this.packets = all_products.result.data;
        this.packetsMusavirim = advisor_products.result.data.length > 0 ? 1 : 0;
        this.officePackets = office_products.result.data.length > 0 ? 1 : 0;

        this.packetsShow = 1;
      }
    },
    async getUserInfo(id) {
      if (
        (id === 0 || id === undefined) &&
        localStorage.getItem("role_name") === "customer"
      ) {
        id =
          localStorage.getItem("account") === "{}"
            ? await this.$router.push({
                name: "Şirket Durumu",
                params: { lang: this.$store.state.lang },
              })
            : JSON.parse(localStorage.getItem("account")).id;
      } else {
        let response = await new AccountsService().getAccount(id);
        this.advisor =
          response.result.users.find(
            (user) => user.roles[0].name === "advisor"
          ) !== undefined
            ? response.result.users.find(
                (user) => user.roles[0].name === "advisor"
              )
            : { name: "", phone: 0 };
        this.advisor.phone =
          this.advisor.phone !== 0
            ? this.advisor.phone.substring(2, 6) +
              this.advisor.phone.substring(6, 9) +
              this.advisor.phone.substring(9, 11) +
              this.advisor.phone.substring(11, 13)
            : 0;
        this.secretary =
          response.result.users.find(
            (user) => user.roles[0].name === "secretary"
          ) !== undefined
            ? response.result.users.find(
                (user) => user.roles[0].name === "secretary"
              )
            : { name: "", phone: 0 };
        this.secretary.phone =
          this.secretary.phone !== 0
            ? this.secretary.phone.substring(2, 6) +
              this.secretary.phone.substring(6, 9) +
              this.secretary.phone.substring(9, 11) +
              this.secretary.phone.substring(11, 13)
            : 0;
      }
    },
    async getAnnouncement(id) {
      if (id) {
        let response = await new AnnouncementService().getAnnouncements(id);
        this.announcement = response.result;
        this.tempannouncementUpdate = this.announcement;
      } else {
        let response = await new AnnouncementService().getAnnouncements();
        this.announcements = response.result.data;
      }
    },
    async getVirtualOffice() {
      if (this.virtual_office.value !== "-") {
        let response = await new ProductsService().getVirtualOffices(
          this.virtual_office.value
        );
        this.virtual_office = response.result;
      }
    },
    async addNote() {
      await new NoteService().addNote(this.note);
    },
    async editNote() {
      await new NoteService().editNote(this.noteUpdate, this.noteUpdate.id);
      await this.getNotes();
      this.updateModal = false;
    },
    async deleteNote() {
      await new NoteService().deleteNote(this.note_id);
      await this.getNotes();
      this.deleteModal = false;
    },
    async getNotes(id) {
      if (id) {
        let response = await new NoteService().getNote(id);
        this.updateModal = true;
        this.noteUpdate = response.result;
      } else {
        let response = await new NoteService().getNote();
        this.notes = response.result.data;
      }
    },
    setDeleteModal(id) {
      this.note_id = id;
      this.deleteModal = true;
    },
    async appointmentRequest(id) {
      let response = await new AppointmentService().addAppointment(
        JSON.parse(localStorage.getItem("user")).id,
        id
      );
      if (response.status === 201) {
        this.$store.dispatch("showNotification", {
          title: "Teşekkürler",
          description:
            "Randevu talebiniz oluşturuldu. En kısa sürede sizinle iletişime geçeceğiz",
          type: "success",
        });
      }
    },
    async filterNotes() {
      let start_date = "";
      let finish_date = "";
      var start_dd = String(this.date.start.getDate()).padStart(2, "0");
      var start_mm = String(this.date.start.getMonth() + 1).padStart(2, "0"); //January is 0!
      var start_yyyy = this.date.start.getFullYear();
      var finish_dd = String(this.date.end.getDate()).padStart(2, "0");
      var finish_mm = String(this.date.end.getMonth() + 1).padStart(2, "0"); //January is 0!
      var finish_yyyy = this.date.end.getFullYear();
      start_date = start_yyyy + "-" + start_mm + "-" + start_dd;
      finish_date = finish_yyyy + "-" + finish_mm + "-" + finish_dd;
      let response = await new NoteService().filterNotes(
        start_date,
        finish_date
      );
      this.notes = response.result.data;
    },
    updateValue(event) {
      const value = event.target.value;
      if (value.length <= 140) {
        this.note.content = value;
      }
      this.$forceUpdate();
    },
  },
  async created() {
    if (localStorage.getItem("role_name") !== "customer") {
      await this.$router.push({
        name: "AdvisorDashboard",
        params: { lang: this.$store.state.lang },
      });
    } else {
      await this.getInfo(JSON.parse(localStorage.getItem("account")).id);
      await this.getUsersProducts(
        JSON.parse(localStorage.getItem("account")).id
      );
      await this.getUserInfo(JSON.parse(localStorage.getItem("account")).id);
      await this.getAnnouncement();
      await this.getNotes();
    }
  },
  computed: {
    reloadPage() {
      return this.$store.state.account;
    },
    watchType() {
      return this.$store.state.type;
    },
  },
  watch: {
    async reloadPage(val) {
      await this.getUsersProducts(val.id);
      await this.getInfo(val.id);
      await this.getUserInfo(val.id);
    },
    async watchType() {
      let account = JSON.parse(localStorage.getItem("account"));
      await this.getUsersProducts(account.id);
      await this.getInfo(account.id);
      await this.getUserInfo(account.id);
    },
  },
};
</script>

<style scoped lang="scss">
.step-step-nav {
  border-bottom: 0px;
  box-shadow: 0px 11px 20px -11px #66666647;
}

.musavir-content {
  height: 100%;
  box-shadow: 0 6px 16px -3px #38383852;
  border: 0;
}

.disabled {
  opacity: 1;
  pointer-events: none;
}

.packet-name {
  font-size: 25px;
  padding-bottom: 15px;
  width: max-content;

  .underline {
    height: 2px;
    width: 70%;
    background: #4f46e5;
    margin-top: 6px;
  }
}

.packet-cards {
  box-shadow: 0px 18px 20px -10px #ddd;
  border: 1px solid #f3f3f3;
}

.header-card {
  background: #1f2a37;
  color: white;
  border-radius: 0.5rem 0.5rem 0 0;
}

.advisor-name {
  margin-left: 0px !important;
  text-transform: capitalize;
}

.small-text {
  font-size: 12px;
}
.filter-date-cont {
  gap: 10px;
}
.input-area {
  label {
    font-size: 14px;
    margin-bottom: 9px;
  }
}
.bg-pink {
  background: #eded;
}
.filter-content {
  background: #f5f4f4;
}
.border-top-line {
  border-top: 1px solid #ededed;
}
.add-note-button {
  text-align: right;
  font-weight: 700;
  color: #4f46e5;
  &:hover {
    cursor: pointer;
  }
}
.note-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media (max-width: 600px) {
  .musavir-content {
    position: absolute;
    bottom: 0;
    width: 100% !important;
    box-shadow: 0 6px 16px -3px #38383852;
    border: 0;
    padding: 0px 10px;
  }
}
</style>
