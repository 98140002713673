import axios from "axios";
import authHeader from "./auth-header";

class AdditionalService {
  addInfo(id, packet, step_name) {
    return axios
      .post(
        `accounts/${id}/informations`,
        {
          informations: [
            {
              key: step_name,
              value: packet.id,
            },
          ],
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default AdditionalService;
