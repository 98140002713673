import axios from "axios";
import authHeader from "./auth-header";

class PermissionsService {
  getRoles() {
    return axios
      .get(`roles`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  getPermissions() {
    return axios
      .get(`permissions`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  addRole(name) {
    return axios
      .post(
        `roles`,
        {
          name: name,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  destroyRole(id) {
    return axios
      .delete(`roles/${id}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  givePermissionToRole(id, data) {
    return axios
      .post(
        `roles/${id}/give-permission`,
        { data },
        id
          ? {
              headers: authHeader(),
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
  assignRoleToUser(id, name) {
    return axios
      .post(
        `users/${id}/assign-role?all=true`,
        {
          role_name: name,
          user: id,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default PermissionsService;
