import axios from "axios";
import authHeader from "./auth-header";
class SecretaryService {
  getAllSecretary() {
    const params = {
      search: "roles.role_id:4",
    };
    return axios
      .get(`users`, { headers: authHeader(), params })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  getSingleSecretary(id) {
    return axios
      .get(`users?all&filters=roles:4;ids:${id}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  assignSecretary(account) {
    return axios
      .post(
        `accounts/${account.id}/users/${account.secretary.id}/assign?user_id=${account.users[0].id}`,
        {},
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  addSecretary(secretary) {
    return axios
      .post(
        "users",
        {
          // language_id:1,
          name: secretary.name,
          phone: "+90" + secretary.phone.replace(/\s/g, ""),
          email: secretary.email,
          password: secretary.password,
          role_id: 4,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  deleteSecretary(id) {
    return axios
      .delete(`users/${id}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  addInfo(id) {
    return axios
      .post(
        `accounts/${id}/informations?all`,
        {
          informations: [
            {
              key: "office_step",
              value: "approved",
            },
          ],
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  editSecretary(secretary, id) {
    return axios
      .put(
        `users/${id}?user_id=${id}`,
        {
          name: secretary.name,
          phone: "+90" + secretary.phone.replace(/\s/g, ""),
          email: secretary.email,
          video_call_url: secretary.video_call_url,
          calendar_url: secretary.calendar_url,
          role_id: 4,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}
export default SecretaryService;
