<template>
  <div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        v-on:blur="emailValidations($event)"
        type="text"
        :required="required ? required : false"
        class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      />
      <div v-if="showError">
        <div
          class="mb-7 absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none"
        >
          <ExclamationCircleIcon
            class="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
        <p class="mt-2 text-sm text-red-600" id="email-error">
          E-Posta formatına uygun bir email giriniz
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

export default {
  components: {
    ExclamationCircleIcon,
  },
  props: {
    modelValue: String,
    required: Boolean,
  },

  data() {
    return {
      email: "",
      showError: false,
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },

  methods: {
    emailValidations() {
      return this.modelValue === ""
        ? ""
        : this.reg.test(this.modelValue)
        ? (this.showError = false)
        : (this.showError = true);
    },
  },
};
</script>
