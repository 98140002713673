<template>
  <main>
    <div
      class="flex justify-center bg-blue-100 rounded-lg p-3 align-center text-center"
    >
      <div class="text-center text-gray-800 mt-1">
        {{ $t("message.standText") }}
      </div>
      <div>
        <button
          @click="
            $router.push({
              name: 'EndlessNetworkForMyCompany',
              params: { lang: this.$store.state.lang },
            })
          "
          class="w-48 h-8 ml-3 inline-flex justify-center text-center px-4 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 align-center focus:ring-offset-2 focus:ring-blue-500"
        >
          {{ $t("message.listMyComp") }}
        </button>
      </div>
    </div>
    <div class="max-w-7xl mx-auto minh-100 mt-5">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="relative">
        <div class="inset-0 mb-10">
          <div class="bg-white h-1/3 sm:h-2/3">
            {{ $t("message.companies_description") }}
          </div>
        </div>

        <div class="relative max-w-7xl mx-auto flex-col mb-20">
          <div class="grid gap-5 grid-cols-4 mt-2">
            <div>
              <label class="block text-sm font-medium text-gray-700">
                {{ $t("message.sector") }}</label
              >
              <select
                id="sektor"
                name="location"
                class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option v-for="(sector, index) in sectors" :key="index">
                  {{ sector.name }}
                </option>
              </select>
            </div>
            <div>
              <label class="block text-sm font-medium text-gray-700">
                {{ $t("message.city") }}</label
              >
              <city-select-input
                @change="getDistricts(city)"
                :cities="cities"
                v-model="city"
              ></city-select-input>
            </div>
            <div>
              <label class="block text-sm font-medium text-gray-700">
                {{ $t("message.district") }}</label
              >

              <district-select-input
                :class="{ disabled: !city }"
                :districts="districts"
                v-model="district"
              ></district-select-input>
            </div>
            <div class="flex mt-4" style="align-items: flex-end">
              <button
                name="location"
                class="w-24 h-10 ml-3 inline-flex justify-center text-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {{ $t("message.filter") }}
              </button>
            </div>
          </div>
          <div
            class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none"
          >
            <div
              v-for="cmp in companies"
              :key="cmp.id"
              class="flex flex-col rounded-lg shadow-lg overflow-hidden"
              @click="
                this.$router.push({
                  name: 'EndlessNetworkStand',
                  params: { id: cmp.id, lang: this.$store.state.lang },
                })
              "
            >
              <div class="flex justify-between">
                <img
                  v-if="cmp.company_logo"
                  class=""
                  style="
                    width: 100%;
                    height: 150px;
                    object-fit: cover;
                    box-shadow: 0px -1px 10px 10px rgb(237 237 237 / 48%);
                    background: #ededed30;
                  "
                  :src="cmp.company_logo"
                  alt="torun"
                />
                <img
                  v-else
                  class=""
                  style="width: 100%; height: 150px; object-fit: cover"
                  src="/img/torun-center.jpeg"
                  alt="torun"
                />
              </div>
              <div class="flex flex-1 justify-between">
                <div class="m-4 flex-col justify-between flex w-full">
                  <div>
                    <p
                      v-if="cmp.company_name"
                      class="lg:text-base font-bold text-indigo-900 sm:text-xs text-center uc-nokta"
                    >
                      {{ cmp.company_name }}
                    </p>
                    <p
                      v-else
                      class="lg:text-base font-bold text-indigo-900 sm:text-xs text-center"
                    >
                      -
                    </p>
                  </div>
                  <div class="flex mr-5 px-2 w-full justify-center gap-3 mt-3">
                    <div
                      v-if="cmp.company_creation_date"
                      class="flex gap-x-2 align-centertext-gray-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="white"
                        viewBox="0 0 24 24"
                        stroke="#9ca3af"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                      <p class="text-sm font-regular text-gray-500">
                        {{ cmp.company_creationdate }}
                      </p>
                    </div>
                    <div v-if="cmp.contact_address" class="flex text-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="white"
                        viewBox="0 0 24 24"
                        stroke="#9ca3af"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      <p class="text-sm font-regular text-gray-500">
                        {{ cmp.contact_address }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="flex-1 bg-white px-3 pb-6 flex flex-col border-t border-gray-300"
              >
                <div class="">
                  <a href="#" class="block mt-2">
                    <div class="flex text-indigo-700 gap-x-2 align-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#9ca3af"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                      <span
                        v-if="cmp.company_details"
                        class="text-base text-gray-500 font-size"
                      >
                        {{ cmp.company_details }}
                      </span>
                      <span v-else class="text-base text-gray-500 font-size"
                        >-</span
                      >
                    </div>
                    <div class="mt-3 flex text-indigo-700 gap-x-2 align-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#9ca3af"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                      </svg>
                      <p
                        v-if="cmp.company_employees"
                        class="text-base text-gray-500 font-size"
                      >
                        {{ cmp.company_employees }}
                      </p>
                      <p v-else class="text-base text-gray-500 font-size">-</p>
                    </div>
                    <div class="mt-3 flex text-indigo-700 gap-x-2 align-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#9ca3af"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                        />
                      </svg>
                      <p
                        v-if="cmp.contact_email"
                        class="text-base text-gray-500 font-size"
                      >
                        {{ cmp.contact_email }}
                      </p>
                      <p v-else class="text-base text-gray-500 font-size">-</p>
                    </div>
                    <div class="mt-3 flex text-indigo-700 gap-x-2 align-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#9ca3af"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                        />
                      </svg>
                      <p
                        v-if="cmp.contact_phone"
                        class="text-base text-gray-500 font-size"
                      >
                        {{ cmp.contact_phone }}
                      </p>
                      <p v-else class="text-base text-gray-500 font-size">-</p>
                    </div>
                  </a>
                </div>
                <div class="" v-if="this.productType">
                  <router-link
                    to=""
                    class="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >
                    Standa Git
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import CityDistrictService from "@/services/city-district.service";
import CompaniesService from "@/services/companies.service";
import CitySelectInput from "@/views/Inputs/CitySelectInput";
import DistrictSelectInput from "@/views/Inputs/DistrictSelectInput";
import AccountsService from "@/services/accounts.service";

export default {
  components: {
    CitySelectInput,
    DistrictSelectInput,
  },
  methods: {
    async getSectors() {
      let response = await new CityDistrictService().getSectors();
      this.sectors = response.result.data;
    },
    async getCities() {
      let response = await new CityDistrictService().getCities();
      this.cities = response.result.data;
    },
    async getDistricts(id) {
      let response = await new CityDistrictService().getDistricts(id);
      this.districts = response.result.data;
    },
    async getCompanies() {
      let response = await new CompaniesService().getCompanies();
      this.companies = response.result.data;
    },
    async getAccount() {
      let response = await new AccountsService().getAccount(
        JSON.parse(localStorage.getItem("account")).id
      );
      this.accounts = response.result;
      if (this.accounts.products.enb.length > 0) {
        const premiumType =
          this.accounts.products.enb[0].name
            .toLowerCase()
            .includes("premium") ||
          this.accounts.products.enb[0].slug.toLowerCase().includes("premium");
        this.productType = premiumType;
      }
      if (this.accounts.products.length > 0) {
        this.products = this.accounts.products.filter(
          (packet) =>
            packet.categories.length > 0 &&
            packet.categories[0].name == "Company"
        );
      }
    },
  },
  data() {
    return {
      locationsTemp: [
        {
          id: 1,
          name: "TORUN CENTER",
          desc: "İstanbul",
          address:
            "Fulya Mahallesi Büyükdere Caddesi No:74/D Torun Center Kat:3 Ofis:10 34394 Şişli / İstanbul",
          src: "/img/torun-center.jpeg",
        },
      ],
      sectors: [],
      cities: [],
      companies: [],
      districts: [],
      products: [],
      city: {},
      accounts: {},
      district: {},
      productType: false,
    };
  },
  async created() {
    await this.getSectors();
    await this.getCompanies();
    await this.getCities();
    await this.getAccount();
  },
};
</script>

<style scoped>
.price-txt-size {
  font-size: 2rem !important;
}

.sticker-size {
  font-size: 0.675rem !important;
}

.small-tx {
  font-size: 0.9rem !important;
}

.packed-shadow {
  box-shadow: 1px 6px 17px 0px #cfcfcf;
}

.started-price-info {
  font-size: 13px;
  line-height: 19px;
  color: #6d6d6d;
  font-weight: 300;
}

.font-size {
  font-size: 15px;
}

.title {
  font-weight: 600;
}

.price {
  font-weight: 800;
  font-size: 27px;
  margin-top: 18px;
  line-height: 10px;
}

.text-line {
  font-weight: 300 !important;
  font-size: 0.8rem !important;
}
.align-center {
  align-items: center;
}
.padding-btm {
  padding-bottom: 0px !important;
}
.uc-nokta {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
