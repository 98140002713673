<template>
  <main>
    <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100">
      <section aria-labelledby="applicant-information-title">
        <div v-if="informations" class="bg-white">
          <!-- Personel Info -->
          <div class="border-gray-200 shadow sm:rounded-lg">
            <div v-show="account_user" class="title-content px-4 py-5 sm:px-6">
              <h2
                id="applicant-information-title"
                class="text-lg leading-6 font-medium text-gray-900"
              >
                {{ account_user.name }}
              </h2>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                {{ account_user.phone }} - {{ account_user.email }}
              </p>
            </div>
            <div v-if="account" class="px-4 py-5 sm:px-6">
              <h2 class="mb-6 text-lg leading-6 font-medium text-gray-900">
                Kişisel Bilgiler
              </h2>
              <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div class="sm:col-span-1">
                  <dt class="text-sm font-medium text-gray-500">Firma Adı</dt>
                  <dd
                    v-if="account.name !== ''"
                    class="mt-1 text-sm text-gray-900"
                  >
                    {{ account.name }}
                  </dd>
                  <dd v-else class="mt-1 text-sm text-gray-900">-</dd>
                </div>
                <div class="sm:col-span-1">
                  <dt class="text-sm font-medium text-gray-500">Adresi</dt>
                  <dd
                    v-if="informations.neighbourhood"
                    class="mt-1 text-sm text-gray-900"
                  >
                    {{ informations.neighbourhood }}
                    {{ informations.street }}
                    {{ informations.buildingNo }}
                    {{ informations.doorNo }}
                    {{ informations.district.name }} /
                    {{ informations.city.name }}
                  </dd>
                  <dd v-else class="mt-1 text-sm text-gray-900">-</dd>
                </div>
                <div class="sm:col-span-1">
                  <dt class="text-sm font-medium text-gray-500">
                    TC Kimlik no
                  </dt>
                  <dd
                    v-if="informations.identityNo"
                    class="mt-1 text-sm text-gray-900"
                  >
                    {{ informations.identityNo }}
                  </dd>
                  <dd v-else class="mt-1 text-sm text-gray-900">-</dd>
                </div>
                <div class="sm:col-span-1">
                  <dt class="text-sm font-medium text-gray-500">Telefon</dt>
                  <dd
                    v-if="informations.phone"
                    class="mt-1 text-sm text-gray-900"
                  >
                    {{ informations.phone }}
                  </dd>
                  <dd v-else class="mt-1 text-sm text-gray-900">-</dd>
                </div>
                <div class="sm:col-span-1">
                  <dt class="text-sm font-medium text-gray-500">
                    Doğum Tarihi
                  </dt>
                  <dd
                    v-if="informations.birthDate"
                    class="mt-1 text-sm text-gray-900"
                  >
                    {{ informations.birthDate }}
                  </dd>
                  <dd v-else class="mt-1 text-sm text-gray-900">-</dd>
                </div>
                <div v-if="informations.birthCity" class="sm:col-span-1">
                  <dt class="text-sm font-medium text-gray-500">Doğum Yeri</dt>
                  <dd
                    v-if="informations.birthCity.name !== ''"
                    class="mt-1 text-sm text-gray-900"
                  >
                    {{ informations.birthDist.name }} /
                    {{ informations.birthCity.name }}
                  </dd>
                  <dd v-else class="mt-1 text-sm text-gray-900">-</dd>
                </div>
              </dl>
            </div>
          </div>
          <!-- Address Info -->

          <div
            v-if="informations.address_option.id !== 0"
            class="border-gray-200 mt-6 shadow sm:rounded-lg"
          >
            <div class="title-content px-4 py-5 sm:px-6">
              <h2 class="text-lg leading-6 font-medium text-gray-900">
                Adres Bilgileri
              </h2>
            </div>
            <div class="px-4 py-5 sm:px-6">
              <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div class="sm:col-span-1">
                  <dt
                    v-if="informations.address_option.id !== 0"
                    class="text-sm font-medium text-gray-500"
                  >
                    Adres Türü
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ informations.address_option.name }}
                  </dd>
                </div>
                <div v-if="informations.address_option.id == '1'">
                  <div
                    v-if="informations.rental_amount_brut !== '-'"
                    class="sm:col-span-1"
                  >
                    <dt class="text-sm font-medium text-gray-500">
                      Kira Tutarı (Brüt)
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ informations.rental_amount_brut }}
                    </dd>
                  </div>
                  <div
                    v-if="informations.rental_amount_net !== '-'"
                    class="sm:col-span-1"
                  >
                    <dt class="text-sm font-medium text-gray-500">
                      Kira Tutarı (Net)
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ informations.rental_amount_net }}
                    </dd>
                  </div>
                  <div
                    v-if="informations.rental_period !== '-'"
                    class="sm:col-span-1"
                  >
                    <dt class="text-sm font-medium text-gray-500">
                      Kira Dönemi
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ informations.rental_period }}
                    </dd>
                  </div>
                  <div
                    v-if="informations.currency !== '-'"
                    class="sm:col-span-1"
                  >
                    <dt class="text-sm font-medium text-gray-500">
                      Para Birimi
                    </dt>
                    <dd
                      v-if="informations.currency"
                      class="mt-1 text-sm text-gray-900"
                    >
                      <span>{{ informations.currency.name }}</span>
                    </dd>
                  </div>
                </div>
                <div
                  v-if="informations.virtual_office_selection === '1'"
                  class="sm:col-span-1"
                >
                  <dt class="text-sm font-medium text-gray-500">Sanal Ofis</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ informations.virtual_office_id.name }}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <!-- Files -->
          <div
            v-if="informations.files.length > 0"
            class="mt-6 shadow sm:rounded-lg border-gray-200 sm:col-span-2"
          >
            <div class="title-content px-4 py-5 sm:px-6">
              <h2 class="text-lg leading-6 font-medium text-gray-900">
                Dosyalar
              </h2>
            </div>
            <div class="px-4 py-5 sm:px-6">
              <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div
                    class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                  >
                    <div class="overflow-hidden sm:rounded-lg">
                      <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-white">
                          <tr>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Adı
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Açıklama
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Türü
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Gelen / Giden
                            </th>
                            <th scope="col" class="relative px-6 py-3">
                              <span class="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="files.length > 0">
                          <tr v-for="file in files" :key="file">
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                            >
                              {{ file.name }}
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              {{ file.description }}
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              {{ file.type.name }}
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              <span v-if="user_id == file.user_id"> Giden</span>
                              <span v-else>Gelen</span>
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                            >
                              <a
                                @click="
                                  downloadFile(
                                    file.id,
                                    file.account_id,
                                    file.name
                                  )
                                "
                                href="#"
                                class="font-medium text-blue-600 hover:text-blue-500"
                              >
                                İndir
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import AccountsService from "../../services/accounts.service";
import CityDistrictService from "../../services/city-district.service";
import DocumentsService from "../../services/documents.service";
import ProductsService from "../../services/products.service";

export default {
  name: "Dashboard",
  components: {},
  setup() {
    return {};
  },
  data() {
    return {
      steps: [
        {
          id: "1",
          name: this.$t("message.companySituation"),
          to: "/app/application-form",
          status: "current",
          visible: false,
          slug: "status",
        },
        {
          id: "2",
          name: this.$t("message.packets"),
          to: "/app/application-form/packets",
          status: "upcoming",
          visible: true,
          slug: "packets",
        },
        {
          id: "3",
          name: this.$t("message.personalInfo"),
          to: "/app/application-form/personal-info",
          status: "upcoming",
          visible: true,
          slug: "personal-info",
        },
        {
          id: "4",
          name: this.$t("message.addressInfo"),
          to: "/app/application-form/business-info",
          status: "upcoming",
          visible: true,
          slug: "business-info",
        },
        {
          id: "5",
          name: this.$t("message.files"),
          to: "/app/application-form/files",
          status: "upcoming",
          visible: true,
          slug: "files",
        },
        {
          id: "6",
          name: this.$t("message.payment"),
          to: "/app/application-form/payment",
          status: "upcoming",
          visible: true,
          slug: "payment",
        },
        {
          id: "7",
          name: this.$t("message.approve"),
          to: "/app/application-form/wait-for-approve",
          status: "upcoming",
          visible: true,
          slug: "wait-for-approve",
        },
      ],
      step: {},
      stepShow: false,
      packets: [],
      showAdvisor: false,
      selected: JSON.parse(localStorage.getItem("account"))
        ? JSON.parse(localStorage.getItem("account"))
        : {},
      index: "",
      advisor: {},
      account: {},
      account_user: [],
      informations: {
        birthDist: { name: "", id: 0 },
        birthCity: { name: "", id: 0 },
        city: { name: "", id: 0 },
        district: { name: "", id: 0 },
        address_option: { name: "", id: 0 },
        virtual_office_id: { name: "", id: 0 },
        currency: { name: "", id: 0 },
        files: [],
      },
      user_id: JSON.parse(localStorage.getItem("user")).id,
      files: [],
    };
  },
  methods: {
    async getAccountInfo(val) {
      this.informations = {
        birthDist: { name: "", id: 0 },
        birthCity: { name: "", id: 0 },
        city: { name: "", id: 0 },
        district: { name: "", id: 0 },
        address_option: { name: "", id: 0 },
        virtual_office_id: { name: "", id: 0 },
        currency: { name: "", id: 0 },
        files: [],
      };
      let response = await new AccountsService().getAccount(val.id);
      this.account = response.result;
      this.account_user = this.account.users[0];
      this.account_user.phone =
        this.account_user.phone.substring(2, 6) +
        " " +
        this.account_user.phone.substring(6, 9) +
        " " +
        this.account_user.phone.substring(9, 11) +
        " " +
        this.account_user.phone.substring(11, 13);
      for (let i = 0; i < response.result.informations.length; i++) {
        this.informations[response.result.informations[i].key] =
          response.result.informations[i].value;
      }
      await this.getCity();
      this.informations.address_option =
        this.informations.address_option.name !== ""
          ? JSON.parse(this.informations.address_option)
          : { name: "", id: 0 };

      if (this.informations.virtual_office_id !== "-") {
        this.informations.virtual_office_selection == "1"
          ? JSON.parse(this.informations.virtual_office_id)
          : "0";
      }
      if (
        this.informations.virtual_office_id !== "0" &&
        this.informations.virtual_office_id !== "-"
      ) {
        let res = await new ProductsService().getVirtualOffices(
          this.informations.virtual_office_id.id
        );
        this.informations.virtual_office_id = res.result;
      }

      this.informations.currency =
        this.informations.currency.name !== "" &&
        this.informations.currency.name !== "-"
          ? JSON.parse(JSON.stringify(this.informations.currency))
          : { name: "", id: 0 };
    },
    async getCity() {
      let response = await new CityDistrictService().getCities();
      this.informations.city = response.result.data.find(
        (city) => city.id == this.informations.city
      );
      this.informations.birthCity = response.result.data.find(
        (city) => city.id == this.informations.birthCity
      );
      await this.getDist();
    },
    async getDist() {
      let dist =
        this.informations.city !== undefined
          ? await new CityDistrictService().getDistricts(
              this.informations.city.id
            )
          : [];
      let birth_dist =
        this.informations.birthCity !== undefined
          ? await new CityDistrictService().getDistricts(
              this.informations.birthCity.id
            )
          : [];
      this.informations.district =
        dist.length !== 0 &&
        dist.result.data.find((city) => city.id == this.informations.district);
      this.informations.birthDist =
        birth_dist.length !== 0 &&
        birth_dist.result.data.find(
          (city) => city.id == this.informations.birthDist
        );
    },
    async getAccountsFile() {
      this.informations.files = [];
      let response = await new DocumentsService().getFiles("", "", "all");
      this.informations.files = response.result.data;
      this.files = response.result.data;
    },
    async downloadFile(file_id, account_id, label) {
      await new DocumentsService().fileDownload(
        "",
        file_id,
        account_id,
        label,
        "all"
      );
    },
  },
  async created() {
    let account = JSON.parse(localStorage.getItem("account"));
    if (account) {
      await this.getAccountInfo(account);
      await this.getAccountsFile();
    }
  },
  computed: {
    reloadPage() {
      return this.$store.state.account;
    },
  },
  watch: {
    async reloadPage(val) {
      await this.getAccountInfo(val);
      await this.getAccountsFile();
    },
  },
};
</script>

<style scoped lang="scss">
.step-step-nav {
  border-bottom: 0px;
  box-shadow: 0px 11px 20px -11px #66666647;
}

.app-content {
  min-height: 50vh;
  position: relative;
}

.musavir-content {
  position: absolute;
  width: 100%;
  bottom: 0px;
  width: calc(100% - 64px);
}

.disabled {
  opacity: 1;
  pointer-events: none;
}

.packet-name {
  font-size: 25px;
  padding-bottom: 15px;
  width: max-content;

  .underline {
    height: 2px;
    width: 70%;
    background: #4f46e5;
    margin-top: 6px;
  }
}

.packet-cards {
  box-shadow: 0px 18px 20px -10px #ddd;
  border: 1px solid #f3f3f3;
}
.title-content {
  background: #f3f3f3;
}
.file-line {
  border: 0;
  border-radius: 0px !important;
  border-bottom: 1px solid #ededed !important;
  &:last-child {
    border-bottom: 0px !important;
  }
}
</style>
