<template>
  <div
    class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-12 w-auto"
        src="../../../public/img/siyah.png"
        alt="Workflow"
      />
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md register-content">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <h2 class="text-center text-3xl font-extrabold text-gray-900">
          {{ $t("message.register") }}
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          {{ $t("message.haveAccount") }}
          {{ " " }}
          <a
            :href="`/${$store.state.lang}/app/login`"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {{ $t("message.login") }}
          </a>
        </p>
        <form class="space-y-6">
          <div>
            <label class="block text-sm font-medium text-gray-700">
              {{ $t("message.name") }} {{ $t("message.surname") }}
            </label>
            <div class="mt-1">
              <text-input v-model="user.name"></text-input>
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">
              {{ $t("message.phone") }}
            </label>
            <div class="mt-1">
              <phone-number-input v-model="user.phone" :phone="phone" />
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">
              E-Posta
            </label>
            <div class="mt-1">
              <email-input v-model="user.email"></email-input>
            </div>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700">
              {{ $t("message.password") }}
            </label>
            <div class="mt-1">
              <password-input v-model="user.password"></password-input>
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">
              {{ $t("message.passwordConfirmation") }}
            </label>
            <div class="mt-1">
              <password-input v-model="passwordRetype"></password-input>
            </div>
          </div>
        </form>
        <div class="py-2">
          <button
            @click="register"
            class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {{ $t("message.register") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PasswordInput from "../Inputs/PasswordInput";
import AuthService from "../../services/new/auth";
import EmailInput from "../Inputs/EmailInput";
import PhoneNumberInput from "../Inputs/PhoneNumberInput";
import TextInput from "../Inputs/TextInput";

export default {
  components: {
    TextInput,
    PhoneNumberInput,
    EmailInput,
    PasswordInput,
  },
  data() {
    return {
      user: {},
      passwordRetype: "",
      showError: false,
      showSuccess: false,
    };
  },
  methods: {
    async register(e) {
      e.preventDefault();
      // this.user.phone = this.user.phone.replace(/\s/g, '');
      // var patt = new RegExp("(05|5)[0-9][0-9][0-9]([0-9]){6}");
      // var res = patt.test(this.user.phone);
      if (
        this.user.password === this.passwordRetype &&
        this.user.password !== "" &&
        this.user.password.length >= 6
      ) {
        await new AuthService().registerUser(this.user).then(() => {
          this.$router.push({
            name: "VerifyPhoneNumber",
            params: { lang: this.$store.state.lang },
          });
          this.user = {};
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.space-y-6 {
  margin-top: 30px;
}
@media only screen and (max-width: 940px) {
  .register-content {
    padding: 0 15px 0 15px;
  }
}
</style>
