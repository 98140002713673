import axios from "axios";
import authHeader from "./auth-header";
class AdvisorService {
  getAllAdvisor() {
    const params = {
      search: "roles.role_id:3",
    };
    return axios
      .get(`users`, { headers: authHeader(), params })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  getSingleAdvisor(id) {
    return axios
      .get(`users?all&filters=roles:3;ids:${id}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  assignAdvisor(account) {
    return axios
      .post(
        `accounts/${account.id}/users/${account.advisor.id}/assign?user_id=${account.users[0].id}`,
        {},
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  addAdvisor(advisor) {
    return axios
      .post(
        "users",
        {
          // language_id:1,
          name: advisor.name,
          phone: "+90" + advisor.phone.replace(/\s/g, ""),
          email: advisor.email,
          password: advisor.password,
          video_call_url: advisor.video_call_url,
          calendar_url: advisor.calendar_url,
          // password_confirmation: advisor.password,
          role_id: 3,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  deleteAdvisor(id) {
    return axios
      .delete(`users/${id}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  addInfo(id, link) {
    return axios
      .post(
        `accounts/${id}/informations?all`,
        {
          informations: [
            {
              key: "step",
              value: "approved",
            },
            {
              key: "iframe_link",
              value: link !== "" ? link : "-",
            },
          ],
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  editAdvisor(advisor, id) {
    return axios
      .put(
        `users/${id}?user_id=${id}`,
        {
          name: advisor.name,
          phone: "+90" + advisor.phone.replace(/\s/g, ""),
          email: advisor.email,
          video_call_url: advisor.video_call_url,
          calendar_url: advisor.calendar_url,
          role_id: 3,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}
export default AdvisorService;
