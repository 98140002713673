<template>
  <div v-if="checkPermission('show accounts')" class="lg:col-span-9">
    <div class="flex flex-col px-3 m-3">
      <div class="flex justify-between mb-4 space-x-5">
        <button
          @click="addModal = true"
          class="py-2 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {{ $t("message.addNew") }}
        </button>
        <div class="flex space-x-2">
          <input
            v-model="account.name"
            type="text"
            name="company-name"
            class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          />
          <button
            @click="filterAccount"
            class="py-2 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {{ $t("message.search") }}
          </button>
        </div>
      </div>
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ $t("message.name") }}
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">{{ $t("message.actions") }}</span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="accounts.length">
                <tr
                  v-for="account in accounts"
                  :key="account.email"
                  :class="
                    this.$store.state.account.id === account.id
                      ? 'bg-gray-300'
                      : 'bg-white'
                  "
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ account.name }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <router-link
                      v-show="checkPermission('update accounts')"
                      :to="{
                        name: 'CompanyEdit',
                        params: { id: account.id, lang: $store.state.lang },
                      }"
                      class="mr-3 text-indigo-600 hover:text-indigo-900"
                      >Düzenle
                    </router-link>
                    <a
                      v-show="checkPermission('destroy accounts')"
                      @click="setDeleteModal(account.id)"
                      href="#"
                      class="text-indigo-600 hover:text-indigo-900"
                      >Sil</a
                    >
                    <a
                      @click="setActiveAccount(account)"
                      href="#"
                      class="ml-3 text-indigo-600 hover:text-indigo-900"
                      >Seç</a
                    >
                  </td>
                </tr>
              </tbody>
              <tbody v-else class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ $t("message.noResultFound") }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="lg:col-span-9">
    <!-- Profile section -->
    <div class="flex justify-center items-center py-12">
      <div
        class="bg-gray flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="uppercase text-lg text-center">
          <div>
            {{ $t("message.dontHavePermission") }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="addModal"
    :clickToClose="false"
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
        <div class="mt-3 text-center sm:mt-5">
          <div class="mt-2">
            <div class="flex-grow">
              <div class="mt-6 grid grid-cols-12 gap-3 flex-grow">
                <div class="col-span-12 sm:col-span-12">
                  <h2
                    for="lastname"
                    class="text-center text-xl font-extrabold text-gray-900"
                  >
                    Şirket İsmi
                  </h2>
                  <small v-if="accounts.length === 0" class="text-gray-500">
                    Şirketinizin bir ismi olmadan devam edemezsiniz
                  </small>
                  <input
                    v-model="account.name"
                    type="text"
                    name="company-name"
                    class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="accounts.length > 0"
          class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
        >
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            @click="addAccount"
          >
            Kaydet
          </button>
          <button
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            @click="addModal = false"
            ref="cancelButtonRef"
          >
            {{ $t("message.close") }}
          </button>
        </div>
        <div v-show="accounts.length === 0" class="mt-5">
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            @click="addAccount"
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  </div>
  <TransitionRoot as="template" :show="deleteModal">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="deleteModal = false"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div>
              <div class="mt-3 text-center sm:mt-5">
                <div class="mt-2">
                  <div class="flex-grow">
                    <div class="mt-6 grid grid-cols-12 gap-3 flex-grow">
                      <div class="col-span-12 sm:col-span-12">
                        <label class="block text-sm font-medium text-gray-700">
                          Şirketinizin hesabını silmek istediğinize emin misiniz
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                @click="deleteCompany"
              >
                Sil
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                @click="deleteModal = false"
                ref="cancelButtonRef"
              >
                {{ $t("message.close") }}
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import AccountsService from "../../../services/accounts.service";
import { Mixin } from "../../../library/utils";

export default {
  mixins: [Mixin],
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  data() {
    return {
      accounts: [],
      deleteModal: false,
      accountId: 0,
      account: { name: "" },
      updateModal: false,
      addModal: false,
      active: false,
    };
  },
  methods: {
    async deleteCompany() {
      await new AccountsService().deleteAccount("", this.accountId);
      this.deleteModal = false;
      await this.getAccount();
    },
    async getAccount(id) {
      this.accountId = id;
      if (id) {
        let response = await new AccountsService().getAccount(id);
        this.account = response.result;
        this.updateModal = true;
      } else {
        let response = await new AccountsService().getAccounts();
        this.accounts = response.result.data;
        if (
          this.accounts.length === 0 &&
          localStorage.getItem("role_name") !== "advisor" &&
          localStorage.getItem("role_name") !== "secretary" &&
          localStorage.getItem("role_name") !== "admin"
        ) {
          this.addModal = true;
        }
        this.$store.state.accounts = this.accounts;
        let tempAcc = this.accounts.filter(
          (account) =>
            this.account.name !== "" && account.name === this.account.name
        );
        this.$store.state.account =
          tempAcc.length > 0 ? tempAcc[0] : this.accounts[0];
        localStorage.setItem(
          "account",
          JSON.stringify(this.$store.state.account)
        );
        localStorage.setItem("accounts", JSON.stringify(this.accounts));
      }
    },
    async addAccount() {
      if (
        this.account.name !== this.tempName &&
        this.account.name.length <= 60
      ) {
        let response = await new AccountsService().addAccount(
          this.account.name
        );
        if (response.status === 201) {
          this.addModal = false;
          await this.getAccount();
          this.account = "";
          await this.$router.push({
            name: "Şirket Durumu",
            params: { lang: this.$store.state.lang },
          });
        }
      }
    },
    setActiveAccount(account) {
      this.$store.state.account = account;
      this.active = true;
    },
    setDeleteModal(id) {
      this.accountId = id;
      this.deleteModal = true;
    },
    async filterAccount() {
      let response = await new AccountsService().getAccount(
        "",
        "",
        `?search=name:${this.account.name}`
      );
      this.accounts = response.result.data;
      this.account.name = "";
    },
  },
  async created() {
    if (this.checkPermission("show accounts")) {
      await this.getAccount();
    }
  },
};
</script>
<style lang="scss">
.bg-gray {
  background: #f9f9f9;
  border: 0px;
  box-shadow: 0 0 0 transparent;
}
</style>
