import axios from "axios";
import authHeader from "./auth-header";

class ShipmentService {
  getShipments(id) {
    return axios
      .get(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/shipment-tracking${id ? `/${id}` : ""}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  addShipment(shipment) {
    return axios
      .post(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/shipment-tracking`,
        {
          company_name: shipment.company_name,
          sender_name: shipment.sender_name,
          sender_phone: shipment.sender_phone,
          checked_out: shipment.checked_out,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  editShipment(shipment, id) {
    return axios
      .put(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/shipment-tracking/${id}`,
        {
          company_name: shipment.company_name,
          sender_name: shipment.sender_name,
          sender_phone: shipment.sender_phone,
          checked_out: shipment.checked_out,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  deleteShipment(id) {
    return axios
      .delete(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/shipment-tracking/${id}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}

export default ShipmentService;
