<template>
  <div>
    <div className="mt-1 relative rounded-md shadow-sm">
      <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        v-on:keypress="textValidations($event)"
        type="text"
        class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    modelValue: String,
  },
  methods: {
    textValidations(e) {
      if (!e.key.match(/^[aA-zZüöçğşıİÜĞÇŞÖ\s]+$/)) {
        e.preventDefault();
      }
    },
  },
};
</script>
