<template>
  <div>
    <select
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      id="location"
      name="location"
      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      :disabled="disabled"
    >
      <option
        v-for="district in districts"
        :key="district.id"
        :value="district.id"
      >
        {{ district.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "DistrictSelectInput",
  props: {
    modelValue: String,
    districts: Array,
    // eslint-disable-next-line vue/require-prop-type-constructor
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
