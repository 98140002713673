<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <main>
    <div
      v-if="show > 0"
      class="additional-container max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100"
    >
      <!-- Replace with your content -->
      <div class="bg-white">
        <div
          class="mt-8 text-xl font-bold text-gray-800 packet-name"
          data-v-12b9f82b=""
        >
          Ek Hizmetler
          <div class="underline" data-v-12b9f82b=""></div>
        </div>
        <div class="max-w-7xl mx-auto">
          <div v-for="category in categories" :key="category.id">
            <div class="mt-10 mb-5 font-bold text-xl">{{ category.name }}</div>
            <div
              class="mt-2 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4"
            >
              <div
                v-for="packet in category.products"
                :key="packet.name"
                class="extra-card rounded-lg shadow-sm divide-y divide-gray-200"
              >
                <div class="p-6 additional-card">
                  <h2 class="text-lg leading-6 font-medium text-gray-900">
                    {{ packet.name }}
                  </h2>
                  <p v-show="packet.price != 0" class="mt-8">
                    <span class="text-4xl font-extrabold text-gray-900"
                      >{{ packet.price }} ₺</span
                    >
                  </p>
                  <router-link
                    v-if="packet.type === 'subscription'"
                    @click="addInfo(packet)"
                    :to="`/${$store.state.lang}/app/additional-payment`"
                    class="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                    >{{ $t("message.buy") }}
                  </router-link>
                  <button
                    v-else
                    @click="getContact(packet)"
                    :class="packet.price === '0' ? 'price-button' : 'mt-8'"
                    class="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >
                    Talep Et
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /End replace -->
    </div>
    <div v-if="show === 0" class="flex justify-center items-center py-12">
      <div
        class="bg-gray flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="text-gray-600 text-lg text-center">
          <div>
            {{ $t("message.buyAPacketForShowThisPage") }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ProductsService from "../../../services/products.service";
import AdditionalService from "../../../services/additional.service";
import AccountsService from "../../../services/accounts.service";
import ContactService from "../../../services/contact.service";

export default {
  components: {},
  setup() {
    return {};
  },
  async created() {
    await this.getUsersProducts();
    await this.getCategories();
    await this.getPackets();
  },
  data() {
    return {
      packets: [],
      categories: [],
      financialPackets: [],
      lawPackets: [],
      digitalPackets: [],
      show: false,
      informations: {
        additional_services: [],
      },
      step: null,
    };
  },
  methods: {
    async getPackets() {
      let response = await new ProductsService().getProducts(
        "",
        "is_special:1"
      );
      this.packets = response.result;
    },
    async addInfo(packet) {
      await new AdditionalService().addInfo(
        JSON.parse(localStorage.getItem("account")).id,
        packet,
        "additional_services"
      );
    },
    async getUsersProducts() {
      if (localStorage.getItem("role_name") === "customer") {
        let response = await new AccountsService().getAccountsProducts(
          JSON.parse(localStorage.getItem("account")).id
        );
        this.show =
          localStorage.getItem("account") !== "{}"
            ? response.result.data.length
            : 0;
      } else {
        this.show = 1;
      }
    },
    async getCategories() {
      let response = await new ProductsService().getCategories();
      this.show = 1;
      this.categories = response.result.data.filter(
        (category) => category.name !== "Company" && category.name !== "Stage"
      );
    },
    async getContact(packet) {
      this.informations.additional_services.push({
        id: packet.id,
        name: packet.name,
      });

      // this.packets.additional_services = this.settings.find(
      //   (element) => element.id === this.accountInfo.address_option.id
      // );
      await new ContactService().addInfo(
        this.informations.additional_services,
        JSON.parse(localStorage.getItem("account")).id
      );
      await new ContactService().getContact(packet.id);
    },
  },
};
</script>
<style lang="scss">
.bg-gray {
  background: #f9f9f9;
  border: 0px;
  box-shadow: 0 0 0 transparent;
}
.additional-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 200px;
}
.text-gray {
  color: #1f2a37;
}
.extra-card {
  box-shadow: 0px 18px 20px -10px #ddd;
  border: 1px solid #f3f3f3;
}
.price-button {
  margin-top: 6.5rem;
}
.packet-name {
  font-size: 25px;
  padding-bottom: 15px;
  width: max-content;
  .underline {
    height: 2px;
    width: 70%;
    background: #4f46e5;
    margin-top: 6px;
  }
}
@media only screen and (max-width: 600px) {
  .additional-container {
    padding: 20px;
  }
}
</style>
