<template>
  <div class="flex justify-center shadow-md">
    <div
      class="align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
    >
      <div>
        <div
          class="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-green-100"
        >
          <CheckIcon class="h-10 w-10 text-green-600" aria-hidden="true" />
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <DialogTitle
            as="h3"
            class="text-lg leading-6 font-medium text-gray-900"
          >
            Şirket kurulumu tamamlandı
          </DialogTitle>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Şirket kurulumu başarıyla tamamlandı
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-6">
        <router-link
          to="/app"
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
        >
          Anasayfa
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { CheckIcon } from "@heroicons/vue/outline";

const settings = [
  {
    id: 1,
    name: "Kiracıyım",
    description: "This project would be available to anyone who has the link",
  },
  {
    id: 2,
    name: "İşyeri mülkiyeti kendime ait",
    description: "Only members of this project would be able to access",
  },
  {
    id: 3,
    name: "Sanal ofis sözleşmem var",
    description: "You are the only one able to access this project",
  },
  {
    id: 4,
    name: "Faaliyet adresim yok",
    description: "You are the only one able to access this project",
  },
];

export default {
  components: {
    CheckIcon,
  },
  setup() {
    const selected = ref(settings[0]);
    const open = ref(true);

    return {
      settings,
      selected,
      open,
    };
  },
  methods: {
    setStatus() {
      this.$store.state.steps[7].status = "current";
      this.$store.state.steps[6].status = "complete";
    },
  },
  created() {
    this.setStatus();
  },
};
</script>
