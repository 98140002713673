import axios from "axios";
import authHeader from "./auth-header";

class NoteService {
  addNote(note) {
    return axios
      .post(`notes`, { content: note.content }, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  editNote(note, id) {
    return axios
      .put(`notes/${id}`, { content: note.content }, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  deleteNote(id) {
    return axios
      .delete(`notes/${id}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  getNote(id) {
    return axios
      .get(`notes${id ? `/${id}` : ""}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  filterNotes(start_date, finish_date) {
    return axios
      .get(`notes?filters=created_at:${start_date},${finish_date}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default NoteService;
