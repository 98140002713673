import axios from "axios";
import authHeader from "./auth-header";

class StageService {
  getStages() {
    return axios
      .get(`enb/stages`, { headers: authHeader() })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return error.response;
      });
  }
  addInfo(stage, id, speakers) {
    return axios
      .post(
        `accounts/${id}/informations`,
        {
          informations: [
            {
              key: "stage_name",
              value: stage.name,
            },
            {
              key: "stage_description",
              value: stage.description,
            },
            {
              key: "stage_start_date",
              value: stage.start_date,
            },
            {
              key: "stage_end_date",
              value: stage.end_date,
            },
            {
              key: "stage_speakers",
              value: speakers,
            },
            {
              key: "stage_packet",
              value: stage.packet,
            },
          ],
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  addPayment(card, stage, product) {
    return axios.post(
      `enb/buy-stage`,
      {
        stages: {
          name: stage.stage_name,
          description: stage.stage_description,
          start_date: stage.stage_start_date,
          end_date: stage.stage_end_date,
          moderators: stage.stage_speakers,
          speakers: stage.stage_speakers,
        },
        cards: {
          card_number: card.cardNumber,
          card_expire_month: parseInt(card.expiration.substring(0, 2)),
          card_expire_year: parseInt(card.expiration.substring(3, 5)),
          card_cvc: parseInt(card.cvc),
          card_holder_name: card.name,
        },
        account_id: JSON.parse(localStorage.getItem("account")).id,
        products: {
          product_id: product,
          brand_id: 7,
        },
      },
      { headers: authHeader() }
    );
  }
}

export default StageService;
