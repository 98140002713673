<template>
  <div
    v-if="checkPermission('show secretaries')"
    class="advisors-container max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100"
  >
    <div class="flex justify-end mb-4">
      <button
        @click="clearModal"
        class="py-2 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Sekreter Ekle
      </button>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    İsim Soyisim
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Telefon
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="secretaries.length"
                class="bg-white divide-y divide-gray-200"
              >
                <tr v-for="(secretary, key) in secretaries" :key="key">
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ secretary.name }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ secretary.phone.substring(2, 6) }}
                    {{ secretary.phone.substring(6, 9) }}
                    {{ secretary.phone.substring(9, 11) }}
                    {{ secretary.phone.substring(11, 13) }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ secretary.email }}
                  </td>

                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <a
                      v-if="checkPermission('update users')"
                      @click="getSingleSecretary(secretary.id)"
                      class="mr-5 text-indigo-600 hover:text-indigo-900"
                      >Düzenle</a
                    >
                    <a
                      v-if="checkPermission('destroy users')"
                      @click="setDeleteModal(secretary.id)"
                      class="text-indigo-600 hover:text-indigo-900"
                      >Sil</a
                    >
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
              <tbody v-else class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          Listelenecek Sekreter Bulunmamaktadır.
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="lg:col-span-9">
    <!-- Profile section -->
    <div class="flex justify-center items-center py-12">
      <div
        class="flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="text-white uppercase text-lg text-center">
          <div>BU SAYFAYI GÖRÜNTÜLEMEK İÇİN YETKİNİZ BULUNMAMAKTADIR.</div>
        </div>
      </div>
    </div>
  </div>
  <TransitionRoot as="template" :show="addModal">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="addModal = false"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="text-center">
              <h3>SEKRETER DÜZENLEME</h3>
              <small class="text-gray-500"
                >Yeni bir sekreter eklemek için lütfen aşağıdaki bilgileri
                doldurunuz</small
              >
            </div>
            <div>
              <div class="mt-3 sm:mt-5">
                <div class="mt-2">
                  <div class="flex-grow">
                    <div class="mt-6 grid grid-cols-12 gap-3 flex-grow">
                      <div class="col-span-12 sm:col-span-12">
                        <label
                          class="block mb-2 text-sm font-medium text-gray-700"
                        >
                          İsim Soyisim
                        </label>
                        <input
                          v-model="secretary.name"
                          type="text"
                          name="city"
                          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div class="col-span-12 sm:col-span-12">
                        <label
                          class="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Telefon
                        </label>
                        <phone-number-input
                          :phone="phone"
                          v-model="secretary.phone"
                        ></phone-number-input>
                      </div>
                      <div class="col-span-12 sm:col-span-12">
                        <label
                          class="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <email-input v-model="secretary.email"></email-input>
                      </div>
                      <div class="col-span-12 sm:col-span-12">
                        <label
                          class="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Şifre
                        </label>
                        <password-input
                          v-model="secretary.password"
                        ></password-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                @click="addSecretary"
              >
                Kaydet
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                @click="addModal = false"
                ref="cancelButtonRef"
              >
                Kapat
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <TransitionRoot as="template" :show="updateModal">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="updateModal = false"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="text-center">
              <h3>SEKRETER DÜZENLEME</h3>
              <small class="text-gray-500"
                >Yeni bir sekreter eklemek için lütfen aşağıdaki bilgileri
                doldurunuz</small
              >
            </div>
            <div>
              <div class="mt-3 sm:mt-5">
                <div class="mt-2">
                  <div class="flex-grow">
                    <div class="mt-6 grid grid-cols-12 gap-3 flex-grow">
                      <div class="col-span-12 sm:col-span-12">
                        <label
                          class="block mb-2 text-sm font-medium text-gray-700"
                        >
                          İsim Soyisim
                        </label>
                        <input
                          v-model="secretary.name"
                          type="text"
                          name="city"
                          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div class="col-span-12 sm:col-span-12">
                        <label
                          class="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Telefon
                        </label>
                        <phone-number-input
                          :phone="phone"
                          v-model="secretary.phone"
                        ></phone-number-input>
                      </div>
                      <div class="col-span-12 sm:col-span-12">
                        <label
                          class="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <email-input v-model="secretary.email"></email-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                @click="editSecretaryInfo"
              >
                Kaydet
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                @click="updateModal = false"
                ref="cancelButtonRef"
              >
                Kapat
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <TransitionRoot as="template" :show="deleteModal">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="deleteModal = false"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="flex justify-center items-center">
              <div
                class="flex justify-center items-center w-full overflow-hidden py-2 sm:px-6 lg:px-8"
              >
                <div class="text-lg text-center">
                  <div>Sekreter hesabını silmek istediğinize emin misiniz?</div>
                </div>
              </div>
            </div>

            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                @click="deleteSecretary"
              >
                Sil
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                @click="deleteModal = false"
                ref="cancelButtonRef"
              >
                Kapat
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { Mixin } from "../../library/utils";
import SecretaryService from "../../services/secretary.service";
import EmailInput from "../Inputs/EmailInput";
import PhoneNumberInput from "../Inputs/PhoneNumberInput";
import PasswordInput from "../Inputs/PasswordInput";

export default {
  mixins: [Mixin],
  components: {
    PasswordInput,
    PhoneNumberInput,
    EmailInput,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  setup() {},
  data() {
    return {
      accounts: [],
      deleteModal: false,
      accountId: 0,
      account: {},
      updateModal: false,
      addModal: false,
      secretaries: [],
      accountCheck: "",
      secretary: {},
      secretaryId: 0,
    };
  },
  methods: {
    async getAllSecretary() {
      let response = await new SecretaryService().getAllSecretary();
      this.secretaries = response.result.data;
    },
    async editSecretaryInfo() {
      await new SecretaryService()
        .editSecretary(this.secretary, this.secretary.id)
        .then(() => {
          this.getAllSecretary();
          this.updateModal = false;
        });
    },
    async getSingleSecretary(id) {
      this.updateModal = true;
      let response = await new SecretaryService().getSingleSecretary(id);
      this.secretary = response.result.data[0];
      this.secretary.phone = this.secretary.phone.substring(3, 13);
    },
    async addSecretary() {
      if (
        this.secretary.password !== "" &&
        this.secretary.password.length >= 6
      ) {
        await new SecretaryService().addSecretary(this.secretary);
        this.addModal = false;
        await this.getAllSecretary();
      }
    },
    setDeleteModal(id) {
      this.secretaryId = id;
      this.deleteModal = true;
    },
    async deleteSecretary() {
      await new SecretaryService().deleteSecretary(this.secretaryId);
      this.deleteModal = false;
      await this.getAllSecretary();
    },
    clearModal() {
      this.secretary = { email: "", password: "", name: "", phone: "" };
      this.addModal = true;
    },
  },

  async created() {
    if (this.checkPermission("show users")) {
      await this.getAllSecretary();
    }
  },
  computed: {
    watchSecretaryModals() {
      let arr = [this.addModal, this.updateModal];
      return arr;
    },
  },
  watch: {
    watchSecretaryModals(arr) {
      if (!arr[0] || !arr[1]) {
        this.secretary = { email: "", password: "", name: "", phone: "" };
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .advisors-container {
    padding: 20px;
  }
}
</style>
