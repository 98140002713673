import axios from "axios";
import authHeader from "./auth-header";

class CallsService {
  getCalls(id) {
    return axios
      .get(
        `accounts/${JSON.parse(localStorage.getItem("account")).id}/calls${
          id ? `/${id}` : ""
        }`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  addCall(call) {
    return axios
      .post(
        `accounts/${JSON.parse(localStorage.getItem("account")).id}/calls`,
        {
          company_name: call.company_name,
          caller: call.caller,
          phone: call.phone,
          note: call.note,
          call_date: call.call_date,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  editCall(call, id) {
    return axios
      .put(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/calls/${id}`,
        {
          company_name: call.company_name,
          caller: call.caller,
          phone: call.phone,
          note: call.note,
          call_date: call.call_date,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  deleteCall(id) {
    return axios
      .delete(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/calls/${id}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}

export default CallsService;
