<template>
  <img
    class="mt-8 mx-auto h-16 w-auto"
    src="../../../public/img/siyah.png"
    alt="Workflow"
  />
  <div class="flex justify-center mt-8 text-center py-12">
    <div
      class="flex-col items-center text-gray-500 bg-white p-12 rounded-md z-50"
    >
      <div>
        <img
          class="mx-auto h-20 w-auto"
          src="../../../public/img/checked.png"
          alt="Workflow"
        />
        <h3 class="mt-6 text-center text-4xl font-extrabold text-gray-900">
          TEŞEKKÜRLER
        </h3>
      </div>
      <div class="mt-3 text-center text-sm text-gray-600">
        <span
          >E-Posta adresiniz başarı ile onaylanmıştır. Giriş sayfasına
          yönlendiriliyorsunuz</span
        >
      </div>
    </div>
  </div>
  <div></div>
</template>
<script>
import UserService from "../../services/user.service";

export default {
  components: {},
  data() {
    return {
      email: "",
      success: false,
    };
  },
  methods: {
    async verifyEmail() {
      await new UserService().emailVerify(
        this.$route.params.id,
        this.$route.params.hash,
        this.$route.query.signature,
        this.$route.query.expires,
        this.$route.query.email
      );
      setTimeout(
        () =>
          this.$router.push({
            name: "Login",
            params: { lang: this.$store.state.lang },
          }),
        3000
      );
    },
  },
  async created() {
    await this.verifyEmail();
  },
};
</script>
