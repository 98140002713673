<template>
  <div
    v-if="checkPermission('show roles')"
    class="accounts-container max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100"
  >
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <div
              v-if="this.checkPermission('store roles')"
              class="flex justify-end mb-2"
            >
              <button
                @click="addRoleModal = true"
                type="text"
                name="city"
                class="focus:ring-gray-500 focus:border-gray-500 block sm:text-sm border-gray-800 bg-gray-900 hover:bg-gray-700 rounded-md text-white"
              >
                + Yeni Rol
              </button>
            </div>
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Role Adı
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="roles.length"
                class="bg-white divide-y divide-gray-200"
              >
                <tr v-for="(role, key) in roles" :key="key">
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ role.name }}
                  </td>

                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <button
                      v-if="checkPermission('destroy roles')"
                      @click="destroyRoles(role.id)"
                      class="text-indigo-600 hover:text-indigo-900 font-medium"
                    >
                      Sil
                    </button>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
              <tbody v-else class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          Listelenecek role bulunmamaktadır
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="lg:col-span-9">
    <!-- Profile section -->
    <div class="flex justify-center items-center py-12">
      <div
        class="flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="text-white uppercase text-lg text-center">
          <div>BU SAYFAYI GÖRÜNTÜLEMEK İÇİN YETKİNİZ BULUNMAMAKTADIR.</div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="addRoleModal"
    :clickToClose="false"
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
        <div class="text-center">
          <div>
            <div class="flex-grow">
              <div class="grid grid-cols-12 gap-3 flex-grow">
                <div class="col-span-12 sm:col-span-12">
                  <h3 class="text-center text-xl text-gray-900">Rol İsmi</h3>
                  <input
                    v-model="new_role"
                    type="text"
                    name="city"
                    class="mt-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 flex space-x-5">
          <button
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            @click="addRoleModal = false"
          >
            Kapat
          </button>
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            @click="addRole"
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Mixin } from "../../library/utils";
import PermissionsService from "@/services/permissions.service";

export default {
  mixins: [Mixin],
  components: {},
  data() {
    return {
      roles: [],
      addRoleModal: false,
      new_role: "",
    };
  },
  methods: {
    async getRoles() {
      let response = await new PermissionsService().getRoles();
      this.roles = response.result.data;
    },
    async addRole() {
      let response = await new PermissionsService().addRole(this.new_role);
      await this.getRoles();
      this.addRoleModal = false;
      if (response.status === 422) {
        this.$store.dispatch("showNotification", {
          title: "Hata",
          description: "Lütfen tüm alanları doldurduğunuzdan emin olun",
          type: "error",
        });
      }
    },
    async destroyRoles(id) {
      let response = await new PermissionsService().destroyRole(id);
      if (response.status === 422) {
        this.$store.dispatch("showNotification", {
          title: "Hata",
          description: "işleminiz gerçekleştirilemedi",
          type: "error",
        });
      } else {
        this.getRoles();
      }
    },
  },
  async created() {
    if (this.checkPermission("show roles")) {
      await this.getRoles();
    }
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .accounts-container {
    padding: 15px;
  }
}
</style>
