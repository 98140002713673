<template>
  <main class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100">
    <div>
      <div class="space-y-8 divide-y divide-gray-200">
        <form @submit.prevent="addInfo">
          <div>
            <div class="information-card">
              <div class="divide-gray-200">
                <h3 class="text-lg leading-6 font-bold text-gray-900">
                  {{ $t("message.firmInfo") }}
                </h3>
              </div>
              <div class="mt-6 grid grid-cols-3 place-content-center">
                <div>
                  <div class="sm:col-span-2">
                    <label class="block text-sm font-light text-gray-700">
                      Logo <span class="text-red-500">*</span>
                    </label>
                    <div class="mt-1">
                      <div class="img-content">
                        <div
                          class="w-full text-center img-drop"
                          @dragover="dragover"
                          @dragleave="dragleave"
                          @drop="drop"
                        >
                          <div>
                            <input
                              type="file"
                              name="fields[assetsFieldHandle][]"
                              id="assetsFieldHandle"
                              class="w-px h-px opacity-0 overflow-hidden absolute"
                              @change="onChange"
                              ref="file"
                              accept=".jpg,.jpeg,.png"
                            />
                            <label
                              for="assetsFieldHandle"
                              class="block cursor-pointer"
                            >
                              <div class="text-gray-600 img-pp-content">
                                <img
                                  class="profile-img rounded-full h-32 w-32"
                                  :src="info.company_logo"
                                  alt=""
                                />
                                <img
                                  class="img-hover-photo h-32 w-32"
                                  src="/img/photo-camera.png"
                                  alt="photo"
                                />
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-span-2">
                  <div
                    class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
                  >
                    <div class="sm:col-span-3">
                      <label class="block text-sm font-light text-gray-700">
                        {{ $t("message.companyName") }}
                        <span class="text-red-500">*</span>
                      </label>
                      <div class="mt-1">
                        <input
                          v-model="info.company_name"
                          type="text"
                          :disabled="true"
                          name="first-name"
                          autocomplete="off"
                          class="focus:ring-indigo-500 focus:border-indigo-500 cursor-not-allowed block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label class="block text-sm font-light text-gray-700">
                        {{ $t("message.companySector") }}
                        <span class="text-red-500">*</span>
                      </label>
                      <div class="mt-1">
                        <SectorSelectInput
                          :sectors="sectors"
                          v-model="info.company_sector"
                          :required="false"
                        >
                        </SectorSelectInput>
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label class="block text-sm font-light text-gray-700">
                        {{ $t("message.website") }}
                        <span class="text-red-500">*</span>
                      </label>
                      <div class="mt-1">
                        <div class="p-fluid grid formgrid focus:outline-none">
                          <div
                            class="field col-12 md:col-4 bg-white text-gray-700 w-full appearance-none rounded-md focus:outline-none mt-1"
                          >
                            <input
                              v-model="info.company_website"
                              type="text"
                              name="first-name"
                              autocomplete="off"
                              required
                              class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label class="block text-sm font-light text-gray-700">
                        {{ $t("message.slogan") }}
                        <span class="text-red-500">*</span>
                      </label>
                      <div class="mt-1">
                        <input
                          v-model="info.company_slogan"
                          type="text"
                          name="first-name"
                          autocomplete="off"
                          required
                          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
                  >
                    <div class="sm:col-span-3">
                      <label class="block text-sm font-light text-gray-700">
                        {{ $t("message.details") }}
                        <span class="text-red-500">*</span>
                      </label>
                      <div class="mt-1">
                        <textarea
                          v-model="info.company_details"
                          type="text"
                          name="first-name"
                          autocomplete="off"
                          required
                          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-5"></div>
            <div class="information-card">
              <div class="divide-gray-200">
                <h3 class="text-lg leading-6 font-bold text-gray-900">
                  {{ $t("message.contactInformation") }}
                </h3>
              </div>
              <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div class="sm:col-span-3">
                  <label class="block text-sm font-light text-gray-700">
                    {{ $t("message.contact_name") }}
                    <span class="text-red-500">*</span>
                  </label>
                  <div class="mt-1">
                    <input
                      v-model="info.contact_name"
                      type="text"
                      name="contact_name"
                      autocomplete="off"
                      required
                      class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label class="block text-sm font-light text-gray-700">
                    E-Mail <span class="text-red-500">*</span>
                  </label>
                  <div class="mt-1">
                    <email-input
                      v-model="info.contact_email"
                      :required="true"
                      class="lg:w-full xl:w-full md:w-5/6 sm:w-5/6"
                    ></email-input>
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label class="block text-sm font-light text-gray-700">
                    {{ $t("message.phone") }}
                    <span class="text-red-500">*</span>
                  </label>
                  <div class="mt-1">
                    <phone-number-input
                      :phone="phone"
                      v-model="info.contact_phone"
                      required
                    ></phone-number-input>
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label class="block text-sm font-light text-gray-700">
                    {{ $t("message.contact_address") }}
                    <span class="text-red-500">*</span>
                  </label>
                  <div class="mt-1">
                    <textarea
                      v-model="info.contact_address"
                      type="text"
                      name="contact_address"
                      autocomplete="off"
                      required
                      class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-5"></div>
            <div class="information-card">
              <div class="divide-gray-200">
                <h3 class="text-lg leading-6 font-bold text-gray-900">
                  {{ $t("message.representativeInfo") }}
                  <small class="text-gray-500 text-xs"
                    >({{ $t("message.representativeDesc") }})</small
                  >
                </h3>
              </div>
              <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div class="sm:col-span-3">
                  <label class="block text-sm font-light text-gray-700">
                    {{ $t("message.name_surname") }}
                    <span class="text-red-500">*</span>
                  </label>
                  <div class="mt-1">
                    <input
                      v-model="info.agent.agent_name"
                      type="text"
                      name="first-name"
                      autocomplete="off"
                      required
                      class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label class="block text-sm font-light text-gray-700">
                    {{ $t("message.email") }}<span class="text-red-500">*</span>
                  </label>
                  <div class="mt-1">
                    <email-input
                      v-model="info.agent.agent_email"
                      required
                      class="lg:w-full xl:w-full md:w-5/6 sm:w-5/6"
                    ></email-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-5"></div>
            <div
              class="packages mt-8 max-w-md mx-auto space-y-4 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0"
            >
              <div
                v-for="packet in packages"
                :key="packet.id"
                :class="
                  this.info.products != null &&
                  this.info.products.id == packet.id
                    ? 'border border-indigo-800'
                    : ''
                "
                class="flex flex-col rounded-lg overflow-hidden packed-shadow"
              >
                <div
                  class="px-6 py-8 flex flex-col justify-between bg-white sm:p-8 sm:pb-6 padding-btm h-full"
                >
                  <div>
                    <div>
                      <h3
                        class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600 sticker-size"
                        id="tier-standard"
                      >
                        {{ packet.name }}
                      </h3>
                    </div>
                    <div
                      class="mt-4 flex items-baseline text-5xl font-semibold price-txt-size"
                    >
                      {{ packet.price }} ₺
                    </div>
                    <div
                      class="flex flex-col items-start justify-between bg-white mt-6 mb-6"
                    >
                      <ul role="list" class="space-y-4">
                        <li
                          v-for="feature in packet.features"
                          :key="feature"
                          class="flex items-start"
                        >
                          <div class="flex-shrink-0">
                            <CheckIcon
                              class="h-6 w-6 text-green-500"
                              aria-hidden="true"
                            />
                          </div>
                          <p class="ml-3 text-base text-gray-700">
                            {{ feature.name }}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="space-y-6">
                    <div>
                      <div
                        @click="selectPacket(packet)"
                        type="submit"
                        class="mb-5 ml-3 block hover:cursor-pointer justify-center py-2 px-4 font-medium rounded-md text-white text-center bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        {{
                          this.info.products != null &&
                          this.info.products.id == packet.id
                            ? $t("message.choosed")
                            : $t("message.choose")
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-5">
            <div class="flex justify-end">
              <button
                type="submit"
                class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Kaydet ve ödeme adımına ilerle
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
// import { DatePicker } from "v-calendar";
import PhoneNumberInput from "@/views/Inputs/PhoneNumberInput";
import SectorSelectInput from "@/views/Inputs/SectorSelectInput.vue";
import { CheckIcon } from "@heroicons/vue/outline";
import { Mixin } from "@/library/utils";
import CityDistrictService from "@/services/city-district.service";
import EmailInput from "@/views/Inputs/EmailInput";
import AccountsService from "@/services/accounts.service";
import ProductsService from "@/services/products.service";
// import moment from "moment";

export default {
  components: {
    SectorSelectInput,
    EmailInput,
    PhoneNumberInput,
    CheckIcon,
    // DatePicker,
  },
  mixins: [Mixin],
  setup() {
    return {};
  },
  props: ["modelValue"],
  data() {
    return {
      cities: [],
      districts: [],
      city: {},
      masks: {
        input: "DD-MM-YYYY",
      },
      maxDate: new Date(),
      step_name: "",
      uploadFiles: [],
      staffCount: ["1-3", "3-10", "10-20", "20+"],
      userInfo: {},
      info: {
        company_name: "",
        company_logo:
          "https://api.emusavirim.net/storage/uploads/1635151409_renkli.png",
        company_website: "",
        company_slogan: "",
        company_details: "",
        contact_name: "",
        contact_email: "",
        contact_phone: "",
        contact_address: "",
        company_sector: "",
        company_official_person: "",
        agent: {
          agent_email: "",
          agent_name: "",
        },
        products: null,
      },
      fields: [],
      user: {},
      packages: [],
      sectors: [],
    };
  },
  methods: {
    selectPacket(packet) {
      this.info.products = packet;
    },
    async getSectors() {
      let response = await new CityDistrictService().getSectors();
      this.sectors = response.result.data;
    },
    async onChange() {
      this.uploadFiles = [...this.$refs.file.files];

      if (this.uploadFiles[0].name.length > 255) {
        this.$store.dispatch("showNotification", {
          title: "Görsel isim uzunluğu max 255 karakter olmalıdır.",
          description: "",
          type: "error",
        });
      } else if (this.uploadFiles[0].size > 20000000) {
        this.$store.dispatch("showNotification", {
          title: "Lütfen daha küçük boyutlu bir resim ile tekrar deneyiniz",
          description: "",
          type: "error",
        });
      } else {
        this.encodeImage(this.uploadFiles[0]);
      }
    },
    encodeImage(input) {
      if (input) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.info.company_logo = e.target.result;
        };
        reader.readAsDataURL(input);
      }
    },
    remove(i) {
      this.uploadFiles.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-gray-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-gray-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-gray-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileUpload.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-gray-300");
    },
    async getCities() {
      let response = await new CityDistrictService().getCities();
      this.cities = response.result.data;
      this.birthCities = response.result.data;
    },
    async getDistricts(id) {
      let response = await new CityDistrictService().getDistricts(id);
      this.districts = response.result.data;
    },
    async addInfo() {
      this.fields =
        this.fields.length !== 0 ? this.fields : [{ name: "", phone: "" }];
      this.info.official_info = this.fields;
      this.info.logo = this.base64Img;

      if (this.info.products != null) {
        this.$store.state.addCompanyOnList = this.info;
        await this.$router.push({
          name: "EndlessNetworkForAdditionalPayment",
          params: { lang: this.$store.state.lang },
        });
      } else {
        this.$store.dispatch("showNotification", {
          title: "Lütfen paket seçimi yapın",
          description: "",
          type: "error",
        });
      }
    },
    async getInfo(id) {
      let response = await new AccountsService().getAccount(id);
      this.id = id;
      if (response.result.informations.length > 0) {
        for (let i = 0; i < response.result.informations.length; i++) {
          if (response.result.informations[i].key === "company_year") {
            this.userInfo[response.result.informations[i].key] = new Date(
              response.result.informations[i].value
            );
          } else {
            this.userInfo[response.result.informations[i].key] =
              response.result.informations[i].value;
          }
        }
      }

      this.info.company_name = response.result.name;
      this.fields = JSON.parse(this.info.company_official_person);
      this.fields = this.fields[0].name !== null ? this.fields : [];
    },
    async getProducts() {
      let response = await new ProductsService().getBrandPackets(
        localStorage.getItem("type"),
        "is_special:1"
      );
      this.packages = response.result.filter(
        (packet) =>
          packet.categories.length > 0 && packet.categories[0].name == "Company"
      );
    },
  },
  async created() {
    await this.getSectors();
    await this.getCities();
    await this.getProducts();
    await this.getInfo(JSON.parse(localStorage.getItem("account")).id);
  },
};
</script>

<style lang="scss">
.packed-shadow {
  box-shadow: 1px 6px 17px 0px #cfcfcf;
}
.information-card {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 11px;
  margin-bottom: 20px;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 400;
}

.small-text {
  font-size: 10px;
}

.dp__input_icon_pad {
  padding-left: 12px !important;
}

.img-content {
  position: relative;
  min-height: 115px;
}

.profile-img {
  position: absolute;
  object-fit: cover;
  top: 15px;
  box-shadow: 0px 7px 20px -7px #111826b3;
  left: 85px;
}

.img-pp-content {
  &:hover {
    .img-hover-photo {
      display: block !important;
    }
  }
}

.img-hover-photo {
  position: absolute;
  top: 15px;
  padding: 50px;
  background: #11182666;
  border-radius: 50%;
  display: none;
  z-index: 100000;
  left: 85px;

  &:hover {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .img-hover-photo {
    left: 40px;
  }
  .profile-img {
    left: 40px;
  }
}
@media only screen and (max-width: 500px) {
  .place-content-center {
    display: flex;
    flex-direction: column;
  }
}
.p-datepicker-today {
  padding: 0;
}
.p-datepicker-header {
  padding: 0 !important;
}
.p-datepicker {
  font-size: 0.875rem;
  width: auto !important;
  min-width: 100px !important;
}
.p-datepicker table td > span {
  width: auto !important;
  height: auto !important;
}
.p-datepicker table td {
  padding: 0 !important;
}
.special-day {
  text-decoration: line-through;
}
.p-inputtext {
  &:focus {
    box-shadow: none !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
    outline: none !important;
  }
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: inherit;
  color: #000;
  border-radius: 0.375rem;
}
.p-datepicker .p-timepicker span {
  font-size: 0.875rem;
}
</style>
