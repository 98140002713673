<template>
  <div>
    <Disclosure as="div" class="bg-sky-700 pb-32"> </Disclosure>

    <main class="-mt-32">
      <div
        class="mb-16 divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x"
      >
        <aside class="py-6 lg:col-span-2 left-menu-content">
          <nav class="space-y-1">
            <div
              class="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto"
            >
              <div class="mt-5 flex-grow flex flex-col">
                <nav
                  class="flex-1 px-2 space-y-1 bg-white"
                  aria-label="Sidebar"
                >
                  <div class="space-y-1">
                    <!-- Current: "bg-gray-100 text-gray-900", Default: "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
                    <button
                      type="button"
                      class="bg-white text-indigo-700 font-bold group w-full flex items-center pl-2 pr-1 py-2 text-left text-base rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      aria-controls="sub-menu-1"
                      aria-expanded="false"
                    >
                      <span class="flex-1">
                        {{ $t("message.generalInfo") }}
                      </span>
                      <!-- Expanded: "text-gray-400 rotate-90", Collapsed: "text-gray-300" -->
                    </button>
                    <!-- Expandable link section, show/hide based on state. -->
                    <div class="space-y-1" id="sub-menu-0">
                      <router-link
                        :to="`/${$store.state.lang}/app/profile`"
                        class="group w-full gap-3 flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                        {{ $t("message.personalInfo") }}
                      </router-link>
                    </div>
                    <div class="space-y-1" id="sub-menu-1">
                      <router-link
                        :to="`/${$store.state.lang}/app/profile/change-password`"
                        class="group w-full gap-3 flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                          />
                        </svg>
                        {{ $t("message.changePassword") }}
                      </router-link>
                    </div>
                  </div>
                  <div class="space-y-1">
                    <!-- Current: "bg-gray-100 text-gray-900", Default: "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
                    <button
                      type="button"
                      class="bg-white text-indigo-700 font-bold group w-full flex items-center pl-2 pr-1 py-2 text-left text-base rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      aria-controls="sub-menu-1"
                      aria-expanded="false"
                    >
                      <span class="flex-1">
                        {{ $t("message.accountInfo") }}
                      </span>
                      <!-- Expanded: "text-gray-400 rotate-90", Collapsed: "text-gray-300" -->
                    </button>
                    <div>
                      <div class="space-y-1" id="sub-menu-2">
                        <router-link
                          :to="`/${$store.state.lang}/app/profile/companies`"
                          class="group w-full gap-3 flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                            />
                          </svg>
                          {{ $t("message.myCompanies") }}
                        </router-link>
                      </div>
                      <div class="space-y-1" id="sub-menu-3">
                        <router-link
                          :to="`/${$store.state.lang}/app/profile/invoices`"
                          class="group w-full gap-3 flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                            />
                          </svg>
                          {{ $t("message.myInvoices") }}
                        </router-link>
                      </div>
                      <div class="space-y-1" id="sub-menu-7">
                        <router-link
                          :to="`/${$store.state.lang}/app/profile/team-member`"
                          class="group w-full gap-3 flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                            />
                          </svg>
                          {{ $t("message.employees") }}
                        </router-link>
                      </div>
                      <div class="space-y-1" id="sub-menu-4">
                        <router-link
                          :to="`/${$store.state.lang}/app/profile/subscriptions`"
                          class="group w-full gap-3 flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                            />
                          </svg>
                          {{ $t("message.subscriptions") }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </nav>
        </aside>
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import { Disclosure } from "@headlessui/vue";
import {
  UserCircleIcon,
  OfficeBuildingIcon,
  CreditCardIcon,
  KeyIcon,
  TicketIcon,
} from "@heroicons/vue/outline";
import { Mixin } from "../../../library/utils";

export default {
  mixins: [Mixin],
  components: {
    Disclosure,
  },
  data() {
    return {
      subNavigation: [
        {
          name: this.$t("message.generalInfo"),
          href: `/${this.$store.state.lang}/app/profile`,
          icon: UserCircleIcon,
          current: false,
          show: true,
        },
        {
          name: this.$t("message.changePassword"),
          href: `/${this.$store.state.lang}/app/profile/change-password`,
          icon: KeyIcon,
          current: false,
          show: true,
        },
        {
          name: this.$t("message.myCompanies"),
          href: `/${this.$store.state.lang}/app/profile/companies`,
          icon: OfficeBuildingIcon,
          current: false,
          permissions: "show accounts",
          show: true,
        },
        {
          name: this.$t("message.myInvoices"),
          href: `/${this.$store.state.lang}/app/profile/invoices`,
          icon: CreditCardIcon,
          current: false,
          permissions: "show invoices",
          show: true,
        },
        {
          name: "Aboneliklerim",
          href: `/${this.$store.state.lang}/app/profile/subscriptions`,
          icon: TicketIcon,
          current: false,
          show: true,
        },
      ],
      generalInfo: false,
      accountInfo: false,
    };
  },
  methods: {
    changeStatus(id) {
      this.subNavigation[id].current = true;
      for (let i = 0; i < this.subNavigation.length; i++) {
        if (i !== id) {
          this.subNavigation[i].current = false;
        }
      }
    },
  },
  created() {
    this.subNavigation[2].show = !!this.checkPermission("show accounts");
    this.subNavigation[3].show = !!this.checkPermission("show invoices");
    this.subNavigation[4].show =
      localStorage.getItem("role_name") === "customer";
    let findPathIndex = this.subNavigation.findIndex(
      (element) => element.href === this.$route.path
    );
    if (this.$route.name === "CompanyEdit") {
      this.subNavigation[2].current = true;
    } else if (this.$route.name === "AddTeamMember") {
      this.subNavigation[4].current = true;
    } else {
      if (findPathIndex !== undefined) {
        this.subNavigation[findPathIndex].current = true;
      }
    }
  },
  computed: {
    watchRoute() {
      return this.$route;
    },
  },
  watch: {
    async watchRoute() {
      let find = this.subNavigation.findIndex(
        (element) => element.href === this.$route.path
      );
      if (find >= 0) {
        if (this.$route.name === "CompanyEdit") {
          this.subNavigation[2].current = true;
        } else {
          this.subNavigation[find].current = true;
          for (let i = 0; i < this.subNavigation.length; i++) {
            if (i !== find) {
              this.subNavigation[i].current = false;
            }
          }
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.left-menu-content {
  background: #1f2a3700;
  z-index: 999;
  height: calc(100vh - 64px);
  box-shadow: 11px 0px 20px -12px #00000026;
}

form {
  border: 0px;
}

.profile-nav-btn {
  height: 50px;

  &:hover {
    background: #ededed;
  }
}

.router-link-exact-active {
  background: #f5f5f5;
  color: #4f46e5;
}
@media screen and (max-width: 920px) {
  .left-menu-content {
    height: 50%;
  }
}
</style>
