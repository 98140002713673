<template>
  <div class="flex justify-center text-center py-12">
    <div class="flex-col items-center lg:p-12 rounded-md z-50">
      <div class="mb-6 flex w-full text-center">
        <img
          class="h-24 mx-auto"
          src="../../../../public/img/con.gif"
          alt="con"
        />
      </div>
      <div>
        <h3 class="text-5xl font-semibold text-gray-800">TEBRİKLER!</h3>
      </div>
      <div class="mt-6 text-gray-500">
        <span
          >Başvurunuz başarılı bir şekilde alınmıştır. En kısa sürede sizi bir
          müşavir ile ilişkilendireceğiz.</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import PaymentService from "../../../services/payment.service";
import AccountsService from "../../../services/accounts.service";

export default {
  components: {},
  setup() {
    const open = ref(true);

    return {
      open,
    };
  },
  data() {
    return {
      advisor: {},
    };
  },
  methods: {
    setStatus() {
      this.$store.state.steps[5].status = "complete";
      for (let i = 0; i < this.$store.state.steps.length; i++) {
        this.$store.state.steps[i].visible = true;
      }
    },
    async getInfo(id) {
      let response = await new AccountsService().getAccount(id);
      this.id = id;
      this.advisor = response.result.users.find(
        (user) => user.roles[0].name === "advisor"
      );
      if (this.advisor) {
        await this.$router.push({
          name: "Dashboard",
          params: { lang: this.$store.state.lang },
        });
      }
    },
    async addInfo() {
      await new PaymentService().addInfo(
        JSON.parse(localStorage.getItem("account")).id,
        "",
        "wait-for-approve"
      );
    },
  },
  async created() {
    await this.getInfo(JSON.parse(localStorage.getItem("account")).id);
    this.setStatus();
    await this.addInfo();
    // setTimeout( () => this.$router.push({ name: 'Dashboard'}), 3000);
  },
};
</script>
