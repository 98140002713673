<template>
  <main>
    <div
      class="flex mt-5 justify-center bg-blue-100 rounded-lg p-2 align-center text-center"
    >
      <div class="text-center text-gray-800 mt-1">
        {{ $t("message.stage") }}
      </div>
      <div>
        <button
          @click="
            $router.push({
              name: 'EndlessNetworkMyCompanyStage',
              params: { lang: this.$i18n.locale },
            })
          "
          class="w-48 h-8 ml-3 inline-flex justify-center text-center px-4 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {{ $t("message.seminar") }}
        </button>
      </div>
    </div>
    <div class="max-w-7xl mx-auto minh-100">
      <router-link
        :to="`/${$store.state.lang}/app/endless-network/activity-calendar`"
        class="mt-4 flex border border-blue-200 bg-blue-500 w-2/6 h-10 text-center justify-center rounded-lg hover:bg-blue-600 text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 mt-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        <span class="hover:underline mt-1">
          {{ $t("message.activityCalendar") }}
        </span></router-link
      >

      <div class="container minh-100 mt-4">
        <iframe
          src="https://www.youtube.com"
          width="100%"
          height="100%"
          frameborder="0"
        >
        </iframe>
      </div>
    </div>
  </main>
</template>

<script>
import StageService from "@/services/stage.service";
export default {
  name: "Stand",
  data() {
    return {
      stage: [],
    };
  },
  methods: {
    async getStage() {
      let response = await new StageService().getStages(this.$route.params.id);
      this.stage = response.result.data;
    },
  },
  async created() {
    await this.getStage();
  },
};
</script>

<style scoped></style>
