<template>
  <div
    class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-12 w-auto"
        src="../../../public/img/siyah.png"
        alt="Workflow"
      />
    </div>

    <div class="login-content mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <h2 class="text-center text-3xl font-extrabold text-gray-900">
          {{ $t("message.login") }}
        </h2>
        <p class="text-center text-sm text-gray-600">
          {{ $t("message.dontHaveAccount") }}
          {{ " " }}
          <a
            :href="`/${$store.state.lang}/app/register`"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {{ $t("message.register") }}
          </a>
        </p>
        <form class="space-y-6" action="#" method="POST">
          <div>
            <label class="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div class="mt-1">
              <email-input v-model="user.email"></email-input>
            </div>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700">
              {{ $t("message.password") }}
            </label>
            <div class="mt-1">
              <password-input v-model="user.password"></password-input>
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input
                v-model="user.remember_me"
                id="remember-me"
                name="remember-me"
                type="checkbox"
                class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                {{ $t("message.rememberMe") }}
              </label>
            </div>

            <div class="text-sm">
              <a
                :href="`/${$store.state.lang}/app/forgot-pass`"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                {{ $t("message.forgotPass") }}
              </a>
            </div>
          </div>

          <div>
            <button
              @click="login"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {{ $t("message.login") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import PasswordInput from "../Inputs/PasswordInput";
import EmailInput from "../Inputs/EmailInput";
import AuthService from "../../services/new/auth";
import AccountsService from "../../services/accounts.service";

export default {
  components: {
    EmailInput,
    PasswordInput,
  },
  data() {
    return {
      user: {
        email: "",
        password: "",
        remember_me: false,
      },
      step: {},
    };
  },
  methods: {
    async login(e) {
      e.preventDefault();
      let response = await new AuthService().loginUser(this.user);
      if (response.success) {
        this.$store.state.me = response.result;

        let avatar = "https://api.emusavirim.net" + response.result.avatar;
        this.$store.state.avatar =
          avatar !== "https://api.emusavirim.netnull"
            ? avatar
            : "https://api.emusavirim.net/storage/uploads/1635151409_renkli.png";
        this.$store.commit("login", JSON.stringify(response.result));
        localStorage.setItem("user", JSON.stringify(response.result));
        localStorage.setItem("token", response.result.token);
        localStorage.setItem("token_expire", response.result.token_expire);
        localStorage.setItem(
          "avatar",
          "https://api.emusavirim.net" + response.result.avatar
        );
        localStorage.setItem(
          "permissions",
          JSON.stringify(response.result.roles[0].permissions)
        );
        localStorage.setItem("role_name", response.result.roles[0].name);

        this.$store.state.is_email_verified = response.result.is_email_verified;
        this.$store.state.userPermissions = localStorage.getItem("permissions");

        let accounts = response.result.accounts;

        if (accounts.length > 0) {
          let account = accounts[0];
          this.$store.state.account = account;
          localStorage.setItem("account", JSON.stringify(account));
          localStorage.setItem("company_name", JSON.stringify(account.name));
        }

        localStorage.setItem("accounts", JSON.stringify(accounts));

        localStorage.setItem("lang", this.$i18n.locale);

        if (!response.result.is_phone_verified) {
          if (response.result.roles[0].name === "customer") {
            localStorage.setItem("type", "2");
          }
          await this.$router.push({
            name: "VerifyPhoneNumber",
            params: { lang: this.$store.state.lang },
          });
        } else {
          if (
            response.result.roles[0].name === "advisor" ||
            response.result.roles[0].name === "admin" ||
            response.result.roles[0].name === "secretary"
          ) {
            localStorage.setItem("type", "1");
            await this.$router.push({
              name: "AdvisorDashboard",
              params: { lang: this.$store.state.lang },
            });
          } else if (response.result.roles[0].name === "customer") {
            localStorage.setItem("type", "2");
            await this.$router.push({
              name: "Dashboard",
              params: { lang: this.$store.state.lang },
            });
          } else if (
            response.result.accounts.length > 0 &&
            response.result.accounts[0].informations.length !== 0
          ) {
            await this.getInfo(response.result.accounts[0].id);
          }
        }
      }
    },
    async getInfo(id) {
      let response = await new AccountsService().getAccount(id);
      this.step = response.result.informations.find(
        (element) => element.key === "step"
      );
      if (this.step) {
        let index = this.$store.state.steps.findIndex(
          (element) => element.slug === this.step.value
        );
        if (this.step.value !== "wait-for-approve") {
          await this.$router.push(this.$store.state.steps[index + 1].to);
        } else {
          await this.$router.push(this.$store.state.steps[index].to);
        }
      }
    },
  },
  async created() {
    await localStorage.setItem("lang", this.$route.params.lang);
    this.$i18n.locale = this.$route.params.lang;
    await this.$store.commit("setLang", this.$route.params.lang);
    await this.$store.dispatch("getNavs");
  },
};
</script>
<style scoped lang="scss">
.space-y-6 {
  margin-top: 30px;
}
@media only screen and (max-width: 940px) {
  .login-content {
    padding: 0 15px 0 15px;
  }
}
</style>
