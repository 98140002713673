<template>
  <div
    class="hidden lg:block fixed top-0 left-0 w-1/2 bg-white"
    aria-hidden="true"
  />
  <div
    class="hidden lg:block fixed top-0 right-0 w-1/2 bg-gray-50"
    aria-hidden="true"
  />

  <div
    class="relative grid grid-cols-1 gap-x-16 max-w-7xl mx-auto lg:px-8 lg:grid-cols-2 xl:gap-x-48"
  >
    <h1 class="sr-only">{{ $t("message.orderInformation") }}</h1>

    <section
      aria-labelledby="summary-heading"
      class="bg-gray-50 pt-16 pb-10 px-4 sm:px-6 lg:pb-16 lg:row-start-1 lg:col-start-2"
    >
      <div class="max-w-lg mx-auto lg:max-w-none">
        <h2 id="summary-heading" class="text-lg font-medium text-gray-900">
          {{ $t("message.orderSummary") }}
        </h2>

        <ul
          role="list"
          class="text-sm font-medium text-gray-900 divide-y divide-gray-200"
        >
          <li class="flex items-start py-6 space-x-4">
            <div class="flex-auto space-y-1">
              <h3>{{ this.info.stage_packet.name }}</h3>
              <div>{{ this.info.stage_packet.description }}</div>
            </div>
            <p class="flex-none text-base font-medium">
              {{ this.info.stage_packet.price }} ₺
            </p>
          </li>
        </ul>

        <dl
          class="hidden text-sm font-medium text-gray-900 space-y-6 border-gray-200 pt-6 lg:block"
        >
          <div
            class="flex items-center justify-between border-t border-gray-200 pt-6"
          >
            <dt class="text-base">{{ $t("message.total") }}</dt>
            <dd class="text-base">{{ this.info.stage_packet.price }} ₺</dd>
          </div>
        </dl>

        <Popover
          class="fixed bottom-0 inset-x-0 flex flex-col-reverse text-sm font-medium text-gray-900 lg:hidden"
        >
          <div
            class="relative z-10 bg-white border-t border-gray-200 px-4 sm:px-6"
          >
            <div class="max-w-lg mx-auto">
              <PopoverButton class="w-full flex items-center py-6 font-medium">
                <span class="text-base mr-auto">Toplam</span>
                <span class="text-base mr-2">2.000 ₺</span>
              </PopoverButton>
            </div>
          </div>
        </Popover>
      </div>
    </section>

    <div
      class="pt-16 pb-36 px-4 sm:px-6 lg:pb-16 lg:px-0 lg:row-start-1 lg:col-start-1"
    >
      <div class="max-w-lg mx-auto lg:max-w-none">
        <section aria-labelledby="payment-heading">
          <h2 id="payment-heading" class="text-lg font-medium text-gray-900">
            {{ $t("message.paymentDetail") }}
          </h2>

          <div class="mt-6 grid grid-cols-3 sm:grid-cols-4 gap-y-6 gap-x-4">
            <div class="col-span-3 sm:col-span-4">
              <label
                for="name-on-card"
                class="block text-sm font-medium text-gray-700"
                >{{ $t("message.cardHolderName") }}</label
              >
              <div class="mt-1">
                <input
                  v-model="card.name"
                  type="text"
                  id="name-on-card"
                  name="name-on-card"
                  autocomplete="off"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div class="col-span-3 sm:col-span-4">
              <label class="block text-sm font-medium text-gray-700">{{
                $t("message.cardNumber")
              }}</label>
              <div class="mt-1">
                <credit-card-input
                  v-model="card.cardNumber"
                ></credit-card-input>
              </div>
            </div>

            <div class="col-span-2 sm:col-span-3">
              <label class="block text-sm font-medium text-gray-700">{{
                $t("message.cardExpire")
              }}</label>
              <div class="mt-1">
                <card-expiration-date-input
                  v-model="card.expiration"
                ></card-expiration-date-input>
              </div>
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">{{
                $t("message.CVC")
              }}</label>
              <div class="mt-1">
                <card-cvc-input v-model="card.cvc"></card-cvc-input>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="grid justify-items-end mt-6 max-w-lg mx-auto lg:max-w-none">
        <div>
          <button
            @click="addPayment"
            class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:ml-6 sm:order-last sm:w-auto"
          >
            {{ $t("message.makePayment") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Popover, PopoverButton } from "@headlessui/vue";
import CreditCardInput from "../Inputs/CreditCardInput";
import CardExpirationDateInput from "../Inputs/CardExpirationDateInput";
import CardCvcInput from "../Inputs/CardCvcInput";
import AccountsService from "../../services/accounts.service";
import { Mixin } from "@/library/utils";
import StageService from "@/services/stage.service";

export default {
  components: {
    CardCvcInput,
    CardExpirationDateInput,
    CreditCardInput,
    Popover,
    PopoverButton,
  },
  mixins: [Mixin],
  setup() {
    return {};
  },
  data() {
    return {
      card: { name: "", cardNumber: "", expiration: "", cvc: "" },
      info: {},
      id: 0,
      orderSummary: "",
      childPriceTotal: 0,
      show: false,
    };
  },
  methods: {
    async addPayment() {
      this.card.cardNumber = this.card.cardNumber.replace(/\s/g, "");
      await new StageService()
        .addPayment(this.card, this.info, this.info.stage_packet.id)
        .then(() => {
          this.$router.push({
            name: "Dashboard",
            params: { lang: this.$store.state.lang },
          });
          this.addInfo();
        });
    },
    async getInfo(id) {
      let response = await new AccountsService().getAccount(id);
      this.id = id;
      if (response.result.informations.length > 0) {
        for (let i = 0; i < response.result.informations.length; i++) {
          this.info[response.result.informations[i].key] =
            response.result.informations[i].value;
        }
      }
      let speakers = [];
      JSON.parse(this.info.stage_speakers).forEach((speaker) => {
        speakers.push(speaker.id);
      });
      this.info.stage_speakers = speakers;
      this.info.stage_packet = JSON.parse(this.info.stage_packet);
    },
  },
  async created() {
    await this.getInfo(JSON.parse(localStorage.getItem("account")).id);
  },
};
</script>
<style lang="scss">
.bg-gray {
  background: #f9f9f9;
  border: 0px;
  box-shadow: 0 0 0 transparent;
}

.text-gray {
  color: #1f2a37;
}
</style>
