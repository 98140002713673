<template>
  <div
    v-if="checkPermission('show accounts all')"
    class="accounts-container max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100"
  >
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="flex space-x-2 p-2 justify-end align-center">
            <button
              @click="changeTab('all user')"
              :class="
                type === 'all user'
                  ? 'bg-indigo-700 text-white'
                  : 'bg-indigo-100 text-indigo-700'
              "
              class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded hover:bg-indigo-200"
            >
              Tüm Kullanıcılar
            </button>
            <button
              @click="changeTab('other')"
              :class="
                type === 'other'
                  ? 'bg-indigo-700 text-white'
                  : 'bg-indigo-100 text-indigo-700'
              "
              class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded hover:bg-indigo-200"
            >
              Paketi olmayan Kullanıcılar
            </button>
            <button
              v-show="checkPermission('store users')"
              @click="addUserModalOpen()"
              class="bg-indigo-100 text-indigo-700 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded hover:bg-indigo-200"
            >
              + Yeni Kullanıcı
            </button>
          </div>
          <div
            v-if="type === 'all user'"
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Kullanıcı Adı
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Telefon
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    E-Posta
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="accounts_all.length"
                class="bg-white divide-y divide-gray-200"
              >
                <tr v-for="(account, key) in accounts_all" :key="key">
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ account.name }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    <span>
                      {{ account.phone.substring(2, 6) }}
                      {{ account.phone.substring(6, 9) }}
                      {{ account.phone.substring(9, 11) }}
                      {{ account.phone.substring(11, 13) }}</span
                    >
                  </td>

                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    <span> {{ account.email }}</span>
                  </td>

                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <button
                      v-show="checkPermission('update users')"
                      @click="getAccountAll(account.id)"
                      class="x ×· border border-transparent text-indigo-600 hover:text-indigo-900"
                    >
                      <PencilIcon class="h-6 w-6"></PencilIcon>
                    </button>
                    <button
                      v-show="checkPermission('destroy users')"
                      @click="setDeleteModal(account.id)"
                      class="ml-2 border border-transparent text-indigo-600 hover:text-indigo-900"
                    >
                      <TrashIcon class="h-6 w-6"></TrashIcon>
                    </button>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
              <tbody v-else class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          Listelenecek hesap bulunmamaktadır
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="type === 'other'"
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Kullanıcı Adı
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Telefon
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    E-Posta
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="accounts.length"
                class="bg-white divide-y divide-gray-200"
              >
                <tr v-for="(account, key) in accounts" :key="key">
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ account.name }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    <span>
                      {{ account.phone.substring(2, 6) }}
                      {{ account.phone.substring(6, 9) }}
                      {{ account.phone.substring(9, 11) }}
                      {{ account.phone.substring(11, 13) }}</span
                    >
                  </td>

                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    <span> {{ account.email }}</span>
                  </td>

                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <button
                      @click="getAccountAll(account.id)"
                      class="x ×· border border-transparent text-indigo-600 hover:text-indigo-900"
                    >
                      <PencilIcon class="h-6 w-6"></PencilIcon>
                    </button>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
              <tbody v-else class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          Listelenecek hesap bulunmamaktadır
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="addUserModal"
      :clickToClose="false"
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        >
          <div class="form-content">
            <div class="mt-6 flex-col">
              <div class="w-full">
                <label
                  htmlFor="username"
                  class="lg:block text-sm font-medium text-gray-700"
                >
                  {{ $t("message.name") }} {{ $t("message.surname") }}
                </label>
                <div class="mt-1 rounded-md lg:shadow-sm flex">
                  <text-input
                    v-model="newAccountInfo.name"
                    class="lg:w-full xl:w-full md:w-5/6 sm:w-5/6 rounded-md sm:text-sm"
                  ></text-input>
                </div>
              </div>
            </div>
            <div class="mt-6 flex-col">
              <div class="w-full">
                <label
                  htmlFor="username"
                  class="lg:block text-sm font-medium text-gray-700"
                >
                  {{ $t("message.phone") }}
                </label>
                <div class="mt-1 rounded-md lg:shadow-sm flex">
                  <phone-number-input
                    :phone="newAccountInfo.phone"
                    v-model="newAccountInfo.phone"
                    class="lg:w-full xl:w-full md:w-5/6 sm:w-5/6 rounded-md sm:text-sm"
                  ></phone-number-input>
                </div>
              </div>
              <div class="w-full mt-5">
                <label
                  htmlFor="lastname"
                  class="lg:block text-sm font-medium text-gray-700"
                >
                  E-Posta
                </label>
                <div class="mt-1 rounded-md lg:shadow-sm flex">
                  <email-input
                    v-model="newAccountInfo.email"
                    class="lg:w-full xl:w-full md:w-5/6 sm:w-5/6"
                  ></email-input>
                </div>
              </div>
            </div>
            <div class="mt-6 flex-col">
              <label class="block text-sm font-medium text-gray-700">
                {{ $t("message.password") }}
              </label>
              <div class="mt-1">
                <password-input
                  v-model="newAccountInfo.password"
                ></password-input>
              </div>
            </div>
            <div class="mt-6 flex-col">
              <div class="grid grid-cols-12 gap-3 flex-grow">
                <div class="col-span-12 sm:col-span-12">
                  <label class="lg:block text-sm font-medium text-gray-700">
                    Rol listesi
                  </label>
                  <Listbox
                    as="div"
                    v-model="newAccountInfo.role"
                    class="firm-select"
                  >
                    <ListboxLabel
                      class="block text-sm font-medium text-gray-700"
                    ></ListboxLabel>
                    <div class="mt-1 relative">
                      <ListboxButton
                        class="border border-gray-200 relative w-full px-3 py-2 rounded-md text-sm font-medium"
                      >
                        <span class="block truncate text-left">{{
                          newAccountInfo.role.name
                        }}</span>
                        <span
                          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                        >
                          <SelectorIcon
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </ListboxButton>

                      <transition
                        leave-active-class="transition ease-in duration-100"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                      >
                        <ListboxOptions
                          class="company-drop-list absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                        >
                          <ListboxOption
                            as="template"
                            v-for="role in roles"
                            :key="role.id"
                            :value="role"
                            v-slot="{ active, selected }"
                          >
                            <li
                              :class="[
                                active
                                  ? 'text-white bg-indigo-600'
                                  : 'text-gray-900',
                                'cursor-default select-none relative py-2 pl-8 pr-4',
                              ]"
                            >
                              <span
                                :class="[
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate',
                                ]"
                              >
                                {{ role.name }}
                              </span>

                              <span
                                v-if="selected"
                                :class="[
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                ]"
                              >
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>
                </div>
              </div>
            </div>
            <div class="mt-5 flex space-x-5">
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                @click="addUserModal = false"
              >
                Kapat
              </button>
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                @click="addUser"
              >
                Kaydet
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="lg:col-span-9">
    <!-- Profile section -->
    <div class="flex justify-center items-center py-12">
      <div
        class="flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="text-white uppercase text-lg text-center">
          <div>BU SAYFAYI GÖRÜNTÜLEMEK İÇİN YETKİNİZ BULUNMAMAKTADIR.</div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="role_modal"
    class="h-full fixed inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center pt-4 px-4 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="modal-cont inline-block align-bottom h-full bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:h-full sm:max-w-xl sm:w-full sm:p-6"
      >
        <div class="text-center">
          <div>
            <div class="flex-grow">
              <div class="text-center">
                <h3>ROL ATAMA</h3>
              </div>
              <div>
                <div class="mt-3 sm:mt-5">
                  <div class="mt-2">
                    <div class="flex-grow">
                      <div class="mt-6 grid grid-cols-12 gap-3 flex-grow">
                        <div class="col-span-12 sm:col-span-12 mt-2">
                          <label
                            class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"
                          >
                            Rol Listesi
                          </label>
                          <Listbox as="div" v-model="role" class="firm-select">
                            <ListboxLabel
                              class="block text-sm font-medium text-gray-700"
                            ></ListboxLabel>
                            <div class="mt-1 relative">
                              <ListboxButton
                                class="border border-gray-200 relative w-full px-3 py-2 rounded-md text-sm font-medium"
                              >
                                <span class="block truncate text-left">{{
                                  role.name
                                }}</span>
                                <span
                                  class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                                >
                                  <SelectorIcon
                                    class="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </ListboxButton>

                              <transition
                                leave-active-class="transition ease-in duration-100"
                                leave-from-class="opacity-100"
                                leave-to-class="opacity-0"
                              >
                                <ListboxOptions
                                  class="company-drop-list absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                  <ListboxOption
                                    as="template"
                                    v-for="role in roles"
                                    :key="role.id"
                                    :value="role"
                                    v-slot="{ active, selected }"
                                  >
                                    <li
                                      :class="[
                                        active
                                          ? 'text-white bg-indigo-600'
                                          : 'text-gray-900',
                                        'cursor-default select-none relative py-2 pl-8 pr-4',
                                      ]"
                                    >
                                      <span
                                        :class="[
                                          selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                          'block truncate',
                                        ]"
                                      >
                                        {{ role.name }}
                                      </span>

                                      <span
                                        v-if="selected"
                                        :class="[
                                          active
                                            ? 'text-white'
                                            : 'text-indigo-600',
                                          'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                        ]"
                                      >
                                        <CheckIcon
                                          class="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </li>
                                  </ListboxOption>
                                </ListboxOptions>
                              </transition>
                            </div>
                          </Listbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
          >
            <button
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
              @click="assignRoleToUser"
            >
              Kaydet
            </button>
            <button
              @click="role_modal = false"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              ref="cancelButtonRef"
            >
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TransitionRoot as="template" :show="deleteModal">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="deleteModal = false"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="flex justify-center items-center">
              <div
                class="flex justify-center items-center w-full overflow-hidden py-2 sm:px-6 lg:px-8"
              >
                <div class="text-lg text-center">
                  <div>Kullanıcıyı silmek istediğinize emin misiniz?</div>
                </div>
              </div>
            </div>

            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                @click="deleteUser"
              >
                Sil
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                @click="deleteModal = false"
                ref="cancelButtonRef"
              >
                Kapat
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import {
  ListboxOptions,
  ListboxOption,
  Listbox,
  ListboxLabel,
  ListboxButton,
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { Mixin } from "../../library/utils";
import {
  CheckIcon,
  SelectorIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/vue/outline";
import UserService from "@/services/user.service";
import PermissionsService from "@/services/permissions.service";
import TextInput from "@/views/Inputs/TextInput.vue";
import PhoneNumberInput from "@/views/Inputs/PhoneNumberInput.vue";
import EmailInput from "@/views/Inputs/EmailInput.vue";
import PasswordInput from "@/views/Inputs/PasswordInput.vue";

export default {
  mixins: [Mixin],
  components: {
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    Listbox,
    SelectorIcon,
    ListboxButton,
    ListboxLabel,
    PencilIcon,
    TrashIcon,
    TransitionRoot,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TextInput,
    PhoneNumberInput,
    EmailInput,
    PasswordInput,
  },
  setup() {},
  data() {
    return {
      accounts: [],
      accounts_all: [],
      deleteModal: false,
      accountId: 0,
      account: {},
      account_all: {},
      updateModal: false,
      addModal: false,
      advisors: [],
      accountCheck: "",
      selectedItem: { name: "" },
      advisor: {},
      user_id: 0,
      informations: {},
      selectedItemSecretary: { name: "" },
      secretaries: [],
      secretary: {},
      type: "all user",
      roles: [],
      role_modal: false,
      role: {},
      userID: null,
      addUserModal: false,
      newAccountInfo: {},
    };
  },
  methods: {
    async getAccount(id) {
      let filter = "filters=has_package:0;has_account:0";
      if (id) {
        this.informations = {};
        this.accountId = id;
        let response = await new UserService().getUserInfo(id, ``, filter);
        for (let i = 0; i < response.result.informations.length; i++) {
          this.informations[response.result.informations[i].key] =
            response.result.informations[i].value;
        }
        this.account = response.result;
      } else {
        let response = await new UserService().getUserInfo("", "", filter);
        this.accounts = response.result.data;
      }
    },
    changeTab(val) {
      this.type = val;
    },
    async addUserModalOpen() {
      let response = await new PermissionsService().getRoles();
      this.roles = response.result.data;
      this.newAccountInfo.role = response.result.data[1];
      this.addUserModal = true;
    },
    async getAccountAll(id) {
      if (id) {
        this.accountId = id;
        let response = await new UserService().getUserInfo(id, id);
        this.account_all = response.result;
        await this.getRoles();
      } else {
        let response = await new UserService().getUserInfo();
        this.accounts_all = response.result.data;
      }
    },
    async getRoles() {
      let response = await new PermissionsService().getRoles();
      this.roles = response.result.data;
      this.role = this.roles.filter(
        (el) => el.name === this.account_all.roles[0].name
      )[0];
      this.role_modal = true;
    },
    async assignRoleToUser() {
      await new PermissionsService().assignRoleToUser(
        this.account_all.id,
        this.role.name
      );
    },
    setDeleteModal(id) {
      this.userID = id;
      this.deleteModal = true;
    },
    async deleteUser() {
      await new UserService().deleteUser(this.userID);
      this.deleteModal = false;
      await this.getAccountAll();
    },
    async addUser() {
      await new UserService().addUser(
        this.newAccountInfo,
        JSON.parse(localStorage.getItem("user")).id
      );
      this.addUserModal = false;
      await this.getAccountAll();
    },
  },
  async created() {
    if (this.checkPermission("show accounts all")) {
      await this.getAccount();
      await this.getAccountAll();
    }
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .accounts-container {
    padding: 15px;
  }
}
</style>
