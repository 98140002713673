import axios from "axios";
import authHeader from "./auth-header";

class EmployeeService {
  addRole(name) {
    return axios
      .post(
        `accounts/${JSON.parse(localStorage.getItem("account")).id}/roles`,
        {
          name: name,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  getRoles() {
    return axios
      .get(`accounts/${JSON.parse(localStorage.getItem("account")).id}/roles`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  getPermissions() {
    return axios
      .get(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/permissions`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  givePermissionsToRole(role_id, permissions) {
    return axios
      .post(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/roles/${role_id}`,
        { permissions: permissions },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  deletePermissionsToRole(role_id) {
    return axios
      .delete(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/roles/${role_id}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  addMember(member, role_id) {
    return axios
      .post(
        `accounts/${JSON.parse(localStorage.getItem("account")).id}/members`,
        {
          name: member.name,
          email: member.email,
          phone: member.phone,
          role_id: role_id,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  deleteMember(id) {
    return axios
      .delete(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/members/${id}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  editMember(member, role_id) {
    return axios
      .put(
        `accounts/${JSON.parse(localStorage.getItem("account")).id}/members/${
          member.id
        }`,
        {
          name: member.name,
          email: member.email,
          phone: member.phone,
          role_id: role_id,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default EmployeeService;
