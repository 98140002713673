<template>
  <form class="lg:col-span-10" action="#" method="POST">
    <div class="form-content">
      <div class="mt-6 flex lg:space-x-6 password-content">
        <div class="w-full">
          <label
            htmlFor="username"
            class="lg:block text-sm font-medium text-gray-700"
          >
            {{ $t("message.new") }} {{ $t("message.password") }}
          </label>
          <div class="mt-1 rounded-md lg:shadow-sm flex">
            <password-input
              v-model="pass"
              class="lg:w-full xl:w-full md:w-5/6 sm:w-5/6 rounded-md sm:text-sm"
            ></password-input>
          </div>
        </div>
        <div class="w-full">
          <label
            htmlFor="username"
            class="lg:block text-sm font-medium text-gray-700"
          >
            {{ $t("message.passwordConfirmation") }}
          </label>
          <div class="mt-1 rounded-md lg:shadow-sm flex">
            <password-input
              v-model="pass_confirm"
              class="lg:w-full xl:w-full md:w-5/6 sm:w-5/6 rounded-md sm:text-sm"
            ></password-input>
          </div>
        </div>
      </div>
      <div class="mt-6 flex-col"></div>
      <div class="mt-6 flex justify-start">
        <button
          type="button"
          @click="changePassword"
          class="inline-flex items-center justify-center text-center px-3 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <SaveIcon class="mr-2 h-5 w-5" aria-hidden="true" />

          {{ $t("message.save") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { SaveIcon } from "@heroicons/vue/outline";
import PasswordInput from "../../Inputs/PasswordInput";
import UserService from "../../../services/user.service";

export default {
  components: {
    PasswordInput,
    SaveIcon,
  },
  data() {
    return {
      pass: "",
      pass_confirm: "",
    };
  },
  methods: {
    async changePassword() {
      if (
        this.pass === this.pass_confirm &&
        this.pass.length >= 6 &&
        this.pass !== ""
      ) {
        await new UserService().changePassword(this.pass, this.pass_confirm);
      }
    },
  },
  async created() {},
};
</script>
<style lang="scss">
@media (max-width: 600px) {
  .password-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.img-content {
  background: #f5f5f5;
  position: relative;
  min-height: 130px;

  .img-drop {
    position: absolute;
    top: 51px;
  }
}

.profile-img {
  position: absolute;
  object-fit: cover;
  top: 15px;
  box-shadow: 0px 7px 20px -7px #111826b3;
  left: 50px;
}

.form-content {
  margin-top: 95px;
  padding: 0px 55px;
  width: 80%;
}

.img-pp-content {
  &:hover {
    .img-hover-photo {
      display: block !important;
    }
  }
}

.img-hover-photo {
  position: absolute;
  top: 15px;
  padding: 50px;
  background: #11182666;
  border-radius: 50%;
  display: none;
  z-index: 100000;
  left: 50px;

  &:hover {
    display: block;
  }
}
</style>
