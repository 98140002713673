<template>
  <div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <input
        name="identityNo"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        v-on:blur="identityNumberValidations($event, modelValue)"
        type="text"
        v-maska="'###########'"
        class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      />
      <div v-show="error.display !== '' && !error.status">
        <div
          class="mb-7 absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none"
        >
          <ExclamationCircleIcon
            class="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
        <p
          v-if="error.display === 'ID should only contain numbers.'"
          class="mt-2 text-sm text-red-600"
        >
          {{ $t("message.tcEmpty") }}
        </p>
        <p v-else class="mt-2 text-sm text-red-600">
          {{ $t("message." + error.display) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { maska } from "maska";
let { checkId } = require("turkish-id-checker");
export default {
  components: {
    ExclamationCircleIcon,
  },
  directives: {
    maska,
  },
  props: ["modelValue"],
  data() {
    return {
      phone: "",
      error: { display: "", status: false },
    };
  },
  methods: {
    identityNumberValidations(e, val) {
      this.error = checkId(val);
      e.preventDefault();
    },
  },
};
</script>
