import axios from "axios";
import authHeader from "./auth-header";

class DocumentsService {
  getFileTypes(role) {
    return axios
      .get(`file-types?filters=${role}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  addFile(file, user_id) {
    return axios
      .post(
        `accounts/${JSON.parse(localStorage.getItem("account")).id}/files${
          user_id ? `/${user_id}` : ""
        }`,
        {
          name: file.name,
          path: file.path,
          description: file.description,
          type_id: file.type_id,
          user_id: JSON.parse(localStorage.getItem("user")).id,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  getFiles(user_id, file_id, all) {
    return axios
      .get(
        `accounts/${JSON.parse(localStorage.getItem("account")).id}/files${
          file_id ? `/${file_id}` : ""
        }${all ? `?all` : ""}`,
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  editFile(file, user_id, file_id) {
    return axios
      .put(
        `accounts/${JSON.parse(localStorage.getItem("account")).id}/files${
          file_id ? `/${file_id}` : ""
        }`,
        {
          name: file.name,
          path: file.path,
          description: file.description,
          type_id: file.type_id,
          user_id: JSON.parse(localStorage.getItem("user")).id,
        },
        user_id
          ? {
              headers: authHeader(),

              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  deleteFile(user_id, file_id) {
    return axios
      .delete(
        `accounts/${JSON.parse(localStorage.getItem("account")).id}/files${
          file_id ? `/${file_id}` : ""
        }`,
        user_id
          ? {
              headers: authHeader(),

              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  fileDownload(user_id, file_id, account_id, label, all) {
    return axios
      .get(
        `accounts/${account_id}/files/${file_id}/download${all ? `?all` : ""}`,
        user_id
          ? {
              headers: authHeader(),
              responseType: "blob",
              params: { user_id: user_id },
            }
          : { headers: authHeader(), responseType: "blob" }
      )
      .then((response) => {
        const blob = new Blob([response], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = label;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(console.error);
  }
  filterFiles(start_date, finish_date, type_id, search, selected) {
    let currArr = [];
    start_date.length > 0 &&
      currArr.push("created_at:" + start_date + "," + finish_date);
    type_id.name.length > 0 && currArr.push("type_id:" + type_id.id);
    search.length > 0 && currArr.push("description:" + search);
    selected === "incoming" || selected === "outgoing"
      ? currArr.push(selected)
      : "";
    return axios
      .get(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/files?all&filters=${currArr.join(";")}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default DocumentsService;
