import axios from "axios";
import authHeader from "./auth-header";

class AnnouncementsService {
  getAnnouncements(id) {
    return axios
      .get(`announcements${id ? `/${id}` : ""}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  addAnnouncement(announcement) {
    return axios
      .post(
        `announcements`,
        {
          content: announcement.content,
          title: announcement.title,
          is_active: true,
          product_id: announcement.product,
          type: "1",
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  editAnnouncement(announcement, id) {
    return axios
      .put(
        `announcements/${id}`,
        {
          title: announcement.title,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  deleteAnnouncement(id) {
    return axios
      .delete(`announcements/${id}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}

export default AnnouncementsService;
