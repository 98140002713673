<template>
  <div
    v-if="checkPermission('update accounts')"
    class="space-y-8 divide-y divide-gray-200"
  >
    <div v-if="company_name" class="mb-2">
      <span class="font-medium mb-4">{{ $t("message.companyName") }} : </span>
      <span class="font-medium text-indigo-600">{{ company_name }}</span>
    </div>
    <div class="pt-8">
      <div class="information-card">
        <div>
          <h3 class="text-lg leading-6 font-bold text-gray-900">
            {{ $t("message.personalInfo") }}
          </h3>
        </div>
        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label class="block text-sm font-light text-gray-700">
              {{ $t("message.identityNo") }} <span class="text-red-500">*</span>
            </label>
            <div class="mt-1">
              <identity-number-input
                v-model="accountInfo.identityNo"
              ></identity-number-input>
            </div>
          </div>
          <div class="sm:col-span-3">
            <label class="block text-sm font-light text-gray-700">
              {{ $t("message.phone") }} <span class="text-red-500">*</span>
            </label>
            <div class="mt-1">
              <phone-number-input
                :modelValue="accountInfo.phone"
                v-model="accountInfo.phone"
              ></phone-number-input>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-5"></div>

      <div class="information-card">
        <div class="divide-gray-200">
          <h3 class="text-lg leading-6 font-bold text-gray-900">
            {{ $t("message.birthInfo") }}
          </h3>
        </div>
        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-2">
            <label class="block text-sm font-light text-gray-700">
              {{ $t("message.city") }} <span class="text-red-500">*</span>
            </label>
            <div class="mt-1">
              <city-select-input
                @change="getBirthDistricts(accountInfo.birthCity)"
                :cities="birthCities"
                v-model="accountInfo.birthCity"
              ></city-select-input>
            </div>
          </div>
          <div class="sm:col-span-2">
            <label class="block text-sm font-light text-gray-700">
              {{ $t("message.district") }} <span class="text-red-500">*</span>
            </label>
            <div class="mt-1">
              <district-select-input
                :class="{ disabled: !accountInfo.birthCity }"
                :districts="birthDistricts"
                v-model="accountInfo.birthDist"
              ></district-select-input>
            </div>
          </div>
          <div class="sm:col-span-2">
            <label class="block text-sm font-light text-gray-700">
              {{ $t("message.birthDate") }} <span class="text-red-500">*</span>
            </label>
            <div class="mt-1">
              <Calendar
                id="date"
                v-model="accountInfo.birthDate"
                class="flex-grow w-full border-gray-300"
                dateFormat="dd-mm-yy"
                :autofocus="false"
                autoComplete="off"
                :autocapitalize="false"
                :aria-autocomplete="false"
                :max-date="maxDate"
                locale="tr"
              >
              </Calendar>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-5"></div>

      <div class="information-card">
        <div class="divide-gray-200">
          <h3 class="text-lg leading-6 font-bold text-gray-900">
            {{ $t("message.addressInfo") }}
          </h3>
        </div>
        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-2">
            <label class="block text-sm font-light text-gray-700">
              {{ $t("message.city") }} <span class="text-red-500">*</span>
            </label>
            <div class="mt-1">
              <city-select-input
                @change="getDistricts(accountInfo.city)"
                :cities="cities"
                v-model="accountInfo.city"
              ></city-select-input>
            </div>
          </div>
          <div class="sm:col-span-2">
            <label class="block text-sm font-light text-gray-700">
              {{ $t("message.district") }} <span class="text-red-500">*</span>
            </label>
            <div class="mt-1">
              <district-select-input
                :class="{ disabled: !accountInfo.city }"
                :districts="districts"
                v-model="accountInfo.district"
              ></district-select-input>
            </div>
          </div>
          <div class="sm:col-span-2">
            <label class="block text-sm font-light text-gray-700">
              {{ $t("message.neighbourhood") }}
              <span class="text-red-500">*</span>
            </label>
            <div class="mt-1">
              <input
                v-model="accountInfo.neighbourhood"
                type="text"
                name="first-name"
                autocomplete="off"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label class="block text-sm font-light text-gray-700">
              {{ $t("message.street") }} <span class="text-red-500">*</span>
            </label>
            <div class="mt-1">
              <input
                v-model="accountInfo.street"
                type="text"
                name="first-name"
                autocomplete="off"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label class="block text-sm font-light text-gray-700">
              {{ $t("message.buildingNo") }} <span class="text-red-500">*</span>
            </label>
            <div class="mt-1">
              <input
                v-model="accountInfo.buildingNo"
                type="number"
                name="first-name"
                autocomplete="off"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label class="block text-sm font-light text-gray-700">
              {{ $t("message.doorNo") }} <span class="text-red-500">*</span>
            </label>
            <div class="mt-1">
              <input
                v-model="accountInfo.doorNo"
                type="number"
                name="first-name"
                autocomplete="off"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <router-link
          :to="`/${$store.state.lang}/app/application-form/packets`"
          type="submit"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {{ $t("message.prevPage") }}
        </router-link>
        <button
          @click="addAccountInfo"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {{ $t("message.nextPage") }}
        </button>
      </div>
    </div>
  </div>
  <div v-else class="lg:col-span-9">
    <!-- Profile section -->
    <div class="flex justify-center items-center py-12">
      <div
        class="flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="text-white uppercase text-lg text-center">
          <div>BU SAYFAYI GÖRÜNTÜLEMEK İÇİN YETKİNİZ BULUNMAMAKTADIR.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneNumberInput from "../../Inputs/PhoneNumberInput";
import CitySelectInput from "../../Inputs/CitySelectInput";
import DistrictSelectInput from "../../Inputs/DistrictSelectInput";
import IdentityNumberInput from "../../Inputs/IdentityNumberInput";
import AccountsService from "../../../services/accounts.service";
import { Mixin } from "../../../library/utils";
import CityDistrictService from "../../../services/city-district.service";
import moment from "moment/moment";
// import { DatePicker } from "v-calendar";
export default {
  components: {
    PhoneNumberInput,
    CitySelectInput,
    DistrictSelectInput,
    IdentityNumberInput,
    // DatePicker,
  },
  mixins: [Mixin],
  setup() {
    return {};
  },
  props: ["modelValue"],
  data() {
    return {
      birthDate: "",
      accountInfo: {
        identityNo: "",
        birthCity: "",
        birthDist: "",
        birthDate: null,
        city: "",
        district: "",
        phone: "",
        street: "",
        neighbourhood: "",
        buildingNo: "",
        doorNo: "",
      },
      identityNo: "identityNo",
      cities: [],
      districts: [],
      city: {},
      birthDistricts: [],
      birthCities: [],
      masks: {
        input: "DD-MM-YYYY",
      },
      maxDate: new Date(),
      step_name: "",
      company_name: null,
    };
  },
  methods: {
    async setStatus() {
      this.$store.state.steps[2].status = "complete";
      // this.$store.state.steps[3].status = 'current'
    },
    async getInfo(id) {
      let response = await new AccountsService().getAccount(id);
      if (response.result.informations.length > 0) {
        for (let i = 0; i < response.result.informations.length; i++) {
          this.accountInfo[response.result.informations[i].key] =
            response.result.informations[i].value;
        }
      }
      let companyName = JSON.parse(localStorage.getItem("company_name"));
      let sonuc = response.result.users[0].accounts.find((element) => {
        element.name === companyName;
        return true;
      });
      if (sonuc) this.company_name = companyName;
      if (this.accountInfo.birthCity) {
        await this.getBirthDistricts(this.accountInfo.birthCity);
      }
      if (this.accountInfo.city) {
        await this.getDistricts(this.accountInfo.city);
      }
      if (this.accountInfo.birthDate) {
        this.accountInfo.birthDate = await this.formatedDate(
          this.accountInfo.birthDate
        );
      }
      let index = this.$store.state.steps.findIndex(
        (step) => step.slug === this.accountInfo.step
      );
      let route_index = this.$store.state.steps.findIndex(
        (step) => step.to === this.$route.path
      );
      if (index > route_index) {
        this.step_name = this.accountInfo.step;
      } else {
        this.step_name = "personal-info";
      }
    },
    async addAccountInfo() {
      if (this.checkData(this.accountInfo)) {
        await new AccountsService().addInfo(
          this.accountInfo,
          JSON.parse(localStorage.getItem("account")).id,
          this.step_name,
          ""
        );
        await this.$router.push(
          `/${this.$store.state.lang}/app/application-form/business-info`
        );
        await this.setStatus();
      }
    },
    async getCities() {
      let response = await new CityDistrictService().getCities();
      this.cities = response.result.data;
      this.birthCities = response.result.data;
    },
    async getDistricts(id) {
      let response = await new CityDistrictService().getDistricts(id);
      this.districts = response.result.data;
    },
    async getBirthDistricts(id) {
      let response = await new CityDistrictService().getDistricts(id);
      this.birthDistricts = response.result.data;
    },
    async formatedDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  async created() {
    if (this.checkPermission("update accounts")) {
      await this.getCities();
      await this.getInfo(JSON.parse(localStorage.getItem("account")).id);
    }
  },
};
</script>

<style lang="scss">
.information-card {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 11px;
  margin-bottom: 20px;
}
.font-bold {
  font-weight: 700;
}
.font-light {
  font-weight: 400;
}
.small-text {
  font-size: 10px;
}
.dp__input_icon_pad {
  padding-left: 12px !important;
}
.p-calendar .p-inputtext {
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
</style>
