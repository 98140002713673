<template>
  <main>
    <div
      v-if="show > 0"
      v-show="info !== undefined"
      class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100"
    >
      <!-- Replace with your content -->
      <div class="container minh-100">
        <!--        <iframe-->
        <!--          src="https://uygulama.parasut.com/kullanici-girisi"-->
        <!--          width="100%"-->
        <!--          height="100%"-->
        <!--          frameborder="0"-->
        <!--        >-->
        <!--        </iframe>-->
      </div>
      <!-- /End replace -->
    </div>
    <div v-if="show === 0" class="flex justify-center items-center py-12">
      <div
        class="bg-gray flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="text-gray-600 text-lg text-center">
          <div>
            {{ $t("message.buyAPacketForShowThisPage") }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="tax_modal"
      class="h-full fixed inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center pt-4 px-4 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="modal-cont inline-block align-bottom h-full bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:h-full sm:max-w-xl sm:w-full sm:p-6"
        >
          <div class="text-center">
            <div>
              <!--              <div class="flex-grow">-->
              <!--                <div class="text-center">-->
              <!--                  <h3>{{ $t("message.accountancyTitle") }}</h3>-->
              <!--                  <small class="text-gray-500"-->
              <!--                    >{{ $t("message.accountancyText") }} ({{ comp_name }})-->
              <!--                    {{ $t("message.accountancyText2") }}</small-->
              <!--                  >-->
              <!--                </div>-->
              <!--                <div>-->
              <!--                  <div class="mt-3 sm:mt-5">-->
              <!--                    <div class="mt-2">-->
              <!--                      <div class="flex-grow">-->
              <!--                        <div class="mt-6 grid grid-cols-12 gap-3 flex-grow">-->
              <!--                          <div class="col-span-12 sm:col-span-12">-->
              <!--                            <label-->
              <!--                              class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"-->
              <!--                            >-->
              <!--                              {{ $t("message.taxOffice") }}-->
              <!--                            </label>-->
              <!--                            <input-->
              <!--                              v-model="tax_info.office"-->
              <!--                              type="text"-->
              <!--                              name="city"-->
              <!--                              class="mt-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"-->
              <!--                            />-->
              <!--                          </div>-->
              <!--                          <div class="col-span-12 sm:col-span-12 mt-2">-->
              <!--                            <label-->
              <!--                              class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"-->
              <!--                            >-->
              <!--                              {{ $t("message.taxNumber") }}-->
              <!--                            </label>-->
              <!--                            <input-->
              <!--                              v-model="tax_info.number"-->
              <!--                              type="text"-->
              <!--                              name="city"-->
              <!--                              class="mt-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"-->
              <!--                            />-->
              <!--                          </div>-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="flex-grow">
                <div class="title text-xl text-indigo-600 mb-3 font-semibold">
                  {{ $t("message.accountancyInformationTitle") }}
                </div>
                <div class="desc text-gray-500">
                  {{ $t("message.accountancyInformationDescription") }}
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <!--              <button-->
              <!--                type="button"-->
              <!--                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"-->
              <!--                @click="addInfo"-->
              <!--              >-->
              <!--                {{ $t("message.save") }}-->
              <!--              </button>-->
              <button
                @click="
                  tax_modal = false;
                  this.$router.push(`/${this.$store.state.lang}/app`);
                "
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                ref="cancelButtonRef"
              >
                {{ $t("message.close") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<style scoped>
.minh-100 {
  height: calc(100vh - 112px);
}
</style>
<script>
import AccountsService from "../../services/accounts.service";
export default {
  components: {},
  data() {
    return {
      info: {},
      show: false,
      tax_modal: false,
      tax_info: { number: "", office: "" },
      comp_name: JSON.parse(localStorage.getItem("account")).name,
    };
  },
  methods: {
    async getAccountInfo() {
      let response = await new AccountsService().getAccount(
        JSON.parse(localStorage.getItem("account")).id
      );
      for (let i = 0; i < response.result.informations.length; i++) {
        this.info[response.result.informations[i].key] =
          response.result.informations[i].value;
      }
      if (
        this.info.tax_office === undefined &&
        this.info.tax_number === undefined
      ) {
        this.tax_modal = true;
      }
    },
    async getUsersProducts() {
      if (localStorage.getItem("role_name") === "customer") {
        await new AccountsService().getAccountsProducts(
          JSON.parse(localStorage.getItem("account")).id
        );
        this.show = 1;
      } else {
        this.show = 1;
      }
    },
    async addInfo() {
      let response = await new AccountsService().addTaxInfo(
        JSON.parse(localStorage.getItem("account")).id,
        this.tax_info
      );
      if (response.status === 201) {
        this.tax_modal = false;
        this.$store.dispatch("showNotification", {
          title: "İşlem başarıyla gerçekleşti",
          description: "",
          type: "success",
        });
      } else if (response.status === 422) {
        this.$store.dispatch("showNotification", {
          title: "Lütfen tüm alanları doldurduğunuzdan emin olun",
          description: "",
          type: "warning",
        });
      } else {
        this.$store.dispatch("showNotification", {
          title: "Hata",
          description:
            "Maximum karakter sınırına ulaştınız. Lütfen 60 karakterdan kısa bir isim giriniz.",
          type: "error",
        });
      }
    },
  },
  async created() {
    await this.getUsersProducts();
    await this.getAccountInfo();
  },
};
</script>
<style lang="scss">
.bg-gray {
  background: #f9f9f9;
  border: 0px;
  box-shadow: 0 0 0 transparent;
}
.text-gray {
  color: #1f2a37;
}
</style>
