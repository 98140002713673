<template>
  <img
    class="mt-8 mx-auto h-24 w-auto"
    src="../../../public/img/siyah.png"
    alt="Workflow"
  />
  <div class="flex justify-center mt-8 text-center py-12">
    <div
      class="text-gray-500 bg-white shadow-xl border border-gray-100 p-12 rounded-md z-50"
    >
      <div class="p-5 mb-5">
        <span class="text-lg text-gray-800"
          >Telefonunuza sms ile gönderilen şifreyi aşağı giriniz.</span
        >
      </div>
      <div class="flex justify-center space-x-8 text-2xl">
        <input
          v-model="code[0]"
          @input="updateValue(code[0], 0)"
          type="text"
          ref="focus_0"
          name="first-name"
          autocomplete="off"
          class="code-input w-12 h-12 text-center focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
        />
        <input
          v-model="code[1]"
          @input="updateValue(code[1], 1)"
          type="text"
          name="first-name"
          autocomplete="off"
          ref="focus_1"
          class="code-input w-12 h-12 text-center focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
        />
        <input
          v-model="code[2]"
          @input="updateValue(code[2], 2)"
          type="text"
          name="first-name"
          autocomplete="off"
          ref="focus_2"
          class="code-input h-12 w-12 text-center focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
        />
        <input
          v-model="code[3]"
          @input="updateValue(code[3], 3)"
          type="text"
          ref="focus_3"
          name="first-name"
          autocomplete="off"
          class="code-input h-12 w-12 text-center focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
        />
        <input
          v-model="code[4]"
          @input="updateValue(code[4], 4)"
          ref="focus_4"
          type="text"
          name="first-name"
          autocomplete="off"
          class="code-input h-12 w-12 text-center focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
        />
        <input
          v-model="code[5]"
          @input="updateValue(code[5], 5)"
          ref="focus_5"
          type="text"
          name="off"
          autocomplete="given-name"
          class="code-input w-12 h-12 text-center focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div class="mt-4">
        <span>
          Kodu tekrar almak için
          <span v-show="countDown !== 0 && countDown !== 30">
            {{ countDown }} saniye sonra
          </span>
          <button
            @click="verifyNotificationPhoneNumber"
            :disabled="showButton"
            :class="[!showButton ? 'text-indigo-700' : 'text-indigo-100']"
          >
            tıklayınız
          </button>
        </span>
      </div>
      <div class="mt-8">
        <button
          @click="verifyPhoneNumber"
          class="w-24 h-12 ml-3 inline-flex justify-center text-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Gönder
        </button>
      </div>
    </div>
  </div>
  <div></div>
</template>
<script>
import UserService from "../../services/user.service";
import AccountsService from "../../services/accounts.service";

export default {
  components: {},
  data() {
    return {
      email: "",
      success: false,
      code: [],
      showButton: false,
      countDown: 30,
    };
  },
  methods: {
    updateValue(val, i) {
      if (val.match(/^[0-9]+$/)) {
        this.focusOn(i);
        if (val.length > 1) {
          this.code[i] = val[0];
        }
      } else {
        this.code[i] = "";
      }
      const inputElements = [...document.querySelectorAll("input.code-input")];

      inputElements.forEach((ele, index) => {
        ele.addEventListener("keydown", (e) => {
          if (e.keyCode === 8 && e.target.value === "")
            inputElements[Math.max(0, index - 1)].focus();
        });
      });
    },
    focusOn(i) {
      const foc = i !== 5 ? "focus_" + (i + 1) : "focus_" + i;
      const button = this.$refs[foc];
      button.focus();
    },
    async verifyPhoneNumber() {
      let response = await new UserService().verifyPhoneNumber(
        this.code.join("")
      );
      if (response.success) {
        if (!localStorage.getItem("token")) {
          await this.$router.push({
            name: "Login",
            params: { lang: this.$store.state.lang },
          });
        } else if (
          localStorage.getItem("role_name") === "advisor" ||
          localStorage.getItem("role_name") === "secretary" ||
          localStorage.getItem("role_name") === "admin"
        ) {
          await this.$router.push({
            name: "AdvisorDashboard",
            params: { lang: this.$store.state.lang },
          });
        } else {
          let accounts = JSON.parse(localStorage.getItem("accounts"));
          let account = JSON.parse(localStorage.getItem("account"));
          let advisor =
            accounts.length > 0
              ? account.users.find((user) => user.roles[0].name === "advisor")
              : false;
          if (advisor) {
            await this.$router.push({
              name: "Dashboard",
              params: { lang: this.$store.state.lang },
            });
          } else if (
            accounts.length > 0 &&
            accounts[0].informations.length !== 0
          ) {
            await this.getInfo(accounts[0].id);
          } else {
            await this.$router.push({
              name: "Şirket Durumu",
              params: { lang: this.$store.state.lang },
            });
          }
        }
      }
    },
    async verifyNotificationPhoneNumber() {
      await new UserService().sendVerifyNotificationPhoneNumber();
      this.countDownTimer();
    },
    countDownTimer() {
      this.showButton = true;
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 3000);
      } else if (this.countDown == 0) {
        this.showButton = false;
        this.countDown = 30;
      }
    },
    async getInfo(id) {
      let response = await new AccountsService().getAccount(id);
      this.step = response.result.informations.find(
        (element) => element.key === "step"
      );
      if (this.step) {
        let index = this.$store.state.steps.findIndex(
          (element) => element.slug === this.step.value
        );
        if (this.step.value !== "wait-for-approve") {
          await this.$router.push(this.$store.state.steps[index + 1].to);
        } else {
          await this.$router.push(this.$store.state.steps[index].to);
        }
      }
    },
  },
  async created() {
    if (!localStorage.getItem("token")) {
      await this.$router.push({
        name: "Login",
        params: { lang: this.$store.state.lang },
      });
    } else {
      await this.verifyNotificationPhoneNumber();
    }
  },
};
</script>
