<template>
  <div v-if="checkPermission('show invoices')" class="lg:col-span-9">
    <!-- Profile section -->
    <div class="py-6 px-3 sm:p-6 lg:pb-8">
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("message.name") }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("message.amount") }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("message.situation") }}
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">{{ $t("message.edit") }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="invoices.length"
                  class="bg-white divide-y divide-gray-200"
                >
                  <tr v-for="invoice in invoices" :key="invoice.id">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {{ invoice.name }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ invoice.total }} ₺
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <span
                        v-if="invoice.status === 'paid'"
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                      >
                        {{ $t("message.paid") }}
                      </span>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <button
                        @click="getSingleInvoice(invoice.id)"
                        class="mr-2 text-indigo-600 hover:text-indigo-900"
                      >
                        Görüntüle
                      </button>
                      <button
                        v-show="checkPermission('upload real invoices all')"
                        @click="setUploadModal(invoice.id)"
                        class="mr-2 text-indigo-600 hover:text-indigo-900"
                      >
                        Yükle
                      </button>
                      <button
                        v-show="checkPermission('download real invoices all')"
                        @click="downloadInvoice(invoice.id, invoice.name)"
                        :class="
                          invoice.url === null
                            ? 'text-gray-300'
                            : 'text-indigo-600'
                        "
                        :disabled="invoice.url === null"
                      >
                        İndir
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else class="bg-white divide-y divide-gray-200">
                  <tr>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {{ $t("message.noResultForInvoices") }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <TransitionRoot as="template" :show="modal">
          <Dialog
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @cloupdateModalse="modal = false"
          >
            <div
              class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
              <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay
                  class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                />
              </TransitionChild>

              <!-- This element is to trick the browser into centering the modal contents. -->
              <span
                class="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
                >&#8203;</span
              >
              <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leave-from="opacity-100 translate-y-0 sm:scale-100"
                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div
                  class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                >
                  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                      <h3 class="text-lg leading-6 font-medium text-gray-900">
                        {{ $t("message.invoiceDetail") }}
                      </h3>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <dl
                        class="grid grid-cols-1 gap-x-2 gap-y-3 sm:grid-cols-2"
                      >
                        <div class="sm:col-span-12">
                          <dt class="text-sm font-medium text-gray-500">
                            {{ $t("message.packetName") }} ve içeriği
                          </dt>
                          <dd
                            v-for="item in invoice.items"
                            :key="item.id"
                            class="mt-1 text-sm text-gray-900"
                          >
                            {{ item.title }} {{ item.price_per_unit }} ₺
                          </dd>
                        </div>
                        <div class="sm:col-span-12">
                          <dt class="text-sm font-medium text-gray-500">
                            Toplam
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900">
                            {{ invoice.total }} ₺
                          </dd>
                        </div>
                        <div class="sm:col-span-12">
                          <dt class="text-sm font-medium text-gray-500">
                            {{ $t("message.discount") }}
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900">
                            {{ invoice.discount }} ₺
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                  <div class="mt-5">
                    <button
                      type="button"
                      class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      @click="modal = false"
                      ref="cancelButtonRef"
                    >
                      {{ $t("message.close") }}
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </Dialog>
        </TransitionRoot>
        <TransitionRoot as="template" :show="uploadModal">
          <Dialog
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @cloupdateModalse="uploadModal = false"
          >
            <div
              class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
              <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay
                  class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                />
              </TransitionChild>

              <!-- This element is to trick the browser into centering the modal contents. -->
              <span
                class="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
                >&#8203;</span
              >
              <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leave-from="opacity-100 translate-y-0 sm:scale-100"
                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div
                  class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                >
                  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div
                        class="flex w-full items-center justify-center text-center"
                        id="app"
                      >
                        <div
                          class="px-12 py-5 w-full text-center mt-1"
                          @dragover="dragover"
                          @dragleave="dragleave"
                          @drop="drop"
                        >
                          <svg
                            class="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <input
                            type="file"
                            name="fields[assetsFieldHandle][]"
                            id="assetsFieldHandle"
                            class="w-px h-px opacity-0 overflow-hidden absolute"
                            @change="onChange"
                            ref="fileUpload"
                            accept=".pdf, .jpeg, .jpg, .png"
                          />

                          <label
                            for="assetsFieldHandle"
                            class="block cursor-pointer"
                          >
                            <div class="text-gray-600">
                              Faturanızı yüklemek için buraya sürükleyin veya
                              <span class="underline text-indigo-600">{{
                                $t("message.clickHere")
                              }}</span>
                            </div>
                            <p class="text-xs text-gray-500">
                              PNG, JPG, GIF up to 10MB
                            </p>
                          </label>
                          <ul
                            class="mt-4"
                            v-if="uploadInvoiceFile.length"
                            v-cloak
                          >
                            <li
                              class="text-sm p-1"
                              v-for="(file, index) in uploadInvoiceFile"
                              :key="index"
                            >
                              {{ file.name }}
                              <button
                                @click="remove(uploadInvoiceFile.indexOf(file))"
                                class="mt-2 inline-flex justify-center rounded-md text-white"
                              >
                                <img
                                  src="../../../../public/img/delete-bin-fill.svg"
                                  width="16"
                                  height="16"
                                  alt="delete"
                                />
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 flex space-x-6">
                    <button
                      type="button"
                      class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      @click="clearModal"
                      ref="cancelButtonRef"
                    >
                      {{ $t("message.close") }}
                    </button>
                    <button
                      type="button"
                      class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-gray-100 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      @click="uploadInvoice"
                      ref="cancelButtonRef"
                    >
                      Yükle
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </Dialog>
        </TransitionRoot>
      </div>
    </div>
  </div>
  <div v-else class="lg:col-span-9">
    <!-- Profile section -->
    <div class="flex justify-center items-center py-12">
      <div
        class="bg-gray flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="text-white uppercase text-lg text-center">
          <div>
            {{ $t("message.dontHavePermission") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InvoicesService from "../../../services/invoices.service";
import { Mixin } from "../../../library/utils";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  mixins: [Mixin],
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
  },
  setup() {},
  data() {
    return {
      invoices: [],
      invoice: {},
      deleteModal: false,
      modal: false,
      invoiceId: 0,
      permissions: [],
      informations: {},
      product: {},
      uploadInvoiceFile: [],
      id: 0,
      uploadModal: false,
    };
  },
  methods: {
    async getInvoices() {
      let id = JSON.parse(localStorage.getItem("account")).id;
      let response = await new InvoicesService().getInvoice("", "", id);
      this.invoices = response.result.data;
    },
    setDeleteModal(id) {
      this.invoiceId = id;
      this.deleteModal = true;
    },
    async getSingleInvoice(id) {
      let account_id = JSON.parse(localStorage.getItem("account")).id;
      let response = await new InvoicesService().getInvoice(id, "", account_id);
      this.invoice = response.result;
      this.modal = true;
      for (let i = 0; i < this.invoice.account.informations.length; i++) {
        this.informations[this.invoice.account.informations[i].key] =
          this.invoice.account.informations[i].value;
      }
      if (this.informations.virtual_office_selection == "1") {
        this.informations.virtual_office = JSON.parse(
          this.informations.virtual_office
        );
      }
    },
    async uploadInvoice() {
      const formData = new FormData();
      formData.append("file", this.uploadInvoiceFile[0]);
      await new InvoicesService().uplaodInvoice("", this.id, formData);
      await this.getInvoices();
      this.uploadModal = false;
    },
    async downloadInvoice(id, label) {
      await new InvoicesService().downloadInvoice("", id, label);
    },
    setUploadModal(id) {
      this.id = id;
      this.uploadModal = true;
    },
    async onChange() {
      this.uploadInvoiceFile = [...this.$refs.fileUpload.files];
      for (let i = 0; i < this.uploadInvoiceFile.length; i++) {
        if (this.uploadInvoiceFile[i].size > 20000000) {
          this.$store.dispatch("showNotification", {
            title: "Lütfen Daha Küçük Boyutlu Bir Dosya Yükleyiniz",
            description: `Dosya Adı: ${this.uploadInvoiceFile[i].name}`,
            type: "warning",
          });
          this.remove(i);
        }
      }
    },
    remove(i) {
      this.uploadInvoiceFile.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-white")) {
        event.currentTarget.classList.remove("bg-white");
        event.currentTarget.classList.add("bg-white");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-white");
      event.currentTarget.classList.remove("bg-white");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileUpload.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-white");
      event.currentTarget.classList.remove("bg-white");
    },
    clearModal() {
      this.uploadInvoiceFile = [];
      this.uploadModal = false;
    },
  },
  async created() {
    await this.getInvoices();
  },
};
</script>
<style lang="scss">
.bg-gray {
  background: #f9f9f9;
  border: 0px;
  box-shadow: 0 0 0 transparent;
}
</style>
