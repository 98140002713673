import axios from "axios";
import authHeader from "./auth-header";

class FilesService {
  addFile(file, id, user_id, step_name) {
    return axios
      .post(
        `accounts/${id}/informations`,
        {
          informations: [
            {
              key: "files",
              value: file.files,
            },
            {
              key: "have_car",
              value: file.have_car,
            },
            {
              key: "car_info",
              value: file.car_info,
            },
            {
              key: "step",
              value: step_name,
            },
          ],
        },
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  getFileUrl(files) {
    return axios
      .post(`file-upload`, files, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default FilesService;
