<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <main>
    <div
      v-if="checkPermission('update accounts')"
      class="max-w-7xl mx-auto minh-100"
    >
      <div v-if="company_name" class="mb-2">
        <span class="font-medium mb-4">{{ $t("message.companyName") }} : </span>
        <span class="font-medium text-indigo-600">{{ company_name }}</span>
      </div>
      <div
        v-show="haveCompany"
        class="h-1/3 sm:h-2/3 inset-0 mb-10 font-medium"
      >
        {{ $t("message.paketInfo") }}
      </div>
      <!-- Replace with your content -->
      <div class="bg-white">
        <div class="pb-12 sm:mt-12 sm:pb-16 lg:mt-8 lg:pb-24">
          <div class="relative">
            <div class="relative z-10 max-w-7xl mx-auto">
              <div
                class="max-w-md mx-auto space-y-4 lg:max-w-7xl lg:grid lg:grid-cols-4 lg:gap-5 lg:space-y-0"
              >
                <div
                  v-for="packet in packets"
                  v-show="packet.is_main === 1"
                  :key="packet.name"
                  class="flex flex-col rounded-lg overflow-hidden packed-shadow"
                >
                  <div class="px-6 py-8 bg-white sm:p-8 sm:pb-6 padding-btm">
                    <div>
                      <h3
                        class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600 sticker-size"
                        id="tier-standard"
                      >
                        {{ packet.name }}
                      </h3>
                    </div>
                    <div
                      class="mt-4 flex items-baseline text-6xl font-extrabold price-txt-size"
                    >
                      {{ packet.price }} ₺
                      <span
                        class="ml-1 text-2xl font-medium text-gray-500 small-tx"
                      >
                        / {{ $t("message.monthly") }}
                      </span>
                    </div>
                    <div
                      class="flex flex-col items-start justify-between bg-white mt-6 mb-6"
                    >
                      <ul role="list" class="space-y-4">
                        <li
                          v-for="feature in packet.features"
                          :key="feature"
                          class="flex items-start"
                        >
                          <div class="flex-shrink-0">
                            <CheckIcon
                              class="h-6 w-6 text-green-500"
                              aria-hidden="true"
                            />
                          </div>
                          <p class="ml-3 text-base text-gray-700">
                            {{ feature.name }}
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div class="space-y-6">
                      <hr v-show="haveCompany" />
                      <div
                        v-show="haveCompany"
                        v-for="child in packet.childrens"
                        :key="child.id"
                        class="ml-3 mr-3 text-base text-gray-700"
                      >
                        <div class="title">{{ child.name }}</div>
                        <div class="price">
                          {{ child.price }}₺
                          <span class="ml-1 text-2xl font-medium text-gray-500">
                          </span>
                          <div class="text-line text-gray-500">
                            ({{ $t("message.forOnce") }})
                          </div>
                        </div>
                      </div>
                      <div>
                        <router-link
                          @click="setStatus(packet)"
                          :to="`/${$store.state.lang}/app/application-form/personal-info`"
                          type="submit"
                          class="mb-5 ml-3 block justify-center py-2 px-4 font-medium rounded-md text-white text-center bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          {{ $t("message.choose") }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="items-center pt-16 flex gap-8 justify-center">
                <h3
                  class="text-center text-gray-900 text-lg font-large truncate advisor-name"
                >
                  {{ $t("message.PacketVideoModalText") }}
                </h3>

                <button
                  @click="addVideoModal = true"
                  type="text"
                  name="city"
                  class="focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-indigo-300 bg-indigo-500 hover:bg-indigo-700 rounded-md text-white"
                >
                  {{ $t("message.videoPlayer") }}
                </button>
              </div>
              <TransitionRoot as="template" :show="addVideoModal">
                <Dialog
                  as="div"
                  class="relative z-10"
                  @close="addVideoModal = false"
                >
                  <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                  >
                    <div
                      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    />
                  </TransitionChild>

                  <div class="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
                    >
                      <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <DialogPanel
                          class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full"
                          style="max-width: 800px; height: 490px"
                        >
                          <div class="">
                            <button
                              type="button"
                              class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              @click="addVideoModal = false"
                            >
                              <span class="sr-only">Close</span>
                              <XIcon class="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                          <div class="sm:flex sm:items-start w-full h-full">
                            <div
                              class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
                            >
                              <iframe
                                src="https://www.youtube.com/embed/TSEMXjuWLmw"
                                frameborder="0"
                                allowfullscreen
                                class="absolute top-0 left-0 w-full h-full"
                              ></iframe>
                            </div>
                          </div>
                        </DialogPanel>
                      </TransitionChild>
                    </div>
                  </div>
                </Dialog>
              </TransitionRoot>
            </div>
          </div>
        </div>
      </div>
      <!-- /End replace -->
    </div>
    <div v-else class="lg:col-span-9">
      <!-- Profile section -->
      <div class="flex justify-center items-center py-12">
        <div
          class="flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
        >
          <div class="text-white uppercase text-lg text-center">
            <div>BU SAYFAYI GÖRÜNTÜLEMEK İÇİN YETKİNİZ BULUNMAMAKTADIR.</div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { CheckIcon, XIcon } from "@heroicons/vue/outline";
import ProductsService from "../../../services/products.service";
import AccountsService from "../../../services/accounts.service";
import { Mixin } from "../../../library/utils";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  components: {
    CheckIcon,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  mixins: [Mixin],
  methods: {
    async setStatus(packet) {
      this.$store.state.steps[1].status = "complete";
      // this.$store.state.steps[2].status = 'current'
      await this.addInfo(packet);
    },
    async getPackets() {
      if (this.accountInfo.packet_type) {
        if (
          this.accountInfo.packet_type === "1" ||
          this.accountInfo.packet_type === "2"
        ) {
          let response = await new ProductsService().getProducts(
            "",
            "is_main:1"
          );
          this.packets = response.result;
        } else if (this.accountInfo.packet_type === "3") {
          let response = await new ProductsService().getProducts(
            "",
            "is_special:1"
          );
          this.packets = response.result;
        }
      } else {
        await this.$router.push({
          name: "Şirket Durumu",
          params: { lang: this.$store.state.lang },
        });
      }
    },
    async addInfo(packet) {
      await new ProductsService().addPacketInfo(
        packet,
        JSON.parse(localStorage.getItem("account")).id,
        "",
        this.step_name
      );
    },
    async getInfo() {
      let response = await new AccountsService().getAccount(
        JSON.parse(localStorage.getItem("account")).id
      );
      let companyName = JSON.parse(localStorage.getItem("company_name"));
      let sonuc = response.result.users[0].accounts.find((element) => {
        element.name === companyName;
        return true;
      });
      if (sonuc) this.company_name = companyName;
      if (response.result.informations.length > 0) {
        for (let i = 0; i < response.result.informations.length; i++) {
          this.accountInfo[response.result.informations[i].key] =
            response.result.informations[i].value;
        }
      }
      let index = this.$store.state.steps.findIndex(
        (step) => step.slug === this.accountInfo.step
      );
      let route_index = this.$store.state.steps.findIndex(
        (step) => step.to === this.$route.path
      );
      if (index > route_index) {
        this.step_name = this.accountInfo.step;
      } else {
        this.step_name = "packets";
      }
      await this.getPackets();
    },
  },
  data() {
    return {
      haveCompany: false,
      packets: [],
      accountInfo: {},
      step_name: "",
      addVideoModal: false,
      company_name: null,
    };
  },
  async created() {
    if (this.checkPermission("update accounts")) {
      await this.getInfo();

      if (this.accountInfo.packet_type === "2") {
        this.haveCompany = true;
      }
    }
  },
};
</script>

<style scoped>
.price-txt-size {
  font-size: 3rem !important;
}

.sticker-size {
  font-size: 0.675rem !important;
}

.small-tx {
  font-size: 0.9rem !important;
}
.packed-shadow {
  box-shadow: 1px 6px 17px 0px #cfcfcf;
}
.started-price-info {
  font-size: 13px;
  line-height: 19px;
  color: #6d6d6d;
  font-weight: 300;
}

.title {
  font-weight: 600;
}

.price {
  font-weight: 800;
  font-size: 27px;
  margin-top: 18px;
  line-height: 10px;
}

.text-line {
  font-weight: 300 !important;
  font-size: 0.8rem !important;
}

.padding-btm {
  padding-bottom: 0px !important;
}
</style>
