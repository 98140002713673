<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <main>
    <div
      v-if="checkPermission('update accounts')"
      class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100"
    >
      <!-- Replace with your content -->
      <div class="mt-8 bg-white">
        <div>
          <div class="max-w-7xl mx-auto">
            <div
              class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex"
            >
              <div class="flex-1 bg-white px-6 py-8 lg:p-12">
                <h3 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                  {{ $t("message.haveNoCompany") }}
                </h3>
                <p class="mt-6 text-base text-gray-500">
                  {{ $t("message.haveNoCompanyDesc") }}
                </p>
              </div>
              <div
                class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12"
              >
                <div class="mt-6 md:m-0">
                  <div class="rounded-md lg:shadow">
                    <button
                      @click="setPacketType(1)"
                      class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                    >
                      {{ $t("message.continue") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 bg-white">
        <div>
          <div class="max-w-7xl mx-auto">
            <div
              class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex"
            >
              <div class="flex-1 bg-white px-6 py-8 lg:p-12">
                <h3 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                  {{ $t("message.haveCompany") }}
                </h3>
                <p class="mt-6 text-base text-gray-500">
                  {{ $t("message.haveCompanyDesc") }}
                </p>
              </div>
              <div
                class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12"
              >
                <div class="mt-6 md:m-0">
                  <div class="rounded-md lg:shadow">
                    <button
                      @click="setPacketType(2)"
                      class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                    >
                      {{ $t("message.continue") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="addModal"
        :clickToClose="false"
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="text-center">
              <div>
                <div class="flex-grow">
                  <div class="grid grid-cols-12 gap-3 flex-grow">
                    <div class="col-span-12 sm:col-span-12">
                      <h3 class="text-center text-xl text-gray-900">
                        Şirket İsmi
                      </h3>
                      <small class="mb-2 text-xs text-gray-500"
                        >Devam etmeden önce şirketinize bir isim
                        belirlemelisiniz</small
                      >
                      <input
                        v-model="account.name"
                        type="text"
                        name="city"
                        class="mt-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                @click="addAccount"
              >
                {{ $t("message.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="lg:col-span-9">
      <!-- Profile section -->
      <div class="flex justify-center items-center py-12">
        <div
          class="flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
        >
          <div class="text-white uppercase text-lg text-center">
            <div>{{ $t("message.dontHavePermission") }}</div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AccountsService from "../../services/accounts.service";
import { Mixin } from "../../library/utils";

export default {
  mixins: [Mixin],
  data() {
    return {
      addModal: false,
      account: {},
      accountInfo: {},
      step_name: "status",
      packet_id: null,
    };
  },
  methods: {
    async addAccount() {
      //
      if (this.account.name.length <= 60) {
        await new AccountsService().addAccount(this.account.name);

        localStorage.setItem("company_name", JSON.stringify(this.account.name));
        this.addModal = false;
        this.account = "";
        await this.getAccount();
        await this.setStatus();

        await this.$router.push({
          name: "Packets",
          id: this.packet_id,
          params: { lang: this.$store.state.lang },
        });
      }
    },
    async setPacketType(packet_id) {
      if (localStorage.getItem("account") === "{}") {
        this.addModal = true;
      } else {
        let response = await new AccountsService().setPacketTypeInfo(
          packet_id,
          JSON.parse(localStorage.getItem("account")).id,
          this.step_name
        );
        if (response.status === 201) {
          await this.setStatus();
          await this.$router.push({
            name: "Packets",
            id: packet_id,
            params: { lang: this.$store.state.lang },
          });
        }
      }
    },
    async getAccount() {
      let response = await new AccountsService().getAccounts();
      this.$store.state.accounts = response.result.data;
      this.$store.state.account = response.result.data[0];
      localStorage.setItem("account", JSON.stringify(response.result.data[0]));
    },
    async setStatus() {
      this.$store.state.steps[0].status = "complete";
      // this.$store.state.steps[1].status = 'current'
    },
    async getInfo() {
      let response = await new AccountsService().getAccount(
        JSON.parse(localStorage.getItem("account")).id
      );
      if (response.result.informations.length > 0) {
        for (let i = 0; i < response.result.informations.length; i++) {
          this.accountInfo[response.result.informations[i].key] =
            response.result.informations[i].value;
        }
      }
      let index = this.$store.state.steps.findIndex(
        (step) => step.slug === this.accountInfo.step
      );
      let route_index = this.$store.state.steps.findIndex(
        (step) => step.to === this.$route.path
      );
      if (
        index > route_index &&
        index !== -1 &&
        this.accountInfo.step !== undefined
      ) {
        this.step_name = this.accountInfo.step;
      } else {
        this.step_name = "status";
      }
    },
  },
  async created() {
    if (this.checkPermission("update accounts")) {
      if (localStorage.getItem("account") === "{}") {
        this.addModal = true;
      } else {
        await this.getInfo();
      }
    }
  },
};
</script>
<style scoped lang="scss">
.title-design {
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 5px;
}
</style>
