<!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <div
    class="min-h-screen bg-gray-50 flex flex-col justify-center sm:px-6 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto mb-2 h-12 w-auto"
        src="../../../public/img/siyah.png"
        alt="Workflow"
      />
    </div>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <h2 class="text-center text-3xl font-extrabold text-gray-900">
          {{ $t("message.changePass") }}
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          {{ $t("message.mailWarningText") }}
          <a
            href="#"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          ></a>
        </p>
        <div class="mt-6 space-y-6">
          <div>
            <label class="block text-sm font-medium text-gray-700">
              E-Posta
            </label>
            <div class="mt-1">
              <email-input v-model="email"></email-input>
            </div>
          </div>

          <div></div>
        </div>
        <button
          @click="forgotPass"
          type="submit"
          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {{ $t("message.send") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import EmailInput from "../Inputs/EmailInput";
import AuthService from "../../services/new/auth";
export default {
  components: { EmailInput },
  data() {
    return {
      email: "",
      success: false,
    };
  },
  methods: {
    async forgotPass() {
      if (this.email !== "") {
        await new AuthService().forgotPassword(this.email);
        this.email = "";
      }
    },
  },
};
</script>
