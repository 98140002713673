import axios from "axios";
import authHeader from "@/services/auth-header";

class SpeakerService {
  addSpeaker(speaker) {
    return axios
      .post(
        `enb/speakers`,
        {
          speaker_first_name: speaker.first_name,
          speaker_last_name: speaker.last_name,
          speaker_bio: speaker.bio,
          speaker_job: speaker.job,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  editSpeaker(speaker, id) {
    return axios
      .put(
        `enb/speakers/${id}`,
        {
          speaker_first_name: speaker.speaker_first_name,
          speaker_last_name: speaker.speaker_last_name,
          speaker_bio: speaker.speaker_bio,
          speaker_job: speaker.speaker_job,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  getSpeaker(id) {
    return axios
      .get(`enb/speakers${id ? `/${id}` : ""}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  deleteSpeaker(id) {
    return axios
      .delete(`enb/speakers${id ? `/${id}` : ""}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}
export default SpeakerService;
