<template>
  <div
    v-if="this.checkPermission('show permissions')"
    class="max-w-7xl mx-auto py-6 step-component sm:px-6 lg:px-8 minh-100"
  >
    <div class="lg:col-span-9">
      <div class="flex flex-col px-3 m-3">
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <div class="mt-2">
              <div class="flex-grow">
                <div class="">
                  <div class="p-2">
                    <div
                      class="flex justify-between items-center rounded-t-lg bg-gray-100 px-8 py-2 text-white bg-gray-800 font-bold flex justify-start"
                    >
                      <div>ROL BİLGİLERİ</div>
                      <div>
                        <button
                          v-if="this.checkPermission('store roles')"
                          @click="addRoleModal = true"
                          type="text"
                          name="city"
                          class="focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-800 bg-gray-900 hover:bg-gray-700 rounded-md text-white"
                        >
                          + Yeni Rol
                        </button>
                      </div>
                    </div>
                    <div class="w-full rounded-b-lg flex-grow bg-gray-100">
                      <div v-if="roles.length > 0" class="mb-2 px-8 py-5">
                        <div class="text-lg text-left mb-5 font-bold">
                          Rol Adı
                        </div>

                        <select
                          v-model="role_name"
                          id="location"
                          name="location"
                          class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                          <option
                            v-for="role in roles"
                            :key="role.id"
                            :value="role"
                          >
                            {{ role.name }}
                          </option>
                        </select>
                      </div>
                      <div v-else class="px-5 py-2 mb-2 w-full">
                        Daha once eklenmis rolunuz bulunmamaktadir. Lutfen
                        yukaridan rol ekleyiniz
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 p-2">
                    <div
                      class="rounded-t-lg bg-gray-100 px-8 py-4 bg-gray-800 text-white font-bold flex justify-start"
                    >
                      YETKİLER
                    </div>
                    <div class="rounded-b-lg flex-grow bg-gray-100">
                      <fieldset>
                        <div
                          class="border-b border-y border-gray-200 divide-y divide-gray-200"
                        >
                          <div v-for="(per, key) in permissions" :key="key">
                            <div
                              class="text-lg text-left mb-5 mt-5 px-8 font-bold"
                            >
                              {{ key }}
                            </div>
                            <div class="grid grid-cols-3 bg-gray-100 px-8">
                              <div
                                v-for="permission in permissions[key]"
                                :key="permission.name"
                                class="relative cursor-pointer flex mr-5 mb-5 items-start py-3 px-2 bg-white rounded-lg border border-indigo-200"
                              >
                                <div
                                  style="
                                    display: flex;
                                    justify-content: center;
                                    flex-direction: column;
                                    align-items: center;
                                  "
                                  class="min-w-0 rounded-lg flex-1 text-sm bg-white text-center"
                                >
                                  <label
                                    :for="permission.name"
                                    class="font-medium cursor-pointer text-gray-700 select-none"
                                    >{{ permission.name }}</label
                                  >
                                </div>
                                <div class="ml-3 flex items-center h-5">
                                  <input
                                    v-model="permissionList"
                                    :value="permission.name"
                                    :id="permission.name"
                                    name="person-1"
                                    type="checkbox"
                                    class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-2 flex justify-end">
          <button
            type="button"
            class="w-32 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            @click="givePermissionToRole"
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="addRoleModal"
      :clickToClose="false"
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        >
          <div class="text-center">
            <div>
              <div class="flex-grow">
                <div class="grid grid-cols-12 gap-3 flex-grow">
                  <div class="col-span-12 sm:col-span-12">
                    <h3 class="text-center text-xl text-gray-900">Rol İsmi</h3>
                    <input
                      v-model="new_role"
                      type="text"
                      name="city"
                      class="mt-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 flex space-x-5">
            <button
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              @click="addRoleModal = false"
            >
              Kapat
            </button>
            <button
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
              @click="addRole"
            >
              Kaydet
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="lg:col-span-9">
    <!-- Profile section -->
    <div class="flex justify-center items-center py-12">
      <div
        class="flex justify-center items-center h-28 w-full max-w-7xl bg-red-600 border border-gray-200 rounded-lg shadow overflow-hidden py-2 sm:px-6 lg:px-8"
      >
        <div class="text-white uppercase text-lg text-center">
          <div>
            {{ $t("message.authorized_text") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PermissionsService from "@/services/permissions.service";
import { Mixin } from "@/library/utils";

export default {
  mixins: [Mixin],
  components: {},
  data() {
    return {
      role_name: "",
      roles: [],
      addRoleModal: false,
      permissions: [],
      permissionList: [],
      member: { name: "", mail: "", phone: "" },
      user: {},
      users: [],
      new_role: "",
    };
  },
  computed: {
    setUserPermission() {
      return this.role_name;
    },
  },
  watch: {
    setUserPermission(val) {
      this.setPermissions(val);
    },
  },
  methods: {
    async getRoles() {
      let response = await new PermissionsService().getRoles();
      this.roles = response.result.data;
    },
    async getPermissions() {
      let response = await new PermissionsService().getPermissions();
      this.permissions = response.result;
    },
    setPermissions(role) {
      let list = [];
      list = role.permissions;
      list.forEach((permission) => {
        this.permissionList.push(permission.name);
      });
    },
    async addRole() {
      let response = await new PermissionsService().addRole(this.new_role);
      await this.getRoles();
      this.addRoleModal = false;
      if (response.status === 422) {
        this.$store.dispatch("showNotification", {
          title: "Hata",
          description: "Lütfen tüm alanları doldurduğunuzdan emin olun",
          type: "error",
        });
      }
    },
    async givePermissionToRole() {
      let permissions = this.permissionList.map((item) => ({
        permission_name: item,
      }));
      await new PermissionsService().givePermissionToRole(
        this.role_name.id,
        permissions
      );
      await this.getPermissions();
    },
  },
  async created() {
    if (this.checkPermission("show permissions")) {
      await this.getRoles();
      await this.getPermissions();
    }
  },
};
</script>
<style lang="scss">
.bg-gray {
  background: #f9f9f9;
  border: 0px;
  box-shadow: 0 0 0 transparent;
}

.information-card {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 11px;
  margin-bottom: 20px !important;
  margin-top: 35px !important;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 400;
}

.small-text {
  font-size: 10px;
}

.dp__input_icon_pad {
  padding-left: 12px !important;
}
</style>
