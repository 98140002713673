import axios from "axios";
import authHeader from "./auth-header";

class InvoicesService {
  getInvoice(id, user_id, account_id) {
    return axios
      .get(
        `accounts/${account_id}/invoices/${id ? `${id}` : ""}`,
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  downloadInvoice(user_id, id, label) {
    return axios
      .get(
        `/accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/invoices/${id}/download`,
        {
          headers: authHeader(),
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.type.includes("image")) {
          const blob = new Blob([response], { type: response.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        } else {
          const file = new Blob([response], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      });
  }

  uplaodInvoice(user_id, id, file) {
    return axios
      .post(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/invoices/${id}/upload`,
        file,
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default InvoicesService;
