<template>
  <div
    class="accounts-container max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100"
  >
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Şirket İsmi
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Yetkili İsmi
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Telefon
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    E-Posta
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Sekreter
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Advisor
                  </th>
                  <th
                    v-show="checkPermission('change status accounts all')"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Aktif/Pasif
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Düzenle</span>
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="accounts.length"
                class="bg-white divide-y divide-gray-200"
              >
                <tr v-for="(account, key) in accounts" :key="key">
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ account.name }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    <span
                      v-if="
                        account.users.find(
                          (user) => user.roles[0].name === 'customer'
                        )
                      "
                    >
                      {{
                        account.users.find(
                          (user) => user.roles[0].name === "customer"
                        ).name
                      }}</span
                    >
                    <span v-else>-</span>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    <span
                      v-if="
                        account.users.find(
                          (user) => user.roles[0].name === 'customer'
                        )
                      "
                    >
                      {{
                        account.users
                          .find((user) => user.roles[0].name === "customer")
                          .phone.substring(2, 6)
                      }}
                      {{
                        account.users
                          .find((user) => user.roles[0].name === "customer")
                          .phone.substring(6, 9)
                      }}
                      {{
                        account.users
                          .find((user) => user.roles[0].name === "customer")
                          .phone.substring(9, 11)
                      }}
                      {{
                        account.users
                          .find((user) => user.roles[0].name === "customer")
                          .phone.substring(11, 13)
                      }}</span
                    >
                    <span v-else>-</span>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    <span
                      v-if="
                        account.users.find(
                          (user) => user.roles[0].name === 'customer'
                        )
                      "
                    >
                      {{
                        account.users.find(
                          (user) => user.roles[0].name === "customer"
                        ).email
                      }}</span
                    >
                    <span v-else>-</span>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    <span
                      v-if="
                        account.users.find(
                          (user) => user.roles[0].name === 'secretary'
                        )
                      "
                    >
                      {{
                        account.users.find(
                          (user) => user.roles[0].name === "secretary"
                        ).name
                      }}</span
                    >
                    <span v-else>-</span>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    <span
                      v-if="
                        account.users.find(
                          (user) => user.roles[0].name === 'advisor'
                        )
                      "
                      >{{
                        account.users.find(
                          (user) => user.roles[0].name === "advisor"
                        ).name
                      }}</span
                    >
                    <span v-else>-</span>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center"
                  >
                    <div
                      v-show="checkPermission('change status accounts all')"
                      class="x ×· border border-transparent text-indigo-600 hover:text-indigo-900"
                    >
                      <input
                        :id="account.id"
                        name="person-1"
                        type="checkbox"
                        :checked="account.status"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        @change="sendApiRequest($event, account.id)"
                      />
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex justify-center align-center"
                  >
                    <span
                      class="mr-5 text-indigo-600 hover:text-indigo-900"
                      @click="
                        this.$router.push({
                          name: 'AccountsDetail',
                          params: {
                            id: account.id,
                            lang: this.$store.state.lang,
                          },
                        })
                      "
                    >
                      Detail
                    </span>
                    <button
                      v-show="checkPermission('update accounts all')"
                      @click="getAllAdvisor(account.id, account.users[0].id)"
                      href="#"
                      class="x ×· border border-transparent text-indigo-600 hover:text-indigo-900"
                    >
                      <PencilIcon class="h-6 w-6"></PencilIcon>
                    </button>
                    <button
                      v-show="checkPermission('destroy accounts all')"
                      @click="setDeleteModal(account.id, account.users[0].id)"
                      class="x ×· border border-transparent text-indigo-600 hover:text-indigo-900"
                    >
                      <TrashIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
              <tbody v-else class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          Listelenecek hesap bulunmamaktadır
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="updateModal"
    class="h-full fixed inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center pt-4 px-4 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="modal-cont inline-block align-bottom h-full bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:h-full sm:max-w-xl sm:w-full sm:p-6"
      >
        <div class="text-center">
          <div>
            <div class="flex-grow">
              <div class="text-center">
                <h3>MÜŞAVİR ATAMA VE ŞİRKET DÜZENLEME</h3>
                <small class="text-gray-500"
                  >Seçilen şirkete müşavir atamak için lütfen listeden bir
                  müşavir seçiniz</small
                >
              </div>
              <div>
                <div class="mt-3 sm:mt-5">
                  <div class="mt-2">
                    <div class="flex-grow">
                      <div class="mt-6 grid grid-cols-12 gap-3 flex-grow">
                        <div
                          v-show="
                            checkPermission('assign user to accounts all')
                          "
                          class="col-span-12 sm:col-span-12 mt-2"
                        >
                          <label
                            class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"
                          >
                            Müşavir Listesi
                          </label>
                          <Listbox
                            as="div"
                            v-model="selectedItem"
                            class="firm-select"
                          >
                            <ListboxLabel
                              class="block text-sm font-medium text-gray-700"
                            ></ListboxLabel>
                            <div class="mt-1 relative">
                              <ListboxButton
                                class="border border-gray-200 relative w-full px-3 py-2 rounded-md text-sm font-medium"
                              >
                                <span class="block truncate text-left">{{
                                  selectedItem.name
                                }}</span>
                                <span
                                  class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                                >
                                  <SelectorIcon
                                    class="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </ListboxButton>

                              <transition
                                leave-active-class="transition ease-in duration-100"
                                leave-from-class="opacity-100"
                                leave-to-class="opacity-0"
                              >
                                <ListboxOptions
                                  class="company-drop-list absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                  <ListboxOption
                                    as="template"
                                    v-for="advisor in advisors"
                                    :key="advisor.id"
                                    :value="advisor"
                                    v-slot="{ active, selected }"
                                  >
                                    <li
                                      :class="[
                                        active
                                          ? 'text-white bg-indigo-600'
                                          : 'text-gray-900',
                                        'cursor-default select-none relative py-2 pl-8 pr-4',
                                      ]"
                                    >
                                      <span
                                        :class="[
                                          selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                          'block truncate',
                                        ]"
                                      >
                                        {{ advisor.name }}
                                      </span>

                                      <span
                                        v-if="selected"
                                        :class="[
                                          active
                                            ? 'text-white'
                                            : 'text-indigo-600',
                                          'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                        ]"
                                      >
                                        <CheckIcon
                                          class="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </li>
                                  </ListboxOption>
                                </ListboxOptions>
                              </transition>
                            </div>
                          </Listbox>
                        </div>
                        <div
                          :disabled="!disable_secretary"
                          :class="{ disabled: !disable_secretary }"
                          v-show="
                            checkPermission('assign user to accounts all')
                          "
                          class="col-span-12 sm:col-span-12 mt-2"
                        >
                          <label
                            class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"
                          >
                            Sekreter Listesi
                          </label>
                          <Listbox
                            :disabled="!disable_secretary"
                            :class="[!disable_secretary ? 'bg-gray-100' : '']"
                            as="div"
                            v-model="selectedItemSecretary"
                            class="firm-select"
                          >
                            <ListboxLabel
                              class="block text-sm font-medium text-gray-700"
                            ></ListboxLabel>
                            <div class="mt-1 relative">
                              <ListboxButton
                                class="border border-gray-200 relative w-full px-3 py-2 rounded-md text-sm font-medium"
                              >
                                <span
                                  :class="[
                                    !disable_secretary
                                      ? 'text-gray-500'
                                      : 'text-gray-700',
                                  ]"
                                  class="block truncate text-left"
                                  >{{ selectedItemSecretary.name }}</span
                                >
                                <span
                                  class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                                >
                                  <SelectorIcon
                                    class="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </ListboxButton>

                              <transition
                                leave-active-class="transition ease-in duration-100"
                                leave-from-class="opacity-100"
                                leave-to-class="opacity-0"
                              >
                                <ListboxOptions
                                  class="company-drop-list absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                  <ListboxOption
                                    as="template"
                                    v-for="secretary in secretaries"
                                    :key="secretary.id"
                                    :value="secretary"
                                    v-slot="{ active, selected }"
                                  >
                                    <li
                                      :class="[
                                        active
                                          ? 'text-white bg-indigo-600'
                                          : 'text-gray-900',
                                        'cursor-default select-none relative py-2 pl-8 pr-4',
                                      ]"
                                    >
                                      <span
                                        :class="[
                                          selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                          'block truncate',
                                        ]"
                                      >
                                        {{ secretary.name }}
                                      </span>

                                      <span
                                        v-if="selected"
                                        :class="[
                                          active
                                            ? 'text-white'
                                            : 'text-indigo-600',
                                          'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                        ]"
                                      >
                                        <CheckIcon
                                          class="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </li>
                                  </ListboxOption>
                                </ListboxOptions>
                              </transition>
                            </div>
                          </Listbox>
                        </div>

                        <div class="col-span-12 sm:col-span-12">
                          <label
                            class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"
                          >
                            Şirket İsmi
                          </label>
                          <input
                            v-model="account.name"
                            type="text"
                            name="city"
                            class="mt-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div class="col-span-12 sm:col-span-12 mt-2">
                          <label
                            class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"
                          >
                            Muhasebe iframe URL
                          </label>
                          <input
                            v-model="informations.iframe_link"
                            type="text"
                            name="city"
                            class="mt-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div class="col-span-12 sm:col-span-12 mt-2">
                          <label
                            class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"
                          >
                            Emusavirim Paketi
                          </label>
                          <select
                            id="tabs"
                            name="tabs"
                            v-model="this.selectedEmusavirimPackage"
                            class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                          >
                            <option
                              v-for="emusavirim in emusavirim_package"
                              :key="emusavirim.id"
                              :value="emusavirim.id"
                              :selected="
                                this.selectedEmusavirimPackage == emusavirim.id
                              "
                            >
                              {{ emusavirim.name }}
                            </option>
                          </select>
                        </div>
                        <div class="col-span-12 sm:col-span-12 mt-2">
                          <label
                            class="text-left ml-1 block mb-2 text-sm font-medium text-gray-700"
                          >
                            Office Paketi
                          </label>
                          <select
                            id="tabs"
                            name="tabs"
                            v-model="selectedOfficePackage"
                            class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                          >
                            <option
                              v-for="office in this.office_package"
                              :key="office.id"
                              :value="office.id"
                              :selected="office.id == selectedOfficePackage"
                            >
                              {{ office.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
          >
            <button
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
              @click="assignAdvisor"
            >
              Kaydet
            </button>
            <button
              @click="updateModal = false"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              ref="cancelButtonRef"
            >
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TransitionRoot as="template" :show="deleteModal">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="deleteModal = false"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="flex justify-center items-center">
              <div
                class="flex justify-center items-center w-full overflow-hidden py-2 sm:px-6 lg:px-8"
              >
                <div class="text-lg text-center">
                  <div>Silmek istediğinize emin misiniz?</div>
                </div>
              </div>
            </div>

            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                @click="deleteAccounts"
              >
                Sil
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                @click="deleteModal = false"
                ref="cancelButtonRef"
              >
                Kapat
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import {
  ListboxOptions,
  ListboxOption,
  Listbox,
  ListboxLabel,
  ListboxButton,
  Dialog,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { Mixin } from "../../library/utils";
import AccountsService from "../../services/accounts.service";
import AdvisorService from "../../services/advisor.service";
import {
  CheckIcon,
  SelectorIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/vue/outline";
import UserService from "../../services/user.service";
import SecretaryService from "../../services/secretary.service";
import ProductsService from "../../services/products.service";

export default {
  mixins: [Mixin],
  components: {
    TransitionChild,
    TransitionRoot,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    Listbox,
    SelectorIcon,
    ListboxButton,
    ListboxLabel,
    PencilIcon,
    TrashIcon,
    Dialog,
  },
  setup() {},
  data() {
    return {
      accounts: [],
      deleteModal: false,
      selectedOfficePackage: null,
      selectedEmusavirimPackage: null,
      accountId: 0,
      account: {},
      updateModal: false,
      addModal: false,
      advisors: [],
      accountCheck: "",
      selectedItem: { name: "" },
      advisor: {},
      user_id: JSON.parse(localStorage.getItem("user")).id,
      informations: {},
      selectedItemSecretary: { name: "" },
      secretaries: [],
      secretary: {},
      disable_secretary: 0,
      emusavirim_package: [],
      office_package: [],
      accountIsActive: null,
    };
  },
  methods: {
    async getAccount(id, user_id) {
      if (id) {
        this.informations = {};
        this.accountId = id;
        this.user_id = user_id;
        let response = await new AccountsService().getAccountAll(id, "all"); //TODO:Hande : burayi kontrol etmelisin servis alanindan user_id alani kaldirildi
        for (let i = 0; i < response.result.informations.length; i++) {
          this.informations[response.result.informations[i].key] =
            response.result.informations[i].value;
        }
        this.informations.iframe_link =
          this.informations.iframe_link !== undefined ||
          this.informations.iframe !== "-"
            ? this.informations.iframe_link
            : "";
        this.account = response.result;
        this.selectedEmusavirimPackage =
          this.account.products.emusavirim.length > 0
            ? this.account.products.emusavirim[0].id
            : null;
        this.selectedOfficePackage =
          this.account.products.endless_office.length > 0
            ? this.account.products.endless_office[0].id
            : null;

        this.advisor = this.account.users.find(
          (user) => user.roles[0].name === "advisor"
        );
        this.secretary = this.account.users.find(
          (user) => user.roles[0].name === "secretary"
        );
        this.advisor = this.advisor !== undefined ? this.advisor : { name: "" };
        this.secretary =
          this.secretary !== undefined ? this.secretary : { name: "" };
        this.accountCheck = this.account.name;
      } else {
        let response;

        if (this.checkPermission("show assigned accounts")) {
          response = await new AccountsService().getAccounts();
          this.accounts = response.result.data;
        } else {
          response = await new AccountsService().getAllAccount();
          this.accounts = response.result.data;
        }
      }
    },
    async getAllAdvisor(id, user_id) {
      await this.getAccount(id, user_id);
      await this.getOfficeProduct(id);
      await this.getAllSecretary();
      this.updateModal = true;
      let response = await new AdvisorService().getAllAdvisor();
      this.advisors = response.result.data;
      this.selectedItem =
        this.advisor.name !== "" ? this.advisor : this.advisors[0];
    },
    async getAllSecretary() {
      let response = await new SecretaryService().getAllSecretary();
      this.secretaries = response.result.data;
      this.selectedItemSecretary =
        this.secretary.name !== "" ? this.secretary : this.secretaries[0];
      this.selectedItemSecretary = this.disable_secretary
        ? this.selectedItemSecretary
        : {
            id: 0,
            name: "Sekreter seçimi için kullanıcınin endless office ürünü satın alması gerekir",
          };
    },
    async editAccount() {
      var products = [];
      if (this.selectedOfficePackage) {
        products.push(this.selectedOfficePackage);
      }
      if (this.selectedEmusavirimPackage) {
        products.push(this.selectedEmusavirimPackage);
      }
      var bodyParams =
        this.accountCheck !== this.account.name
          ? {
              name: this.account.name,
              products: products,
            }
          : {
              products: products,
            };
      if (this.account.name.length <= 60) {
        let response = await new AccountsService().editAccount(
          this.user_id,
          this.accountId,
          bodyParams
        );
        if (response.status === 422) {
          this.$store.dispatch("showNotification", {
            title: "Lütfen sistemde olmayan bir isim giriniz",
            description: "",
            type: "warning",
          });
        }
      } else {
        this.$store.dispatch("showNotification", {
          title: "Hata",
          description:
            "Maximum karakter sınırına ulaştınız. Lütfen 60 karakterdan kısa bir isim giriniz.",
          type: "error",
        });
      }
    },
    async assignAdvisor() {
      await this.editAccount();
      if (this.checkPermission("assign user to accounts all")) {
        await this.assignSecretary();
        this.account.advisor = this.selectedItem;
        this.account.id = this.accountId;
        let response = await new AdvisorService().assignAdvisor(this.account);
        if (response.status === 201) {
          this.updateModal = false;
          await this.addInfo(this.accountId);
          await this.getAccount();
          await this.getAdvisorInfo();
          this.$store.dispatch("showNotification", {
            title: "İşlem başarıyla gerçekleşti",
            description: "",
            type: "success",
          });
        }
      }
    },
    async assignSecretary() {
      if (this.disable_secretary) {
        this.account.secretary = this.selectedItemSecretary;
        let response = await new SecretaryService().assignSecretary(
          this.account
        );
        if (response.status === 201) {
          await new SecretaryService().addInfo(this.accountId);
        }
      }
    },
    async addInfo(id) {
      if (this.checkPermission("update accounts")) {
        this.informations.iframe_link =
          this.informations.iframe_link !== undefined
            ? this.informations.iframe_link
            : "-";
        await new AdvisorService().addInfo(id, this.informations.iframe_link);
      }
    },
    async getAdvisorInfo() {
      let response = await new UserService().getUserInfo(
        JSON.parse(localStorage.getItem("user")).id
      );
      localStorage.setItem(
        "accounts",
        JSON.stringify(response.result.accounts)
      );
      this.$store.state.accounts = response.result.accounts;
    },
    async getEmusavirimProducts() {
      let response = await new ProductsService().getBrandPackets(1);
      this.emusavirim_package = response.result;
    },
    async getOfficeProducts() {
      let response = await new ProductsService().getBrandPackets(2);
      this.office_package = response.result;
    },
    async getOfficeProduct(id) {
      let response = await new AccountsService().getAccountsProducts(
        id,
        "filters=brands:2",
        "all=true"
      );
      this.disable_secretary = response.result.data.length > 0;
    },
    setDeleteModal(id, user_id) {
      this.id = id;
      this.user_id = user_id;
      this.deleteModal = true;
    },
    async deleteAccounts() {
      await new AccountsService().deleteAccount(this.user_id, this.id);
      this.deleteModal = false;
      await this.getAccount();
    },
    async sendApiRequest(event, accountId) {
      await new AccountsService().setChangeStatus(
        accountId,
        event.target.checked
      );
    },
  },

  async created() {
    await this.getEmusavirimProducts();
    await this.getOfficeProducts();
    await this.getAccount();
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .accounts-container {
    padding: 15px;
  }
}
</style>
