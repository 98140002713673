import axios from "axios";
import authHeader from "./auth-header";

class AppointmentService {
  getAppointments(id) {
    return axios
      .get(`appointment-requests${id ? `/${id}` : ""}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  addAppointment(user_id, advisor_id) {
    return axios
      .post(
        `appointment-requests`,
        {
          user_id: user_id,
          advisor_id: advisor_id,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  changeAppointmentStatus(user_id, advisor_id, id) {
    return axios
      .put(
        `appointment-requests/${id}`,
        {
          user_id: user_id,
          advisor_id: advisor_id,
          status: 1,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default AppointmentService;
