<template>
  <div
    class="hidden lg:block fixed top-0 left-0 w-1/2 bg-white"
    aria-hidden="true"
  />
  <div
    class="hidden lg:block fixed top-0 right-0 w-1/2 bg-gray-50"
    aria-hidden="true"
  />

  <div
    class="relative grid grid-cols-1 gap-x-16 max-w-7xl mx-auto lg:px-8 lg:grid-cols-2 xl:gap-x-48"
  >
    <h1 class="sr-only">{{ $t("message.orderInformation") }}</h1>

    <section
      v-if="orderSummary.name != ''"
      aria-labelledby="summary-heading"
      class="bg-gray-50 pt-16 pb-10 px-4 sm:px-6 lg:pb-16 lg:row-start-1 lg:col-start-2"
    >
      <div class="max-w-lg mx-auto lg:max-w-none">
        <h2
          id="summary-heading"
          class="text-lg font-semibold text-gray-900 mb-4"
        >
          {{ $t("message.orderSummary") }}
        </h2>

        <ul
          role="list"
          class="text-sm font-small text-gray-900 divide-y divide-gray-200"
        >
          <li class="flex items-start py-4 space-x-4">
            <div class="flex-auto space-y-1">
              <h3 class="font-semibold">{{ orderSummary.name }} (Aylık)</h3>
              <p class="text-xs">
                {{ orderInfo.office_location.name }} /
                {{ orderInfo.office_location.desc }}
              </p>
            </div>
            <p class="flex-none text-base font-medium">
              {{ orderSummary.price }} ₺
            </p>
          </li>
        </ul>

        <dl
          class="hidden text-sm font-medium text-gray-900 space-y-6 border-gray-200 pt-6 lg:block"
        >
          <div
            class="flex items-center justify-between border-t border-gray-200 pt-6"
          >
            <dt class="text-base">{{ $t("message.total") }}</dt>
            <dd class="text-base">{{ orderSummary.total }} ₺</dd>
          </div>
        </dl>

        <Popover
          class="mobile-total-content fixed bottom-0 inset-x-0 flex flex-col-reverse text-sm font-medium text-gray-900 lg:hidden"
        >
          <div
            class="relative z-10 bg-white border-t border-gray-200 px-4 sm:px-6"
          >
            <div class="max-w-lg mx-auto">
              <PopoverButton
                class="w-full items-center text-right py-6 font-semibold"
              >
                <span class="text-base">{{ $t("message.total") }}: </span>
                <span class="text-base mr-2"> {{ orderSummary.total }} ₺</span>
              </PopoverButton>
            </div>
          </div>
        </Popover>
      </div>
    </section>

    <div
      class="pt-16 pb-36 px-4 sm:px-6 lg:pb-16 lg:px-0 lg:row-start-1 lg:col-start-1"
    >
      <div class="max-w-lg mx-auto lg:max-w-none">
        <section aria-labelledby="payment-heading">
          <h2
            id="payment-heading"
            class="text-lg font-semibold text-gray-900 mb-4"
          >
            {{ $t("message.paymentDetail") }}
          </h2>

          <div class="mt-6 grid grid-cols-3 sm:grid-cols-4 gap-y-6 gap-x-4">
            <div class="col-span-3 sm:col-span-4">
              <label
                for="name-on-card"
                class="block text-sm font-medium text-gray-700"
                >{{ $t("message.cardHolderName") }}</label
              >
              <div class="mt-1">
                <input
                  v-model="card.name"
                  type="text"
                  id="name-on-card"
                  name="name-on-card"
                  autocomplete="off"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div class="col-span-3 sm:col-span-4">
              <label class="block text-sm font-medium text-gray-700">{{
                $t("message.cardNumber")
              }}</label>
              <div class="mt-1">
                <credit-card-input
                  v-model="card.cardNumber"
                ></credit-card-input>
              </div>
            </div>

            <div class="col-span-2 sm:col-span-3">
              <label class="block text-sm font-medium text-gray-700">{{
                $t("message.cardExpire")
              }}</label>
              <div class="mt-1">
                <card-expiration-date-input
                  v-model="card.expiration"
                ></card-expiration-date-input>
              </div>
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">{{
                $t("message.CVC")
              }}</label>
              <div class="mt-1">
                <card-cvc-input v-model="card.cvc"></card-cvc-input>
              </div>
            </div>
          </div>
        </section>

        <div class="mt-10 flex justify-end">
          <button
            @click="addPayment"
            :disabled="
              this.card.cardNumber && this.card.expiration && this.card.cvc
                ? false
                : true
            "
            class="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:ml-6 sm:order-last sm:w-auto"
          >
            {{ $t("message.makePayment") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Popover, PopoverButton } from "@headlessui/vue";
import CreditCardInput from "../../Inputs/CreditCardInput";
import CardExpirationDateInput from "../../Inputs/CardExpirationDateInput";
import CardCvcInput from "../../Inputs/CardCvcInput";
import { Mixin } from "../../../library/utils";
import PaymentService from "../../../services/payment.service";
import AccountsService from "../../../services/accounts.service";
import ProductsService from "../../../services/products.service";

export default {
  components: {
    CardCvcInput,
    CardExpirationDateInput,
    CreditCardInput,
    Popover,
    PopoverButton,
  },
  setup() {
    return {};
  },
  mixins: [Mixin],
  data() {
    return {
      card: {},
      orderInfo: {},
      id: 0,
      orderSummary: { name: "", total: {}, price: {} },
      childPriceTotal: 0,
    };
  },
  methods: {
    async setStatus() {
      this.$store.state.steps[2].status = "complete";
      // this.$store.state.steps[6].status = 'current'
      await this.$router.push({
        name: "OfficeWaitForApprove",
        params: { lang: this.$store.state.lang },
      });
    },
    async addPayment() {
      if (this.card.cardNumber && this.card.expiration && this.card.cvc) {
        this.card.cardNumber = this.card.cardNumber.replace(/\s/g, "");
        let response = await new PaymentService().addPayment(
          this.card,
          this.orderInfo.office_packet_id
        );
        await this.addInfo();
        this.$store.state.officePackets = response.result.data;
        await this.setStatus();
      }
    },
    async getInfo(id) {
      let response = await new AccountsService().getAccount(id);
      this.id = id;
      if (response.result.informations.length > 0) {
        for (let i = 0; i < response.result.informations.length; i++) {
          this.orderInfo[response.result.informations[i].key] =
            response.result.informations[i].value;
        }
        this.orderInfo.office_location = JSON.parse(
          this.orderInfo.office_location
        );
        let index = this.$store.state.endlessOfficeSteps.findIndex(
          (step) => step.slug === this.orderInfo.office_step
        );
        let route_index = this.$store.state.endlessOfficeSteps.findIndex(
          (step) => step.to === this.$route.path
        );
        if (index > route_index) {
          this.step_name = this.orderInfo.step;
        } else {
          this.step_name = "office-payment";
        }
        await this.getPacketInfo(this.orderInfo.office_packet_id);
      }
    },
    async getPacketInfo(id) {
      let response = await new ProductsService().getProducts(id);
      this.orderSummary = response.result;

      for (let i = 0; i < this.orderSummary.childrens.length; i++) {
        this.childPriceTotal += parseInt(this.orderSummary.childrens[i].price);
      }
      this.orderSummary.total =
        parseInt(this.orderSummary.price) + this.childPriceTotal;
    },
    async addInfo() {
      await new PaymentService().addOfficeInfo(this.id, "", this.step_name);
    },
  },
  async created() {
    await this.getInfo(JSON.parse(localStorage.getItem("account")).id);
  },
};
</script>
<style lang="scss">
button:disabled {
  opacity: 0.5;
  &:hover {
    cursor: not-allowed;
  }
}
.items-start {
  border: 0px !important;
}

.mobile-total-content {
  z-index: 99999999;
  box-shadow: 0px -3px 20px -4px #3a3a3a38;
}
</style>
