<template>
  <div>
    <select
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      id="location"
      name="location"
      :required="required"
      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      :disabled="disabled"
    >
      <option v-for="(sector, index) in sectors" :key="index">
        {{ sector.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SectorSelectInput",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: String,
    sectors: Array,
    // eslint-disable-next-line vue/require-prop-type-constructor
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
