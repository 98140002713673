<template>
  <main class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 minh-100">
    <div>
      <div class="space-y-8 divide-y divide-gray-200">
        <div>
          <div class="information-card">
            <div class="divide-gray-200">
              <h3 class="text-lg leading-6 font-bold text-gray-900">
                {{ $t("message.activityInfo") }}
              </h3>
            </div>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-5">
              <div class="sm:col-span-2">
                <label class="block text-sm font-light text-gray-700">
                  {{ $t("message.name") }}<span class="text-red-500">*</span>
                </label>
                <div class="mt-1">
                  <input
                    v-model="stage.name"
                    type="text"
                    name="first-name"
                    autocomplete="off"
                    class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label class="block text-sm font-light text-gray-700">
                  {{ $t("message.subject") }}
                  <span class="text-red-500">*</span>
                </label>
                <div class="mt-1">
                  <textarea
                    v-model="stage.description"
                    type="text"
                    name="first-name"
                    autocomplete="off"
                    class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label class="block text-sm font-light text-gray-700">
                  {{ $t("message.date") }} <span class="text-red-500">*</span>
                </label>
                <div class="mt-1">
                  <div class="p-fluid grid formgrid focus:outline-none">
                    <div
                      class="field col-12 md:col-4 bg-white text-gray-700 w-full appearance-none rounded-md focus:outline-none mt-1"
                    >
                      <Calendar
                        id="dateformat"
                        v-model="stage.date"
                        autoComplete="off"
                        dateFormat="dd-mm-yy"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="sm:col-span-2">
                <div class="grid grid-cols-2 gap-2">
                  <label class="block text-sm font-light text-gray-700">
                    {{ $t("message.startTime") }}
                    <span class="text-red-500">*</span>
                  </label>
                  <label class="block text-sm font-light text-gray-700">
                    {{ $t("message.finishTime") }}
                    <span class="text-red-500">*</span>
                  </label>
                  <Calendar
                    id="time24"
                    @date-select="setEndHour"
                    v-model="stage.hour"
                    :timeOnly="true"
                    autoComplete="off"
                    :step-minute="15"
                    hourFormat="24"
                  />
                  <input
                    :disabled="true"
                    :value="stage.end_hour"
                    type="text"
                    name="first-name"
                    class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-100"
                  />
                </div>
              </div>
            </div>
            <div class="pt-5"></div>
          </div>
          <div class="information-card">
            <div class="divide-gray-200 flex justify-between">
              <h3 class="text-lg leading-6 font-bold text-gray-900">
                {{ $t("message.ModeratorInfo") }}
              </h3>
            </div>
            <div class="p-2">
              <div class="w-full rounded-b-lg flex-grow">
                <div v-if="speakers.length > 0" class="px-2 mb-2 py-5">
                  <fieldset>
                    <div class="grid grid-cols-3 px-8">
                      <div
                        v-for="(speaker, index) in speakers"
                        :key="index"
                        class="relative flex mr-5 mb-5 items-start py-3 px-2 bg-white rounded-lg border border-indigo-200"
                      >
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                          "
                          class="min-w-0 rounded-lg flex-1 text-sm bg-white text-center"
                        >
                          <label
                            :for="`moderator_${speaker.id}`"
                            class="font-medium text-gray-700 select-none"
                            >{{ speaker.job }} - {{ speaker.bio }}
                            {{ speaker.first_name }}
                            {{ speaker.last_name }}</label
                          >
                        </div>
                        <div class="ml-3 flex items-center h-5">
                          <input
                            v-model="selected_moderator"
                            :value="speaker"
                            :id="`moderator_${speaker.id}`"
                            name="person-1"
                            type="checkbox"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div v-else class="px-5 py-2 mb-2 w-full">
                  {{ $t("message.moderatorsDesc") }}
                </div>
              </div>
            </div>
          </div>
          <div class="information-card">
            <div class="divide-gray-200 flex justify-between">
              <h3 class="text-lg leading-6 font-bold text-gray-900">
                {{ $t("message.speakerInfo") }}
              </h3>
              <button
                @click="addSpeakerModal = true"
                class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {{ $t("message.newSpeaker") }}
              </button>
            </div>
            <div class="p-2">
              <div class="w-full rounded-b-lg flex-grow">
                <div v-if="speakers.length > 0" class="px-2 mb-2 py-5">
                  <fieldset>
                    <div class="grid grid-cols-3 px-8">
                      <div
                        v-for="(speaker, index) in speakers"
                        :key="index"
                        class="relative flex mr-5 mb-5 items-start py-3 px-2 bg-white rounded-lg border border-indigo-200"
                      >
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                          "
                          class="min-w-0 rounded-lg flex-1 text-sm bg-white text-center"
                        >
                          <label
                            :for="`speaker_${speaker.id}`"
                            class="font-medium text-gray-700 select-none"
                            >{{ speaker.job }} - {{ speaker.bio }}
                            {{ speaker.first_name }}
                            {{ speaker.last_name }}</label
                          >
                        </div>
                        <div class="ml-3 flex items-center h-5">
                          <input
                            v-model="selected_speakers"
                            :value="speaker"
                            :id="`speaker_${speaker.id}`"
                            name="person-1"
                            type="checkbox"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div v-else class="px-5 py-2 mb-2 w-full">
                  {{ $t("message.speakerDesc") }}
                </div>
              </div>
            </div>
          </div>

          <div class="pt-5">
            <div class="flex justify-end">
              <button
                @click="addStageInfo"
                class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {{ $t("message.saveAndGo") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="addSpeakerModal"
      :clickToClose="false"
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        >
          <div class="text-center">
            <div>
              <div class="flex-grow">
                <div
                  class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-1"
                >
                  <div>
                    <label class="block text-sm font-light text-gray-700">
                      {{ $t("message.name") }}
                      <span class="text-red-500">*</span>
                    </label>
                    <div class="mt-1">
                      <input
                        v-model="speaker.first_name"
                        type="text"
                        name="first-name"
                        autocomplete="off"
                        class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <label class="block text-sm font-light text-gray-700">
                      {{ $t("message.surname") }}
                      <span class="text-red-500">*</span>
                    </label>
                    <div class="mt-1">
                      <input
                        v-model="speaker.last_name"
                        type="text"
                        name="first-name"
                        autocomplete="off"
                        class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div>
                    <label class="block text-sm font-light text-gray-700">
                      {{ $t("message.company") }}
                      <span class="text-red-500">*</span>
                    </label>
                    <div class="mt-1">
                      <input
                        v-model="speaker.job"
                        type="text"
                        name="first-name"
                        autocomplete="off"
                        class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <label class="block text-sm font-light text-gray-700">
                      {{ $t("message.title") }}
                      <span class="text-red-500">*</span>
                    </label>
                    <div class="mt-1">
                      <input
                        v-model="speaker.bio"
                        type="text"
                        name="first-name"
                        autocomplete="off"
                        class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 flex space-x-5">
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                @click="addSpeakerModal = false"
              >
                {{ $t("message.close") }}
              </button>
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                @click="addSpeaker"
              >
                {{ $t("message.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Mixin } from "@/library/utils";
import SpeakerService from "@/services/speaker.service";
import StageService from "@/services/stage.service";
import ProductsService from "@/services/products.service";
export default {
  mixins: [Mixin],
  setup() {
    return {};
  },
  props: ["modelValue"],
  data() {
    return {
      masks: {
        input: "DD-MM-YYYY",
      },
      maxDate: new Date(),
      stage: {
        hour: null,
        end_hour: null,
        start_hour: null,
      },
      format24h: true,
      speakers: [],
      moderators: [],
      selected_speakers: [],
      selected_moderator: [],
      addSpeakerModal: false,
      speaker: { first_name: "", last_name: "", job: "", bio: "" },
      packet: [],
    };
  },
  methods: {
    async addSpeaker() {
      let response = await new SpeakerService().addSpeaker(this.speaker);
      if (response.status === 201) {
        this.addSpeakerModal = false;
      }
      await this.getSpeakers();
    },
    async getSpeakers() {
      let response = await new SpeakerService().getSpeaker();
      this.speakers = response.result.data;
    },
    async addStageInfo() {
      var start_dd = String(this.stage.date.getDate()).padStart(2, "0");
      var start_mm = String(this.stage.date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var start_yyyy = this.stage.date.getFullYear();
      this.stage.start_date =
        start_yyyy +
        "-" +
        start_mm +
        "-" +
        start_dd +
        " " +
        this.stage.hour.getHours() +
        ":" +
        this.stage.hour.getMinutes();
      this.stage.end_date =
        start_yyyy +
        "-" +
        start_mm +
        "-" +
        start_dd +
        " " +
        this.stage.end_hour;

      this.stage.packet = this.packet;
      let response = await new StageService().addInfo(
        this.stage,
        JSON.parse(localStorage.getItem("account")).id,
        this.selected_speakers
      );
      if (response.status === 201) {
        await this.$router.push({
          name: "EndlessNetworkStagePayment",
          params: { lang: this.$store.state.lang },
        });
      }
    },
    setEndHour() {
      //add one hour for endhours
      var dt = new Date(this.stage.hour);
      dt.setHours(dt.getHours() + 1);
      if (dt.getMinutes() < 10) {
        this.stage.end_hour = dt.getHours() + ":" + "0" + dt.getMinutes();
      } else {
        this.stage.end_hour = dt.getHours() + ":" + dt.getMinutes();
      }
    },
    async getProducts() {
      let response = await new ProductsService().getProducts(
        "",
        "is_special:1"
      );
      this.packet = response.result.find(
        (product) => product.name === "ENB Stage"
      );
    },
  },
  async created() {
    await this.getSpeakers();
    await this.getProducts();
  },
};
</script>

<style lang="scss">
.information-card {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 11px;
  margin-bottom: 20px;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 400;
}

.small-text {
  font-size: 10px;
}

.dp__input_icon_pad {
  padding-left: 12px !important;
}

.img-content {
  position: relative;
  min-height: 115px;
}

.profile-img {
  position: absolute;
  object-fit: cover;
  top: 15px;
  box-shadow: 0px 7px 20px -7px #111826b3;
  left: 85px;
}

.img-pp-content {
  &:hover {
    .img-hover-photo {
      display: block !important;
    }
  }
}

.img-hover-photo {
  position: absolute;
  top: 15px;
  padding: 50px;
  background: #11182666;
  border-radius: 50%;
  display: none;
  z-index: 100000;
  left: 85px;

  &:hover {
    display: block;
  }
}
.p-datepicker-today {
  padding: 0;
}
.p-datepicker-header {
  padding: 0 !important;
}
.p-datepicker {
  font-size: 0.875rem;
  width: auto !important;
  min-width: 100px !important;
}
.p-datepicker table td > span {
  width: auto !important;
  height: auto !important;
}
.p-datepicker table td {
  padding: 0 !important;
}
.special-day {
  text-decoration: line-through;
}
.p-inputtext {
  &:focus {
    box-shadow: none !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
    outline: none !important;
  }
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: inherit;
  color: #000;
  border-radius: 0.375rem;
}
.p-datepicker .p-timepicker span {
  font-size: 0.875rem;
}
</style>
